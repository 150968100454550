import React, { useCallback, useState } from 'react';
import Input from '../../../common/components/Inputs/Input';
import { validatePassword } from '../../../utils/validPatterns';
import { fetchToken } from '../actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';




const Login = ({fetchToken}) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')


    const formSubmitHandler = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        fetchToken(email, password)
    }, [email, fetchToken, password])

    return (
        <form onSubmit={formSubmitHandler} className="login_form">
            <div className="sub_title">Добро пожаловать!</div>
            <Input
                inputType="text"
                placeholder="Email"
                title=''
                withIcon={false} value={email}
                changeHandler={value => setEmail(value)}
            />
            <Input
                inputType="password"
                placeholder="Пароль"
                withIcon={true}
                value={password}
                title=''
                changeHandler={value => setPassword(value)}
                isValid={(password ? validatePassword(password) : true)}
                validText="Пароль должен состоять из 6-30 символов и включать в себя хотя бы одно число и букву в верхнем регистре"
            />
            <button className="btn primary">Войти</button>
            <Link to="/forgot-password">Забыли пароль?</Link>
        </form>
    )
}


const mapDispatchToProps = {
    fetchToken
}

export default connect(null, mapDispatchToProps)(Login)