import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { store } from '../../../App';
import { dispatchCheckboxItem } from '../../../pages/TaskInfoPlate/actions';

const CheckListItem = ({
  orderNumber,
  inputFieldDispatch,
  fieldAsInput,
  textValue,
  checked,
  disabled,
}) => {
  const [itemAsInput, setitemAsInput] = useState(fieldAsInput);
  const [text, setText] = useState(textValue);
  const dispatch = useDispatch();
  const { selectedTask }: any = store.getState().taskInfoPlate;

  return (
    <div className={'checkItem'}>
      <div className={'checkItemContent'}>
        <div
          onClick={(e) => {
            if (!disabled) {
              let parent = e.currentTarget.parentNode as Element,
                orderNumber = parent.getElementsByClassName('orderNumber')[0]
                  .textContent,
                nameItem;
              if (parent.getElementsByClassName('nameItem')[0]) {
                nameItem = parent.getElementsByClassName('nameItem')[0]
                  .textContent;
              } else {
                nameItem = parent.getElementsByTagName('input')[1].value;
              }
              if (nameItem.length) {
                inputFieldDispatch(
                  orderNumber,
                  nameItem,
                  !checked,
                  true, // itemAsInput,
                  false, // is Delete
                  false // onlyChangeReducer
                );
              }
            }
          }}
          className="checkbox_item"
        >
          <div className={`checkbox_icon ${checked ? ' checked' : ''}`} />
        </div>

        <div className={`orderNumber ${itemAsInput ? 'asInput' : ''}`}>
          {orderNumber}.
        </div>

        {itemAsInput ? (
          <input
            placeholder="Минимум 3 символа"
            type={'text'}
            className={'input_div'}
            autoFocus={true}
            onBlur={(e) => {
              // если ни одного символа не написано - то при блюре возвращать кнопку добавления
              // если чекбокс при этом отмечен - то ничего не делать, ведь нажатие чекбокса уже прописывает строку в редюсер.
              let parent = e.currentTarget.parentNode as Element;
              let orderNumber = parent.getElementsByClassName('orderNumber')[0]
                .textContent;
              let nameItem = parent.getElementsByTagName('input')[0].value;

              // потеря фокуса - гарантированное преобразование в обычный див
              setitemAsInput(false);

              if (orderNumber !== null && nameItem.length > 2) {
                inputFieldDispatch(
                  parseInt(orderNumber),
                  nameItem,
                  checked,
                  true // itemAsInput
                );
              } else {
                inputFieldDispatch(
                  null,
                  null,
                  null,
                  true // itemAsInput
                );
              }
            }}
            onChange={(e) => {
              setText(e.target.value);
            }}
            value={text}
          />
        ) : (
          <div
            className={'nameItem'}
            onClick={() => {
              if (!disabled) {
                setitemAsInput(true);
              }
            }}
          >
            {text}
          </div>
        )}
      </div>

      {!disabled && (
        <div className={'deleteButtonBox'}>
          <div
            className={'deleteButton'}
            onClick={(e) => {
              inputFieldDispatch(
                parseInt(orderNumber),
                textValue,
                checked,
                true, // itemAsInput
                true, // isDelete
                !selectedTask // onlyChangeReducer
              );
            }}
            // onClick={dispatch(dispatchCheckboxItem())}
          />
        </div>
      )}
    </div>
  );
};

export default CheckListItem;
