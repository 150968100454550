import React, { useMemo, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { Status } from '../../types';
import { State } from '../../../rootReducer';
import { fetchData } from '../../../utils/fetchData';
//import './styles.sass';
import { updateTask } from '../../actions';
import styled, { css } from 'styled-components';
import { columnsOfTables } from '../../shared_data';
import Icon from '../Icon';

type Props = {
  id: number;
  statuses: Status[];
  taskId: number;
  updateTask: (id: number, params: any) => void;
  disabled?: boolean;
};

const greenStatus = css`
  color: #008c6d;
  background: #f2fefc;
  border: 1px solid #008c6d;
`;
const blueStatus = css`
  color: #02405e;
  background: #f3fafe;
  border: 1px solid #02405e;
`;
const redStatus = css`
  color: #cc0000;
  background: #fff2f2;
  border: 1px solid #cc0000;
`;
const yellowStatus = css`
  color: #b9932f;
  background: #fffdf7;
  border: 1px solid #b9932f;
`;
const purpleStatus = css`
  color: #b201f1;
  background: #fbf3fe;
  border: 1px solid #b201f1;
`;
const grayStatus = css`
  color: #707070;
  background: #f2f2f2;
  border: 1px solid #707070;
`;

const WraperTaskStatus = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${columnsOfTables[3].style?.minWidth};
  max-width: ${columnsOfTables[3].style?.minWidth};
`;
const StatusStyle = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 32px;
  border-radius: 6px;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 4px;

  ${({ status_id }) => {
    switch (status_id) {
      case 12:
      case 13:
        return greenStatus;
      case 10:
        return blueStatus;
      case 14:
        return redStatus;
      case 6:
      case 17:
        return yellowStatus;
      case 2:
      case 3:
      case 4:
      case 5:
        return purpleStatus;
      case 15:
      case 11:
      case 1:
      case 9:
        return grayStatus;
    }
  }}
`;

const StatusListStyle = styled.div`
  border-radius: 6px;
  padding: 16px;
  background: #fff;
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 152px;
  position: absolute;
  top: 45px;
  left: -16px;
  z-index: 1000;
  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const TaskStatus: React.FC<Props> = ({
  id,
  statuses,
  taskId,
  updateTask,
  disabled,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [statusList, setStatusList] = useState<Status[]>([]);

  const currentStatus = useMemo(() => {
    let status = statuses.find((status) => status.id === id);
    if (status) return status;
    else return null;
  }, [id, statuses]);

  const list = useRef<HTMLDivElement>(null);

  const outsideClick = useCallback((e: Event) => {
    if (!list.current?.contains(e.target as HTMLElement)) {
      setShow(false);
      setStatusList([]);
      document.removeEventListener('click', outsideClick);
    }
  }, []);

  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      (async () => {
        e.stopPropagation();
        if (!disabled) {
          const data = await fetchData.get(`/api/v1/tasks/${taskId}/statuses`);
          if (data) {
            const result: Status[] = statuses.filter((elem) =>
              data.includes(elem.id)
            );
            setStatusList(result);
            setShow(true);
            document.addEventListener('click', outsideClick);
          }
        }
      })();
    },
    [outsideClick, statuses, taskId]
  );
  let nameStatus;
  if (id > 1 && id < 6) {
    nameStatus = currentStatus?.name.split(' ')[1][0].toUpperCase();
    nameStatus =
      nameStatus +
      currentStatus?.name.split(' ')[1].split('').splice(1).join('');

    //currentStatus?.name.split(' ')[1].split('').splice(1);
  } else {
    nameStatus = currentStatus?.name;
  }
  return (
    <WraperTaskStatus>
      <StatusStyle status_id={id} disabled={disabled} onClick={clickHandler}>
        {id > 1 && id < 6 && <Icon name="arrow-right-status" />}
        {nameStatus}
      </StatusStyle>

      {show && statusList.length > 0 && (
        <StatusListStyle ref={list}>
          {statusList.map((status) => {
            let nameStatus;

            if (status.id > 1 && status.id < 6) {
              nameStatus = status?.name.split(' ')[1][0].toUpperCase();
              nameStatus =
                nameStatus +
                status?.name.split(' ')[1].split('').splice(1).join('');

              //currentStatus?.name.split(' ')[1].split('').splice(1);
            } else {
              nameStatus = status?.name;
            }
            return (
              <StatusStyle
                status_id={status.id}
                key={status.id}
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  updateTask(taskId, { status_id: status.id });
                  setShow(false);
                }}
              >
                {status.id > 1 && status.id < 6 && (
                  <Icon name="arrow-right-status" />
                )}
                {nameStatus}
              </StatusStyle>
            );
          })}
        </StatusListStyle>
      )}
    </WraperTaskStatus>
  );
};

const mapStateToProps = (state: State) => {
  return {
    statuses: state.commonInfo.statuses,
    // openStatusPlateId: state.tasksPage.openStatusPlateId
  };
};

const mapDispatchToProps = {
  updateTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskStatus);
