import React from 'react';
import { connect } from 'react-redux';
import { setUserBirthDay } from '../actions';
import CustomDatePicker from '../../../common/components/DatePickers/DatePicker';
import { State } from '../../../rootReducer';
import { User } from '../../../common/types';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';
import styled from 'styled-components';

type Props = {
  birthday: string;
  userId: number;
  currentUserInfo: User | null;
  setUserBirthDay: (birthday: string) => void;
};
const WrapperBirthDay = styled.div`
  pointer-events: ${({ disabled }) => (disabled ? 'auto' : 'none')};
`;
//Дату рождения можно менять для новых пользователей, для себя и если у пользователя есть роль  админа

const UserBirthDay: React.FC<Props> = ({
  birthday,
  userId,
  currentUserInfo,
  setUserBirthDay,
}) => {
  return (
    <NameWithInput title="Дата рождения" iconName="calendar-dark">
      <WrapperBirthDay
        disabled={
          userId === 0 ||
          userId === currentUserInfo?.id ||
          currentUserInfo?.roles.includes(1)
        }
      >
        <CustomDatePicker
          date={new Date(birthday)}
          acceptHandler={(birthday) => setUserBirthDay(birthday)}
        />
      </WrapperBirthDay>
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => {
  return {
    birthday: state.newUser.birthday,
    userId: state.newUser.id,
    currentUserInfo: state.commonInfo.currentUserInfo,
  };
};

const mapDispatchToProps = {
  setUserBirthDay: setUserBirthDay,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBirthDay);
