import { takeEvery, call, put } from 'redux-saga/effects';
import { types, createUserAciton, changePass } from './action_types';
import { store } from '../../App';
import { State } from '../../rootReducer';
import { fetchData } from '../../utils/fetchData';
import { DayOff } from '../../common/types';
import { CreateNotif } from '../../utils/createNotification';
import { setUserAddMode } from '../Users/actions';
import { clearUser, setIsSending } from './actions';
import { setUsers, setCurrentUserInfo } from '../../common/actions';
import { cookieMaster } from '../../utils/CookieMaster';
import { createBrowserHistory } from 'history';

function checkUpdateData(body, id, curentUserId) {
  //удаляем из запроса не допустимые поля

  delete body['roles']; //сами себе мы не можем устанавливать роль
  delete body['positions']; //сами себе мы не можем устанавливать должность
  delete body['departments']; //сами себе мы не можем устанавливать отдел
  delete body['email']; //сами себе мы не можем менять емаил

  return body;
}

function* createUser({ update }: createUserAciton) {
  yield put(setIsSending(true));
  let {
    commonInfo: { users },
    newUser,
  }: State = store.getState();

  const id = newUser.id;
  let curentUserId = store.getState().commonInfo.current_user;
  let body = {
    email: newUser.email,
    // password: newUser.password,
    surname: newUser.surname,
    name: newUser.name,
    roles: newUser.roles,
  };
  if (newUser.password) body['password'] = newUser.password;
  if (newUser.departments.length > 0) body['departments'] = newUser.departments;
  if (newUser.patronymic) {
    body['patronymic'] = newUser.patronymic;
  } else {
    body['patronymic'] = '';
  }
  if (newUser.positions.length > 0) body['positions'] = newUser.positions;
  if (newUser.birthday) body['birthday'] = newUser.birthday;

  let schedule = {
    weekDays: [
      ...newUser.schedule.map((day: number) => ({
        day,
        time: [{ begin: newUser.workBegin, end: newUser.workEnd }],
      })),
    ],
  };
  if (newUser.daysOff.length > 0) {
    schedule['daysOff'] = [
      ...newUser.daysOff.map((dayOff: DayOff) => ({
        date: dayOff.date, //отдаем в формате DD.MM.YYYY
        //date: (new Date(dayOff.date)).toLocaleDateString(),
        comment: dayOff.comment,
      })),
    ];
  }

  if (newUser.image instanceof File) {
    let formData = new FormData();
    formData.append('file', newUser.image);
    const image = yield call(fetchData.post, '/api/v1/files', formData, {
      Authorization: cookieMaster.getCookie('access_token'),
    });
    if (image) {
      body['image_file_id'] = image.id;
    }
  }
  let fetchedUserData;

  /*небольшой костыль, это по хорошему вынести на уровень компонентов*/

  if (update) {
    /*костыль, это по хорошему вынести на уровень компонентов*/
    body = checkUpdateData(body, id, curentUserId);
    /*костыль, это по хорошему вынести на уровень компонентов*/
    fetchedUserData = yield call(
      fetchData.patch,
      `/api/v1/companies/1/users/${id}`,
      JSON.stringify(body)
    );
    if (fetchedUserData) {
      yield call(
        fetchData.patch,
        `/api/v1/users/${id}/schedule`,
        JSON.stringify({ schedule })
      );
      CreateNotif('Данные пользователя успешно обновлены', 'success');
      yield put(clearUser());
    }
  } else {
    body['password'] = null;
    fetchedUserData = yield call(
      fetchData.post,
      '/api/v1/companies/1/users',
      JSON.stringify(body)
    );
    if (fetchedUserData) {
      let data = yield call(
        fetchData.post,
        `/api/v1/users/${fetchedUserData.id}/schedule`,
        JSON.stringify({ schedule })
      );
      if (data) {
        CreateNotif('Пользователь успешно создан', 'success');
        yield put(clearUser());
      }
    }
  }

  const history = createBrowserHistory();

  if (fetchedUserData) {
    yield put(
      setUsers([
        ...users.filter((user) => user.id !== id),
        {
          ...fetchedUserData,
          departments: newUser.departments,
          // positions: newUser.positions,
          // roles: newUser.roles
        },
      ])
    );
    if (fetchedUserData.id === curentUserId)
      yield put(setCurrentUserInfo(fetchedUserData));
    // window.history.pushState(null, '', '/users')
    history.push('/users');

    yield put(setUserAddMode(false));
  }

  yield put(setIsSending(false));
}

function* changePassword({ oldPassword, password }: changePass) {
  const dep = yield call(
    fetchData.post,
    '/api/v1/password/simple-change',
    JSON.stringify({
      oldPassword: oldPassword,
      password: password,
      password_confirmation: password,
    })
  );
  if (dep?.success) CreateNotif('Пароль успешно изменен', 'success');
}

function* resetPass() {
  const email = store.getState().commonInfo.currentUserInfo?.email;
  const dep = yield call(
    fetchData.post,
    '/api/v1/password/reset',
    JSON.stringify({
      email,
    })
  );

  if (dep.message === 'We have e-mailed your password reset link!') {
    CreateNotif('Ссылка для сброса пароля отправлена вам на почту', 'success');
  }
}

export function* watchPasswordReset() {
  yield takeEvery(types.PASS_RESET, resetPass);
}

export function* watchPasswordChange() {
  yield takeEvery(types.CHANGE_PASS, changePassword);
}

export function* watchCreateUser() {
  yield takeEvery(types.CREATE_USER, createUser);
}
