import React, {useEffect, useState, useCallback, useRef} from 'react';
import NameWithSubtitle from './NameWithSubtitle'
import {useParams} from 'react-router-dom';
import {State} from '../../../rootReducer';
import {connect} from 'react-redux';
import {User, Project, Role, Item} from '../../types';
import {setLoadingTasks} from '../../../pages/Tasks/actions';
import {setIsFetchingTasks} from '../../../pages/Projects/actions';
import {CreateNotif} from '../../../utils/createNotification';
import SelectWithSecondaryBtn from "./SelectWithSecondaryBtn";


type Props = {
    type: string,
    users: User[],
    projects: Project[],
    roles: Role[],
    itemsList: Item[],
    setLoadingTasks: (is: boolean) => void,
    setIsFetchingTasks: (is: boolean) => void,
}


const UserProjectSelect: React.FC<Props> = ({type, users, projects, roles, itemsList, setLoadingTasks, setIsFetchingTasks}) => {

    const [name, setName] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [open, setOpen] = useState(false)

    const {id} = useParams()
    const select = useRef<HTMLDivElement>(null)

    const outsideClick = useCallback((e: Event) => {
        if (!select.current?.contains(e.target as HTMLElement)) setOpen(false)
    }, [])

    const itemClickHandler = useCallback((e: React.MouseEvent) => {
        e.stopPropagation()
        if (type === 'tasks') {
            setLoadingTasks(true)
        } else {
            setIsFetchingTasks(true)
        }
        setOpen(false)
    }, [setIsFetchingTasks, setLoadingTasks, type])

    useEffect(() => {
        document.addEventListener('click', outsideClick)
        return () => document.removeEventListener('click', outsideClick)
    }, [outsideClick])

    useEffect(() => {
        if (type === 'tasks' && users.length > 0) {
            let current = users.find(user => user.id === +id)
            if (current) {
                setName(`${current.surname} ${current.name}`)
                let role = roles.find(role => role.id === current?.roles[0])
                if (role) setSubtitle(role.name)
            } else {
                CreateNotif('Пользователь удален, либо еще не создан')
                setName('Выбрать другого пользователя')
            }
        }
    }, [id, projects, roles, type, users])

    useEffect(() => {
        if (type === 'projects') {
            let current = projects.find(project => project.id === +id)
            if (current) {
                setName(current.name)
                setSubtitle(current.description)
            } else {
                CreateNotif('Проект удален, либо еще не создан')
                setName('Выбрать другой проект')
            }
        }
    }, [id, projects, type])


    return (
        <div ref={select} className="custom_select user_project_select">
            {/*это точно остается*/}
            <NameWithSubtitle context={type} name={name} subtitle={subtitle} isOpen={open} clickHandler={(val) => setOpen(val)}/>

            {/*это меняется на то, что используется в фильтрах*/}
            {open && (
                <SelectWithSecondaryBtn headOptions={{openDropdownByDefault: true, current_context_id: id, type: type}}
                                        className={'head_of_page'} list={itemsList} selected={null}
                                        selectHandler={() => {}} disabled={false}/>


                // <SelectWithSecondaryBtn
                //     list={FilteredParameter.list}
                //     selected={FilteredParameter.selected}
                //     selectHandler={(value) => {
                //         setDraftAction(value)
                //     }}
                //     disabled={!!FilteredParameterValue.selected}
                // />

                //
                // <div className="select_items">
                //     {itemsList
                //         .filter(item => parseInt(id) !== item.value)
                //         .map((item: Item, ind: number) => (
                //             <Link
                //                 key={item.value}
                //                 className={`custom_select_item${parseInt(id) === item.value ? ' selected_item' : ''}`}
                //                 to={`/${type}/${item.value}`}
                //                 onClick={itemClickHandler}
                //             >
                //                 {item.text}
                //             </Link>
                //         ))}
                // </div>
            )}
        </div>
    )
}


const mapStateToProps = (state: State) => ({
    users: state.commonInfo.users,
    projects: state.commonInfo.projects,
    roles: state.commonInfo.roles
})

const mapDispatchToProsp = {
    setLoadingTasks,
    setIsFetchingTasks
}

export default connect(mapStateToProps, mapDispatchToProsp)(UserProjectSelect)