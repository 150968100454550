import React from 'react';

import styled from 'styled-components';

type Props = {
    name?: string;
    src?: string;
    color?: string;
    hoverColor?: string;
    width?: string;
    cursor?: string;
    height?: string;
    onClick?: (e: React.MouseEvent) => void;
    style?: object;
    alt?: string;
};

const IconStyled = styled.img`
  display: inline-block;
  border-radius: inherit;
  cursor: ${({cursor}) => (cursor ? cursor : 'default')};
  font-size: 0;
  color: ${({iconColor}) => iconColor};
  width: ${({width}) => width && width};
  height: ${({height}) => height && height};
  background-image: url(${(src) => src});

  svg {
    fill: currentColor;
  }
  ${({hoverColor, iconColor}) => `
      &:hover {
        color: ${hoverColor || iconColor};
        cursor: pointer;
      }
    `};
`;

const Icon: React.FC<Props> = ({
                                   name,
                                   src = '',
                                   color,
                                   hoverColor,
                                   width,
                                   height,
                                   cursor,
                                   style,
                                   alt,
                                   onClick,
                               }) => {
    let path = '';
    if (src) {
        path = src;
    } else if (name && !src) {
        // eslint-disable-next-line
        path = require(`./imgs/${name}.svg`);
    } else {
        path = require(`./imgs/burger.svg`);
    }

    const iconColor = color || 'inherit';

    return (
        <IconStyled
            style={style}
            src={path}
            alt={alt}
            iconColor={iconColor}
            hoverColor={hoverColor}
            width={width}
            height={height}
            cursor={cursor}
            onClick={onClick}
        />
    );
};

export default Icon;
