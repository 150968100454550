import React from 'react';
import { setUserName } from '../actions';
import withTitle from '../../../utils/hoc/withTitle';
import { connect } from 'react-redux';
import Input from '../../../common/components/Inputs/Input';
import { State } from '../../../rootReducer';
import { User } from '../../../common/types';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';

type Props = {
  name: string;
  userId: number;
  currentUserInfo: User | null;
  setUserName: (name: string) => void;
};
//Имя можно менять для новых пользователей, для себя и если у пользователя есть роль  админа
const UserName: React.FC<Props> = ({
  name,
  userId,
  currentUserInfo,
  setUserName,
}) => {
  return (
    <NameWithInput title="Имя" iconName="people" necessarily>
      <Input
        disabled={
          !(
            userId === 0 ||
            userId === currentUserInfo?.id ||
            currentUserInfo?.roles.includes(1)
          )
        }
        inputType="text"
        placeholder=""
        title=""
        value={name}
        changeHandler={(name) => setUserName(name)}
        // isValid={!!name}
        // validText={'Имя пользователя является обязательным полем'}
      />
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => {
  return {
    name: state.newUser.name,
    userId: state.newUser.id,
    currentUserInfo: state.commonInfo.currentUserInfo,
  };
};

const mapDispatchToProps = {
  setUserName: setUserName,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserName);
