import React from 'react';
import { State } from '../../../rootReducer';
import { connect } from 'react-redux';
import { getDepGroups } from '../selectors';
import {
  fetchUsers,
  selectDepGroup,
  setAddUserToGroupDep,
  setDepGroupType,
  setUsers,
} from '../actions';
import { User } from '../../../common/types';
import styled from 'styled-components';
import Icon from '../../../common/components/Icon';
type Props = {
  name: string;
  id?: number;
  selectedDepGroupId: number | null;
  plateType: string | null;
  type: string;
  setAddUserToGroupDep: (val: boolean) => void;
  setDepGroupType: (val: string | null) => void;
  selectDepGroup: (val: number | null, totalUsers: number | null) => void;
  number_of_employees: number;
  fetchUsers: (pageNum: number) => void;
  setUsers: (val: User[]) => void;
};

const DepListElementStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ active }) =>
    active &&
    `
    background:#F3FAFE;
    color: #1BAAF0;
   
    `}
  &:hover {
    div {
      display: none;
    }
    img {
      display: flex;
    }
  }
  span {
    color: #212121;
    font-size: 14px;
    line-height: 32px;
    padding: 0 12px;
    max-width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  div {
    color: #a8a8a8;
    height: 32px;
    line-height: 32px;
    margin: 0 12px;
    text-align: center;
  }
  img {
    display: none;
    margin-right: 4px;
  }
`;

const DepGroupListElem: React.FC<Props> = ({
  name,
  setUsers,
  fetchUsers,
  number_of_employees,
  selectDepGroup,
  setDepGroupType,
  setAddUserToGroupDep,
  id,
  selectedDepGroupId,
  type,
  plateType,
}) => {
  return (
    <DepListElementStyle
      onClick={() => {
        if (id) {
          setDepGroupType(type);
          selectDepGroup(id, number_of_employees);
        } else {
          // У пункта Все нет айдишника
          selectDepGroup(null, null);
          setDepGroupType(null);
          setUsers([]);
        }
        fetchUsers(1);
      }}
      active={
        (selectedDepGroupId === id && plateType === type) ||
        (!id && selectedDepGroupId === null)
      }
    >
      <span>{name}</span>
      <div>{number_of_employees}</div>
      {id && (
        <Icon
          name="setting"
          width="24px"
          height="24px"
          onClick={() => {
            selectDepGroup(id, number_of_employees);
            setAddUserToGroupDep(true);
          }}
        />
        // <div
        //   className={'gearIcon'}
        //   onClick={() => {
        //     selectDepGroup(id, number_of_employees);
        //     setAddUserToGroupDep(true);
        //   }}
        // />
      )}
    </DepListElementStyle>
  );
};

const mapStateToProps = (state: State, props) => ({
  selectedDepGroupId: state.usersPage.selectedDepGroupId,
  plateType: state.usersPage.depGroupPlateType,
  groups: getDepGroups(state, props),
});

const mapDispatchToProps = {
  setAddUserToGroupDep,
  setUsers,
  setDepGroupType,
  selectDepGroup,
  fetchUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(DepGroupListElem);
