import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { fetchData } from '../../../utils/fetchData';

const EmailVerify = () => {
  const location = useLocation();
  const [status, setStatus] = useState('');

  useEffect(() => {
    (async () => {
      let status = await fetchData.get(
        `${location.pathname}${location.search}`,
        {}
      );

      if (status) {
        setStatus(status);
      } else {
        setStatus('error');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {status === 'Email verified!' && (
        <>
          <div className="sub_title">Пользователь успешно верифицирован!</div>
          <Link to="/login">Вход</Link>
        </>
      )}
      {status === 'error' && (
        <>
          <div className="sub_title">Ошибка верификации</div>
          <div className="have_account">Уже есть аккаунт?</div>
          <Link to="/login">Войти</Link>
        </>
      )}
    </>
  );
};

export default EmailVerify;
