import React, { useState } from 'react';
import CheckListItem from './CheckListItem';

const CheckListModule = ({ action, checkList, disabled }) => {
  const [buttonAddNewRow, setbuttonAddNewRow] = useState(true);

  // useMemo(() => {
  //     setbuttonAddNewRow(!!checkList.length)
  // }, [checkList])

  let counterDone = 0;
  checkList.forEach((item) => {
    if (item.done) counterDone++;
  });

  let progressBarPercents = 0;
  if (checkList.length)
    progressBarPercents = (counterDone / checkList.length) * 100;
  const progressObj = { width: progressBarPercents + '%' };

  return (
    <div className={'checklist'}>
      <div
        className={'header'}
        onClick={(e) => {
          let classList = e.currentTarget.children[1].classList;
          if (classList.length) {
            if (classList[0] === 'collapsedCheckListItems') {
              classList.remove('collapsedCheckListItems');
              classList.add('expandedCheckListItems');
              // @ts-ignore
              document.getElementById('checkItems').style.display = 'block';
            } else {
              classList.remove('expandedCheckListItems');
              classList.add('collapsedCheckListItems');
              // @ts-ignore
              document.getElementById('checkItems').style.display = 'none';
            }
          }
        }}
      >
        <div>Чеклист</div>
        <div className={'collapsedCheckListItems'} />
      </div>

      <div className={'progressBar'}>
        <div className={'digit_progress'}>
          {counterDone}/{checkList.length}
        </div>
        <div className={'bar'}>
          <div className={'all_time'} />
          <div className={'completed'} style={progressObj} />
        </div>
      </div>

      <div id={'checkItems'}>
        {/*здесь рендерится лист уже существующих*/}
        {checkList.map((item, item_id) => {
          return (
            <CheckListItem
              key={item.id + item.name}
              // data-position={}
              inputFieldDispatch={(
                orderNumber,
                nameItem,
                checkboxValue,
                item_as_input,
                isDelete,
                onlyChangeReducer
              ) => {
                // console.log(nameItem, isDelete)
                // если айдишник у этого айтема уже есть, значит потеря фокуса - это изменение
                if (nameItem) {
                  action(
                    orderNumber,
                    nameItem,
                    checkboxValue,
                    isDelete ? 'delete' : item.id ? 'patch' : 'create',
                    onlyChangeReducer
                  );
                }
                item_as_input
                  ? setbuttonAddNewRow(true)
                  : setbuttonAddNewRow(false);
              }}
              fieldAsInput={false}
              checked={item.done}
              textValue={item.name}
              orderNumber={item_id + 1}
              disabled={disabled}
            />
          );
        })}

        {!disabled &&
          (buttonAddNewRow ? (
            // показываю кнопку
            <div
              id={'addCheckItem'}
              onClick={(e) => {
                // e.currentTarget.parentNode?.appendChild()
                setbuttonAddNewRow(false);
              }}
            >
              <div className={'addNewRowIcon'} />
              <div className={'addNewRowText'}>Добавить пункт</div>
            </div>
          ) : (
            // показываю новое поле ввода
            <CheckListItem
              // это значит запрос на отправку уходит
              inputFieldDispatch={(
                orderNumber,
                nameItem,
                checkboxValue,
                item_as_input,
                isDelete,
                onlyChangeReducer
              ) => {
                if (nameItem) {
                  action(
                    orderNumber,
                    nameItem,
                    checkboxValue,
                    'create',
                    onlyChangeReducer
                  );
                }
                item_as_input
                  ? setbuttonAddNewRow(true)
                  : setbuttonAddNewRow(false);
              }}
              fieldAsInput={true}
              checked={false}
              textValue={''}
              orderNumber={checkList.length + 1}
              disabled={disabled}
            />
          ))}
      </div>
    </div>
  );
};

export default CheckListModule;
