import React from 'react';
import withTitle from '../../../utils/hoc/withTitle';
import { connect } from 'react-redux';
import SelectWithSecondaryBtn from '../../../common/components/Selects/SelectWithSecondaryBtn';
import { setUserPosition } from '../actions';
import { State } from '../../../rootReducer';
import { getPosSelectData } from '../selectors';
import { SelectData, User } from '../../../common/types';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';

type Props = {
  posSelectData: SelectData;
  userId: number;
  currentUserInfo: User | null;
  setUserPosition: (position: number[]) => void;
};

//Должность можно менять для новых пользователей, и для других пользователей, если ты админ или директор

const PositionSelect: React.FC<Props> = ({
  posSelectData,
  userId,
  currentUserInfo,
  setUserPosition,
}) => {
  return (
    <NameWithInput title="Должность" iconName="bag">
      <SelectWithSecondaryBtn
        disabled={
          !(
            (userId === 0 || userId !== currentUserInfo?.id) &&
            (currentUserInfo?.roles.includes(1) ||
              currentUserInfo?.roles.includes(2))
          )
        }
        list={posSelectData.list}
        selected={posSelectData.selected}
        selectHandler={(value) => setUserPosition([value as number])}
      />
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => ({
  posSelectData: getPosSelectData(state),
  userId: state.newUser.id,
  currentUserInfo: state.commonInfo.currentUserInfo,
});

const mapDispatchToProps = {
  setUserPosition: setUserPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionSelect);
