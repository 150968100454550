import React, { useCallback, useEffect } from 'react';
import TaskDateTime from '../../../common/components/DatePickers/DateTimePiker';
import withTitle from '../../../utils/hoc/withTitle';
import { connect } from 'react-redux';
import { recalculateTaskLoad, setStartCyclick, setTaskEnd } from '../actions';
import { State } from '../../../rootReducer';
import { updateTask } from '../../../common/actions';
import SelectWithSecondaryBtn from '../../../common/components/Selects/SelectWithSecondaryBtn';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';

const RepeatTask = ({
  disabledComponent,
  selectedTask,
  updateTask,
  repeat,
  setStartCyclick,
}) => {
  const valueSettingRepeat = {
    '': 0,
    'every day': 1,
    'every week': 2,
    'every month': 3,
    settings: 4,
  };

  const repeat_main = [
    { text: 'Нет', value: '' },
    { text: 'Каждый день', value: 'every day' },
    { text: 'Каждую неделю', value: 'every week' },
    { text: 'Каждый месяц', value: 'every month' },
    { text: 'Настроить', value: 'settings' },
  ];

  const selectHandler = (value) => {
    if (value === 'every week') {
      // каждую неделю
      setStartCyclick(value, [new Date().getDay()], 1, false, '');
    } else if (value === 'every month') {
      // каждый месяц
      setStartCyclick(value, [new Date().getDate()], 1, false, '');
    } else if (value === 'settings') {
      // кастом
      setStartCyclick(
        repeat.period === '' ? value : repeat.period,
        repeat.params,
        repeat.interval,
        true
      );
    } else {
      // каждый день
      setStartCyclick(value, [], 1, false, '');
    }
  };

  return (
    <div
      className={`repeat_first_field ${
        repeat.params.length ? 'fullWidth' : 'standart'
      }`}
    >
      <SelectWithSecondaryBtn
        list={repeat_main}
        selected={
          repeat.settings_string === ''
            ? repeat_main[valueSettingRepeat[repeat.period]]
            : {
                text: repeat.settings_string,
                value: repeat.period,
              }
        }
        disabled={disabledComponent}
        selectHandler={selectHandler}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.selectedTask,
    repeat: state.taskInfoPlate.repeat,
    end: state.taskInfoPlate.end,
    minDate: state.taskInfoPlate.begin,
    delegated: state.taskInfoPlate.delegated,
  };
};

const mapDispatchToProps = {
  setStartCyclick,
  updateTask,
  setTaskEnd,
  recalculateTaskLoad,
};

export default connect(mapStateToProps, mapDispatchToProps)(RepeatTask);
