import React, {useEffect, useRef, useState} from 'react';
import ProjectTableHead from './ProjectTableHead';
import ProjectTableContent from './ProjectTableContent';
import {State} from '../../../rootReducer';
import {connect} from 'react-redux';
import {Task, Project} from '../../../common/types';
import {useParams} from 'react-router-dom';
import {changeTaskList, fetchTasks} from '../actions';
import {columnsOfTables} from '../../../common/shared_data';

import styled from 'styled-components';

type Props = {
    isFetchingTasks: boolean;
    tasks: Task[];
    projects: Project[];
    changeTaskList: (tasks: Task[]) => void;
    fetchTasks: (id: number, pageNum) => void;
    activeColumns: string[];
};

const WrapperTasksTable = styled.div`
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
  position: relative;

  ${({isFetchingTasks}) =>
    isFetchingTasks &&
    `
&:before {
      content: '';
      background: #fff;
      z-index: 3;
      width:100%;
      height: 100%;
      position:absolute;
      top:-5px;
      right:0;
}
    &:after {
      content: '';
      border-radius: 50%;
      border: 3px solid #1BAAF0;
      z-index: 3;
      border-bottom-color: #fff;
      border-left-color: #fff;
      animation: spin .5s linear infinite;
      transform: translateX(-50%);
      position:absolute;
      top:250px;
      left:50%;
      height:16px;
      width:16px;
    }
`}
`;

const ProjectTable: React.FC<Props> = ({
                                           activeColumns,
                                           isFetchingTasks,
                                           tasks,
                                           projects,
                                           changeTaskList,
                                           fetchTasks,
                                       }) => {
    const {id} = useParams();
    const [pageNum, setPageNum] = useState(1);
    useEffect(() => {
        changeTaskList([]);
    }, [id, changeTaskList]);

    useEffect(() => {
        fetchTasks(id, 1);
    }, [fetchTasks, id]);

    return (
        // <div
        //     className={`table tasks_table${isFetchingTasks ? ' in_load' : ''} table_${
        //         activeColumns.length
        //     }${
        //         activeColumns.includes(columnsOfTables[0].columnId) ? '' : ' table_id'
        //     }`}
        <WrapperTasksTable isFetchingTasks={isFetchingTasks}>
            {projects.length === 0 ? (
                <div style={{paddingLeft: 24}} className="main_title">
                    Проекты отсутствуют
                </div>
            ) : (
                <>
                    {/*<ProjectTableHead setPageNum={setPageNum}/>*/}
                    {tasks.length === 0 ? (
                        <div style={{padding: 24}} className="main_title">
                            Задачи отсутствуют
                        </div>
                    ) : (
                        <ProjectTableContent pageNum={pageNum} setPageNum={setPageNum}/>
                    )}
                </>
            )}
        </WrapperTasksTable>
    );
};

const mapStateToProps = (state: State) => ({
    activeColumns: state.projectsPage.activeColumns,
    isFetchingTasks: state.projectsPage.isFetchingTasks,
    tasks: state.projectsPage.tasks,
    projects: state.commonInfo.projects,
});

const mapDispatchToProps = {
    changeTaskList,
    fetchTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTable);
