import React, { useCallback, useState } from 'react';
import Input from '../../../common/components/Inputs/Input';
import { Link } from 'react-router-dom';
import { fetchData } from '../../../utils/fetchData';

const EmailForm = () => {
  const [email, setEmail] = useState('');
  const [isSended, setIsSended] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const formSubmitHandler = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      setIsSending(true);
      e.preventDefault();
      let result = await fetchData.post(
        '/api/v1/password/reset',
        JSON.stringify({ email })
      );
      if (typeof result !== 'undefined') {
        setIsSended(true);
      }
      setIsSending(false);
    },
    [email]
  );

  return (
    <form onSubmit={formSubmitHandler} className="login_form">
      <div className="sub_title">
        {isSended
          ? 'Ссылка для восстановления пароля была отправлена на ваш Email'
          : 'Забыли пароль?'}
      </div>
      {!isSended && (
        <>
          <Input
            inputType="text"
            placeholder="Email"
            title=""
            withIcon={false}
            value={email}
            changeHandler={(value) => setEmail(value)}
          />
          <button className={`btn primary${isSending ? ' in_load' : ''}`}>
            Готово
          </button>
        </>
      )}
      <Link to="/login">Вход</Link>
    </form>
  );
};

export default EmailForm;
