import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Item } from '../../types';
import Chips from '../ChipsItem';
import styled, { css } from 'styled-components';
import CheckboxPeople from '../CheckboxItem/CheckboxPeopel';
import SearchInput from '../newComponent/Inputs/SearchInput';
// import { Hash } from 'crypto';
// import Button from '../newComponent/Buttons/Button';

type Props = {
  selectedItems: Item[];
  itemsList: Item[];
  disabled?: boolean;
  placeholder?: string;
  radio?: boolean; // возможность выборать 1 элемент (true) или несколько(false)
  isValid?: boolean;
  addHandler: (value: number) => void;
  removeHandler: (value: number) => void;
  showSearchField?: boolean;
};
const WrapperMultySelect = styled.div`
  position: relative;
`;
const WrapperChipsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.25rem;
`;

const ConfirmButton = styled.div`
  // display: flex
  // margin-top: 8px
`;

const MultySelect: React.FC<Props> = ({
  selectedItems,
  itemsList,
  placeholder,
  isValid = true,
  disabled = false,
  radio = false,
  addHandler,
  removeHandler,
  showSearchField = true,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const list = useRef<HTMLDivElement>(null);

  const [filterItemsList, setFilterItemsList] = useState([...itemsList]);

  const search = (event) => {
    setShow(true);
    setFilterItemsList([...itemsList]);
    let searchString = event.target.value.toLowerCase();

    let filter = filterItemsList.filter((data) =>
      data.text?.toLowerCase().includes(searchString)
    );
    // console.log('filterItemsList', filterItemsList);
    setFilterItemsList([...itemsList]);

    if (searchString.length === 0) {
      setFilterItemsList([...itemsList]);
    } else {
      setFilterItemsList(filter);
    }
  };

  const outsideClick = useCallback(
    (e: Event) => {
      if (show && !list.current?.contains(e.target as HTMLElement))
        setShow(false);
    },
    [show]
  );
  useEffect(() => {
    document.addEventListener('click', outsideClick);
    return () => document.removeEventListener('click', outsideClick);
  }, [outsideClick]);

  useEffect(() => {
    if (radio && selectedItems.length) {
      setFilterItemsList([...itemsList]);
      setShow(false);
    }
    setFilterItemsList([...itemsList]);
  }, [selectedItems.length, radio, itemsList]);

  return (
    <WrapperMultySelect ref={list}>
      {showSearchField &&
        (!radio ? ( // мультипл выбор
          <SearchInput
            disabled={disabled}
            placeholder={placeholder}
            onChange={search}
            onClick={() => {
              // setFilterItemsList([...itemsList]);
              setShow(!show);
            }}
          />
        ) : radio && selectedItems.length === 0 ? ( // сингл выбор
          <SearchInput
            disabled={disabled}
            placeholder={placeholder}
            onChange={search}
            onClick={() => {
              setFilterItemsList([...itemsList]);
              setShow(!show);
            }}
          />
        ) : null)}

      <WrapperChipsBlock>
        {selectedItems.map((item) => {
          return (
            <Chips
              text={item.rus_name ? item.rus_name : item.text}
              key={item.value}
              image={item.urlImage}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                removeHandler(item.value as number);
              }}
            />
          );
        })}
      </WrapperChipsBlock>

      {show && (
        <CheckboxPeople
          users={filterItemsList}
          selectedUsers={selectedItems}
          addHandler={(arg) => {
            if (radio) setShow(false); // закрывать выпадающий список при клике по пункту, если это сингл выбор
            addHandler(arg);
          }}
          removeHandler={removeHandler}
          radio={radio}
        />
      )}
    </WrapperMultySelect>
  );
};

export default MultySelect;
