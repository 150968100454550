import React from 'react';
import './styles.sass'

export type ToggleElem = {
    name: string
    value?: string | number
    id?: number
}

type Props = {
    elemsList: ToggleElem[]
    activeValue?: string | number | null
    selectHandler: (value: string | number) => void
}


const Toggler: React.FC<Props> = ({elemsList, activeValue = null, selectHandler}) => {
    return (
        <div className="toggler">
            {elemsList.map(elem => (
                <div
                    key={elem.value || elem.id}
                    className={`toggler_elem ${elem.value === activeValue || elem.id === activeValue ? ' active_toggler_elem' : ''}`}
                    onClick={() => selectHandler(elem.value ? elem.value : elem.id as number | string)}
                >
                    {elem.name}
                </div>
            ))}
        </div>
    )
}


export default Toggler

