import React from 'react';
import TasksActions from './components/TasksActions';
import { useSelector } from 'react-redux';
import TasksTable from './components/TasksTable';
import { State } from '../../rootReducer';
import Visualization from './components/Visualization/';

const Tasks: React.FC = () => {
  const {
    selectedUserId,
    visualizationData,
    showVisualization,
    tasks,
  } = useSelector((state: State) => state.tasksPage);
  // const cmp = (props) => {

  // if(hash) return <HashPage>
  //   else return <NormalPage>
  // }

  // console.log(id, task_id)
  // useEffect(() => {
  // if (id && users.length > 0) {
  // let user = users.find(u => u.id === +id)
  // if (user) {
  //     openExistingUser(user)
  // }
  // setUserAddMode(true)
  // }
  // }, [id, task_id])

  return (
    <>
      <TasksActions />
      {/* <Collapse in={showVisualization}>
          </Collapse> */}
      {showVisualization && (
        <Visualization
          tasks={tasks}
          showVisualization={showVisualization}
          selectedUserId={selectedUserId}
          visualizationData={visualizationData}
        />
      )}
      <TasksTable />
    </>
  );
};

export default Tasks;
