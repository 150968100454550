import React, { useState } from 'react';
// import Input from '../../../common/components/Inputs/Input';
import SectionElem from './SectionElem';
import { connect } from 'react-redux';
import { setProjectSections, addSection } from '../actions';
import { State } from '../../../rootReducer';
import { getSections } from '../selectors';
import styled from 'styled-components';
import { SectionInComponent } from '../../../common/types';

import Button from '../../../common/components/newComponent/Buttons/Button';
import CustomInput from '../../../common/components/newComponent/Inputs/CustomInput';

type Props = {
  projectId: number;
  sections: SectionInComponent[];
  setProjectSections: (sections: SectionInComponent[]) => void;
  addSection: (projectId: number, name: string) => void;
};

const WrapperBlockInput = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WrapperBlockSections = styled.div`
  ol {
    margin-left: 1rem;
  }
  li {
    height: 40px;
    line-height: 40px;
    margin: 0.5rem 0;

    div {
      font-size: 15px;
      color: #212121;

      img {
        margin-left: 12px;
        opacity: 0;
        cursor: pointer;
        transition: opacity 0.1s ease;

        &:last-of-type {
          margin-left: auto;
        }
      }

      &:hover {
        img {
          opacity: 1;
        }
      }
    }
  }
`;

const ProjectSections: React.FC<Props> = ({
  sections,
  projectId,
  setProjectSections,
  addSection,
}) => {
  const [sectionName, setSectionName] = useState('');

  const addHandler = () => {
    if (!sectionName) return;
    if (projectId) {
      addSection(projectId, sectionName);
    } else {
      setProjectSections([...sections, { name: sectionName, id: 0 }]);
    }
    setSectionName('');
  };

  return (
    <WrapperBlockSections className="project_sections">
      <WrapperBlockInput>
        {/* <Input
          inputType="text"
          placeholder=""
          title=""
          value={sectionName}
          changeHandler={setSectionName}
        /> */}
        <CustomInput value={sectionName} inputChangeHandler={setSectionName} />
        <Button
          onClick={addHandler}
          title="Добавить"
          design="outline"
          style={{ marginLeft: '.5rem', alignItems: 'center' }}
        />
      </WrapperBlockInput>
      {sections.length > 0 && (
        <ol>
          {sections.map((section, ind) => (
            <SectionElem
              name={section.name}
              id={section.id ? section.id : 0}
              key={`${performance.now()}_${ind}`}
              index={ind}
            />
          ))}
        </ol>
      )}
    </WrapperBlockSections>
  );
};

const mapStateToProps = (state: State) => {
  return {
    sections: getSections(state),
    projectId: state.newProject.id,
  };
};

const mapDispatchToProps = {
  setProjectSections,
  addSection,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSections);
