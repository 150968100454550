import React, {
    useState,
    useCallback,
    useEffect,
    useRef,
    useLayoutEffect,
} from 'react';
import TableSearch from '../../../common/components/TableSearch/TableSearch';
import {State} from '../../../rootReducer';
import {
    setTableFilter,
    changeTaskList,
    fetchTasks,
    setOrder,
} from '../actions';
import {connect} from 'react-redux';
import {
    fetchTasksAction,
    setTableFilterAction,
    changeTaskListAction,
} from '../action_types';
import {Task} from '../../../common/types';

import DetailsRoundedIcon from '@material-ui/icons/DetailsRounded';
import ChangeHistoryRoundedIcon from '@material-ui/icons/ChangeHistoryRounded';
import {columnsOfTables} from '../../../common/shared_data';
import Icon from "../../../common/components/Icon";
import styled from 'styled-components';
import SortIcon from '../../../common/components/SortIcon';

type Props = {
    tableFilter: string;
    projectId: number | null;
    setTableFilter: (filter: string) => setTableFilterAction;
    changeTaskList: (tasks: Task[]) => changeTaskListAction;
    fetchTasks: (projecId: number, pageNum: number) => fetchTasksAction;
    setOrder: any;
    activeColumns: string[];
    setPageNum: (pageNum: number) => void;
    isScroll: boolean;
};

const WrapperTasksTableHead = styled.div`
  margin-right: ${({ isScroll }) => isScroll && '8px'};
  margin-left: 40px;
  display: flex;
  height: 32px;
  justify-content: space-between;
`;

const BlockStyled = styled.div`
  display: flex;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: #a8a8a8;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
  }
`;

const HeaderItemName = styled.div`
  cursor: pointer;
  padding-left: 4px;
  & > span {
    position: relative;
  }
`;

const ProjectTableHead: React.FC<Props> = ({
  tableFilter,
  activeColumns,
  projectId,
  setTableFilter,
  changeTaskList,
  fetchTasks,
  setOrder,
  isScroll,
  setPageNum,
}) => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(
    null
  );
  const [Clicked, setClicked] = useState<any>({ col: 'end', count: 1 });

  const searchChangeHandler = useCallback(
    (filter: string) => {
      if (!projectId) return;
      setTableFilter(filter);
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          changeTaskList([]);
          fetchTasks(projectId, 1);
        }, 500)
      );
    },
    [changeTaskList, fetchTasks, projectId, setTableFilter, timer]
  );

  const firstUpdate = useRef(false);
  useLayoutEffect(() => {
    // при инициализации это не должно отрабатывать. для этого сделано условие с useRef
    if (firstUpdate.current) {
      setOrder(Clicked.col, Clicked.count === 1);
      setPageNum(1);
      return;
    }
    firstUpdate.current = true;
  }, [Clicked]);

  return (
    <WrapperTasksTableHead isScroll={isScroll}>
      <BlockStyled>
        {Object.keys(columnsOfTables).map((key) => {
          return (
            activeColumns.includes(columnsOfTables[key].columnId) &&
            (columnsOfTables[key].columnId === 'id' ||
              columnsOfTables[key].columnId === 'author_id' ||
              columnsOfTables[key].columnId === 'name') && (
              <HeaderItemName
                key={key}
                style={columnsOfTables[key].style}
                onClick={() => {
                  setClicked({
                    col: columnsOfTables[key].columnId,
                    count:
                      Clicked.col === columnsOfTables[key].columnId
                        ? Clicked.count === 2
                          ? 1
                          : Clicked.count + 1
                        : 1,
                  });
                }}
              >
                <span>
                  {columnsOfTables[key].showingText}
                  <SortIcon
                    active={Clicked.col === columnsOfTables[key].columnId}
                    activeSort={
                      Clicked.col === columnsOfTables[key].columnId &&
                      Clicked.count === 1
                        ? 'top'
                        : 'bottom'
                    }
                  />
                </span>
                {/*{Clicked.col === columnsOfTables[key].columnId && Clicked.count === 1 &&*/}
                {/*<Icon name={'sort down'} style={{marginLeft: '8px'}}/>*/}
                {/*}*/}
                {/*{Clicked.col === columnsOfTables[key].columnId && Clicked.count === 2 &&*/}
                {/*<Icon name={'sort up'} style={{marginLeft: '8px'}}/>}*/}
              </HeaderItemName>
            )
          );
        })}
      </BlockStyled>

      <BlockStyled>
        {Object.keys(columnsOfTables).map((key) => {
          return activeColumns.includes(columnsOfTables[key].columnId) &&
            (columnsOfTables[key].columnId === 'status_id' ||
              columnsOfTables[key].columnId === 'end' ||
              columnsOfTables[key].columnId === 'task_load' ||
              columnsOfTables[key].columnId === 'executor_id' ||
              columnsOfTables[key].columnId === 'project_name' ||
              columnsOfTables[key].columnId === 'priority_id') ? (
            <HeaderItemName
              key={columnsOfTables[key].columnId}
              style={columnsOfTables[key].style}
              onClick={() => {
                setClicked({
                  col: columnsOfTables[key].columnId,
                  count:
                    Clicked.col === columnsOfTables[key].columnId
                      ? Clicked.count === 2
                        ? 1
                        : Clicked.count + 1
                      : 1,
                });
              }}
            >
              <span>
                {columnsOfTables[key].showingText}
                <SortIcon
                  active={Clicked.col === columnsOfTables[key].columnId}
                  activeSort={
                    Clicked.col === columnsOfTables[key].columnId &&
                    Clicked.count === 1
                      ? 'top'
                      : 'bottom'
                  }
                />
              </span>
              {/* {Clicked.col === columnsOfTables[key].columnId &&
                Clicked.count === 1 && <DetailsRoundedIcon />}
              {Clicked.col === columnsOfTables[key].columnId &&
                Clicked.count === 2 && <ChangeHistoryRoundedIcon />} */}
            </HeaderItemName>
          ) : null;
        })}
      </BlockStyled>
    </WrapperTasksTableHead>
  );
};

const mapStateToProps = (state: State) => {
  return {
    activeColumns: state.projectsPage.activeColumns,
    tableFilter: state.projectsPage.tableFilter,
    projectId: state.projectsPage.selectedProject?.id || null,
  };
};

const mapDispatchToProps = {
  setTableFilter,
  changeTaskList,
  fetchTasks,
  setOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTableHead);
