export const patterns = {
    email: /^[\w]{1}[\w-.]*@[\w-]+\.[a-z]{2,5}$/i,
    password: /(?=.*[0-9])(?=.*[A-Z])[0-9a-zA-Z]{6,30}/g
}


export function validatePassword(password: string): boolean {
    if (!password) return false 
    if (!password.match(/[A-Z]/)) return false 
    if (!password.match(/[0-9]/i)) return false
    if (password.length > 30 || password.length < 6) return false 
    return true
}