import { getDeps, getPositions, getRoles } from '../../common/selectors';
import { createSelector } from 'reselect';
import { Department, Position, Role, Item } from '../../common/types';
import { State } from '../../rootReducer';
import { patterns, validatePassword } from '../../utils/validPatterns';

export const getUserDeps = (state: State): number[] =>
  state.newUser.departments;
export const getUserPositions = (state: State): number[] =>
  state.newUser.positions;
export const getUserRoles = (state: State): number[] => state.newUser.roles;
export const getUserName = (state: State): string => state.newUser.name;
export const getUserSurname = (state: State): string => state.newUser.surname;
export const getUserPatronimyc = (state: State): string =>
  state.newUser.patronymic;
export const getUserBirthday = (state: State): string => state.newUser.birthday;
export const getUserEmail = (state: State): string => state.newUser.email;
export const getUserSchedule = (state: State): number[] =>
  state.newUser.schedule;
export const getNewUserInfo = (state: State) => state.newUser;

export const getDepSelectData = createSelector(
  [getDeps, getUserDeps],
  (deps, userDeps) => {
    let selected: Item | null = null;
    let list = deps.map((dep: Department) => {
      let item = {
        text: dep.name,
        value: dep.id,
      };
      if (item.value === userDeps[0]) selected = item;
      return item;
    });
    return { list, selected };
  }
);

export const getPosSelectData = createSelector(
  [getPositions, getUserPositions],
  (positions, userPositions) => {
    let selected: Item | null = null;
    let list = positions.map((pos: Position) => {
      let item = {
        text: pos.name,
        value: pos.id,
      };
      if (item.value === userPositions[0]) selected = item;
      return item;
    });
    return { list, selected };
  }
);

export const getRoleSelectData = createSelector(
  [getRoles, getUserRoles],
  (roles, userRoles) => {
    let selected: Item[] = [];
    let list = roles.map((role: Role) => {
      let item = {
        text: role.name,
        value: role.id,
        rus_name: role.rus_name,
      };
      if (userRoles.includes(item.value)) selected.push(item);
      return item;
    });
    return { list, selected };
  }
);

export const getCanSend = createSelector(
  [getNewUserInfo],
  ({ password, email, name, surname, roles, id }) => {
    let canSend = true;
    //if (id === 0 && (!password || !validatePassword(password))) canSend = false
    if (!email || !patterns.email.test(email)) canSend = false;
    if (!name || !surname) canSend = false;
    if (roles.length === 0) canSend = false;
    return canSend;
  }
);
