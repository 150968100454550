//!!!!!!!!!не изменять структуру и порядок, только содержимое
export const columnsOfTables = [
  {
    columnId: 'id',
    showingText: '№',
    style: { minWidth: '40px' },
  },
  {
    columnId: 'author_id',
    showingText: 'Автор',
    style: { minWidth: '56px' },
  },
  {
    columnId: 'name',
    showingText: 'Задача',
    style: { minWidth: '256px' },
  },
  {
    columnId: 'status_id',
    showingText: 'Статус',
    style: { minWidth: '120px' },
  },
  {
    columnId: 'end',
    showingText: 'Дедлайн',
    style: { minWidth: '88px' },
  },
  {
    columnId: 'executor_id',
    showingText: 'Исп.',
    style: { minWidth: '56px' },
  },
  {
    columnId: 'task_load',
    showingText: 'Затраты',
    style: { minWidth: '56px' },
  },

  {
    columnId: 'project_name',
    showingText: 'Проект',
    style: { minWidth: '160px' },
  },
  //   {
  //     columnId: 'section_name',
  //     showingText: 'Раздел',
  //     style: { minWidth: '40px' },
  //     className: 'section_col a_center',
  //   },
  {
    columnId: 'priority_id',
    showingText: 'Приоритет',
    style: { minWidth: '80px' },
  },
];

////////// shared functions for Saga in tasks and Projects
export function sorting(tasksOrder) {
  if (tasksOrder.col_name === '') {
    return '';
  } else {
    let result = '&order=' + tasksOrder.col_name;
    if (!tasksOrder.order_direct) result += '&orderType=desc';
    return result;
  }
}

export function table_filter(tableFilter) {
  return tableFilter ? '&search=' + tableFilter : '';
}

export function CollectAllFilters(filters) {
  if (Object.keys(filters.appliedFilters).length) {
    // если это фильтр с айдишником 1 - это фильтр по статусу
    if (Object.keys(filters.appliedFilters).includes('1')) {
      let collect_all_values = '';
      // я не знаю одно ли там значение или массив из нескольких, поэтому для гарантии прохожу циклом по всему массиву
      Object.values(filters.appliedFilters).forEach((item) => {
        // и значения в конечном стринге разделяю запятыми
        if (collect_all_values.length) collect_all_values += ',';
        collect_all_values += item;
      });
      return '&status_id=' + collect_all_values;
    }
  } else return '';
}
////////////////////////////
