import React, { useState, useCallback } from 'react';
import withTitle from '../../../utils/hoc/withTitle';
import { connect } from 'react-redux';
import Input from '../../../common/components/Inputs/Input';
import CustomDatePicker from '../../../common/components/DatePickers/DatePicker';
import { DayOff, User } from '../../../common/types';
import { setUserDaysOff } from '../actions';
import { State } from '../../../rootReducer';
import { store } from '../../../App';
import DateMaster from '../../../utils/DateMaster';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';
import Button from '../../../common/components/newComponent/Buttons/Button';
const moment = require('moment');
require('moment-weekday-calc');

type Props = {
  daysOff: DayOff[];
  userId: number;
  currentUserInfo: User | null;
  setUserDaysOff: (daysOff: DayOff[]) => void;
};

//расписание можно менять для новых пользователей и если ты админ или директор

const DaysOff: React.FC<Props> = ({
  daysOff,
  userId,
  currentUserInfo,
  setUserDaysOff,
}) => {
  const [date, setDate] = useState(new Date().toISOString());
  const [comment, setComment] = useState('');

  const addHandler = useCallback(
    (date, comment) => {
      if (date && comment) {
        date = moment(date).format('DD.MM.YYYY');
        //console.log(moment(date).format('DD.MM.YYYY'))
        setUserDaysOff([{ date, comment }, ...daysOff]);
        setComment('');
      }
    },
    [daysOff, setUserDaysOff]
  );

  const deleteHanlder = useCallback(
    (index) => {
      let clone = daysOff.slice();
      clone.splice(index, 1);
      setUserDaysOff(clone);
    },
    [daysOff, setUserDaysOff]
  );

  return (
    <NameWithInput title="Выходные дни" iconName="clock">
      <div
        className={`days_off${
          userId === 0 ||
          currentUserInfo?.roles.includes(1) ||
          currentUserInfo?.roles.includes(2)
            ? ''
            : ' disabled'
        }`}
      >
        <div className="days_off_input">
          <CustomDatePicker
            date={new Date(date)}
            acceptHandler={(date) => setDate(date)}
          />
          <Input
            inputType="text"
            placeholder="Комментарий"
            title=""
            value={comment}
            changeHandler={(val) => setComment(val)}
          />
          <Button
            onClick={() => addHandler(date, comment)}
            title="+"
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '20px',
              marginLeft: '8px',
            }}
          />
        </div>
        <div className="days_off_list">
          {daysOff.map((day, index) => (
            <div key={day.date + day.comment} className="dayoff_elem">
              <div className="dayoff_date">{day.date}</div>
              <div className="dayoff_comment">{day.comment}</div>
              <div onClick={() => deleteHanlder(index)} className="cross">
                {' '}
              </div>
            </div>
          ))}
        </div>
      </div>
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => {
  return {
    daysOff: state.newUser.daysOff,
    userId: state.newUser.id,
    currentUserInfo: state.commonInfo.currentUserInfo,
  };
};

const mapDispatchToProps = {
  setUserDaysOff: setUserDaysOff,
};

export default connect(mapStateToProps, mapDispatchToProps)(DaysOff);
