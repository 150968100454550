import React, {useRef, useState} from 'react';
import './styles.sass'

type Props = {
    searchValue: string,
    isOpen?: boolean,
    setSearchValue: (value: string) => void
}


const TableSearch: React.FC<Props> = ({searchValue, isOpen = false, setSearchValue}) => {

    const [searchMode, setMode] = useState<boolean>(isOpen)

    const input = useRef<HTMLInputElement>(null)
    const window = useRef<HTMLInputElement>(null)

    // const outsideClickHandler = useCallback(function (e: Event): any {
    //     if (!window.current?.contains(e.target as Node)) {
    //         setMode(false)
    //     }
    // }, [setMode])
    // useEffect(() => {
    //     document.addEventListener('click', outsideClickHandler)
    //     return () => document.removeEventListener('click', outsideClickHandler)
    // }, [outsideClickHandler])


    return (
        <div ref={window} onClick={() => {
            setMode(true)
            setTimeout(() => input.current?.focus(), 0)
        }} className={`table_search${searchMode ? ' in_search_mode' : ''}`}>
            {searchMode ? (
                <>
                    <input
                        ref={input}
                        value={searchValue}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                        placeholder="Поиск"
                    />
                    <div onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()
                        setMode(false)
                        setSearchValue('')
                    }} className="close_search"/>
                </>
            ) : (
                <div>Поиск</div>
            )}
        </div>
    )
}


export default TableSearch