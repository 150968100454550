import { types, TasksAction } from './action_types';
import { Task } from '../../common/types';
import { Reducer } from 'redux';
import { cookieMaster } from '../../utils/CookieMaster';
import { columnsOfTables } from '../../common/shared_data';

let default_init_cols: string[] = [];

let localSt: any = localStorage.getItem('tasksTableCols');
if (localSt) {
  let parsed = JSON.parse(localSt);
  Object.keys(parsed).forEach((item) => {
    default_init_cols.push(parsed[item]);
  });
} else {
  columnsOfTables.forEach((item) => {
    if (item.columnId !== 'executor_id') {
      default_init_cols.push(item.columnId);
    }
  });
}

const initialState = {
  tasks: [] as Task[],
  taskAddMode: false,
  filtersWindowShow: false,
  isLoading: true,
  tableFilter: '',
  filters: {
    filterParametersList: [
      { text: 'Статус', value: 1 },
      // ,
      // {text: 'Автор', value: 2},
      // {text: 'Исполнитель', value: 3},
      // {text: 'Задача', value: 4},
      // {text: 'Дата', value: 5}
    ],
    // appliedFilters: {1: [2, 4]},
    appliedFilters: {},
    draft: 0,
    addNewRow: true,
    exec_auth: '',
  },
  selectedUserId: parseInt(cookieMaster.getCookie('user_id')),
  showVisualization: false,
  showColumnsWindow: false,
  visualizationData: [] as Task[],
  isShownDayClose: false,
  tasksOrder: { col_name: 'end', order_direct: true },
  activeColumns: default_init_cols,
};

export type TasksState = typeof initialState;

const reducer: Reducer<TasksState, TasksAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.CHANGE_TASK_LIST:
      return {
        ...state,
        tasks: action.tasks,
      };
    case types.CLEAR_VISUALIZATION_LIST:
      return {
        ...state,
        visualizationData: [],
      };
    case types.CHANGE_VISUALISATION_LIST:
      return {
        ...state,
        visualizationData: action.tasks,
      };
    case types.SET_TASK_ADD_MODE:
      return {
        ...state,
        taskAddMode: action.mode,
      };
    case types.SET_FILTER_WIN_MODE:
      return {
        ...state,
        filtersWindowShow: action.mode,
        filters: Object.assign(
          {},
          state.filters,
          {
            addNewRow: !action.mode
              ? !Object.keys(state.filters.appliedFilters).length
              : state.filters.addNewRow,
          },
          { draft: 0 }
        ),
      };
    case types.SET_LOADING_TASK:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case types.SET_SELECTED_USER_ID:
      return {
        ...state,
        selectedUserId: action.id,
      };
    case types.SET_TABLE_FILTER:
      return {
        ...state,
        tableFilter: action.filter,
      };
    case types.SET_FILTERS:
      // пока что реализовано только для целочисленных значений внутри массива
      return {
        ...state,
        filters: Object.assign(
          {},
          state.filters,
          { appliedFilters: action.filterObject },
          { draft: 0 }
        ),
      };
    case types.SET_DRAFT_VALUE:
      return {
        ...state,
        filters: Object.assign(
          {},
          state.filters,
          { draft: action.draft },
          { addNewRow: false }
        ),
      };
    case types.SET_FILTERS_ADD_NEW_ROW:
      return {
        ...state,
        filters: Object.assign({}, state.filters, { addNewRow: action.value }),
      };
    case types.SET_SHOW_VISUALIZATION:
      return {
        ...state,
        showVisualization: action.show,
      };
    case types.SET_SHOW_COLUMNS_WINDOW:
      return {
        ...state,
        showColumnsWindow: action.show,
      };
    case types.SET_SHOW_DAYS_OFF:
      return {
        ...state,
        isShownDayClose: action.show,
      };
    case types.SET_ALL_OR_ACTUAL:
      return {
        ...state,
        filters: Object.assign(
          {},
          state.filters,
          {
            exec_auth:
              action.value['exec_auth_value'] === 'executor'
                ? state.filters.exec_auth === 'author' &&
                  action.value['erase_applied_filters']
                  ? state.filters.exec_auth
                  : 'executor'
                : action.value['exec_auth_value'],
          },
          {
            appliedFilters: action.value['erase_applied_filters']
              ? {}
              : state.filters.appliedFilters,
          },
          { addNewRow: !state.filters.draft }
        ),
      };
    case types.SET_EXEC_AUTH:
      return {
        ...state,
        filters: Object.assign({}, state.filters, { exec_auth: action.val }),
      };
    case types.SET_TABLE_ORDER:
      return {
        ...state,
        tasksOrder: {
          col_name: action.col_name,
          order_direct: action.order_direct,
        },
      };
    case types.SET_SHOWING_COLS:
      const val = action.value;
      let new_arr: string[] = [];

      if (val === 'DEFAULT' || val === 'ALL') {
        if (val === 'DEFAULT') {
          columnsOfTables.forEach((item) => {
            if (
              !(
                item.columnId === 'executor_id' ||
                item.columnId === 'project_name' ||
                item.columnId === 'section_name'
              )
            ) {
              new_arr.push(item.columnId);
            }
          });
        } else columnsOfTables.forEach((item) => new_arr.push(item.columnId));
      } else {
        // копирую стэйт в новый объект, если вставлю старый, ререндера не произойдет
        state.activeColumns.forEach((item) => new_arr.push(item));
        new_arr.includes(val)
          ? new_arr.splice(new_arr.indexOf(val), 1)
          : new_arr.push(val);
      }

      // localStorage.removeItem('tasksTableCols')
      localStorage.setItem('tasksTableCols', JSON.stringify({ ...new_arr }));

      return {
        ...state,
        activeColumns: new_arr,
      };

    default:
      return state;
  }
};

export default reducer;
