import React, { useMemo, useCallback } from 'react';
import { Task, Section, User } from '../../../common/types';
import DateMaster from '../../../utils/DateMaster';
//import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import ProjectCell from '../../../common/components/TableCells/ProjectSection';
//import PartitionCell from '../../../common/components/TableCells/PartitionCell';
import IdCell from '../../../common/components/TableCells/IdCell';
import TaskNameCell from '../../../common/components/TableCells/TaskNameCell';
import DeadLineCell from '../../../common/components/TableCells/DeadLineCell';
import ExecutorCell from '../../../common/components/TableCells/ExecutorCell';
import TaskWorkLoadingCell from '../../../common/components/TableCells/TaskWorkLoadingCell';
import PriorityCell from '../../../common/components/TableCells/PriorityCell';
import { selectTask } from '../../TaskInfoPlate/actions';
import { connect } from 'react-redux';
import TaskStatus from '../../../common/components/TaskStatus/TaskStatus';
import { setShownTaskInfoPlate } from '../../TaskInfoPlate/actions';
import { State } from '../../../rootReducer';
import { getSelectedTask } from '../../TaskInfoPlate/selectors';
import { openExistingTask, setProjectSections } from '../../NewTask/actions';
import { setTaskAddMode } from '../actions';
import { store, urlApp } from '../../../App';
import { fetchData } from '../../../utils/fetchData';
import styled from 'styled-components';
import { columnsOfTables } from '../../../common/shared_data';

type Props = {
  task: Task;
  selectedTask: Task | null;
  taskAddMode: boolean;
  users: User[];
  selectTask: (task: Task) => void;
  setShownTaskInfoPlate: (isShownTaskInfoPlate: boolean) => void;
  openExistingTask: (task: Task) => void;
  setTaskAddMode: (mode: boolean) => void;
  setProjectSections: (sections: Section[]) => void;
  activeColumns: string[];
  projects: object;
  partitions: object;
};
const TaskStyle = styled.div`
  display: flex;
  margin-right: 40px;
  justify-content: space-between;
  height: 56px;
  border-bottom: 1px solid #e0e0e0;
  background: #ffffff;
  position: relative;
  transition: background-color 200ms ease;

  &:hover {
    background-color: #f2f2f2;
  }
  ${({ active }) =>
    active &&
    `
    background: #F3FAFE!important;
    border-bottom: 1px solid #99D7F5!important;
    `}
`;
const BlockLeftFlex = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const BlockRightFlex = styled.div`
  position: relative;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

// const DragHandle = SortableHandle(() => <div className="task_drag_handle" />);

const TaskTr: React.FC<Props> = ({
  task,
  users,
  projects,
  partitions,
  selectedTask,
  setProjectSections,
  selectTask,
  setShownTaskInfoPlate,
  openExistingTask,
  setTaskAddMode,
  activeColumns,
}) => {
  const dateMaster = useMemo(() => {
    return new DateMaster(task.end);
  }, [task]);

  const trClickHandler = useCallback(
    (e: React.MouseEvent) => {
      let target = e.target;
      setTimeout(() => {
        if (store.getState().tasksPage.taskAddMode) return;
        if (selectedTask?.id !== task.id) selectTask(task);
        if (!(target as HTMLElement).closest('.task_status_wrapper'))
          setShownTaskInfoPlate(true);
      }, 500);
    },
    [setShownTaskInfoPlate, selectedTask, task, selectTask]
  );

  // const trDblClickHandler = useCallback(async () => {
  //   openExistingTask(task);
  //   setTaskAddMode(true);
  //   const sections = await fetchData.get(
  //     `/api/v1/projects/${task.project_id}/sections`
  //   );
  //   if (sections) setProjectSections(sections);
  // }, [openExistingTask, setProjectSections, setTaskAddMode, task]);

  const executor = users.find((user) => user.id === task.executor_id);
  const author = users.find((user) => user.id === task.author_id);

  let project_of_task;
  Object.keys(projects).forEach((id) => {
    if (projects[id].id === task.project_id) {
      project_of_task = projects[id].name;
    }
  });

  let section_of_task;
  Object.keys(partitions).forEach((id) => {
    if (partitions[id].id === task.project_section_id) {
      section_of_task = partitions[id].name;
    }
  });

  return (
    <TaskStyle
      active={task.id === selectedTask?.id}
      onClick={trClickHandler}
      // onDoubleClick={trDblClickHandler}
    >
      {/* <DragHandle /> */}
      <BlockLeftFlex>
        {activeColumns.includes('id') && <IdCell id={task.id} />}
        {activeColumns.includes('author_id') && (
          <ExecutorCell
            minWidth={columnsOfTables[1].style?.minWidth}
            url={
              author?.image
                ? urlApp + `/files/${author.image.url}/download`
                : null
            }
            executorName={
              author ? `${author.surname} ${author.name}` : 'Неизвестен'
            }
          />
        )}
        {activeColumns.includes('name') && (
          <TaskNameCell name={task.name} desc={task.description} />
        )}
      </BlockLeftFlex>
      <BlockRightFlex>
        {activeColumns.includes('status_id') && (
          <TaskStatus
            id={
              // если статус "в работе" и дедлайн уже прошел, то надо ставить статус "просрочена" - id 14
              task.status_id === 10 &&
              new Date(
                dateMaster.year,
                dateMaster.month,
                dateMaster.day,
                dateMaster.hours,
                dateMaster.minutes
              ).getTime() <= new Date(Date.now()).getTime()
                ? 14
                : task.status_id
            }
            taskId={task.id}
          />
        )}
        {activeColumns.includes('end') && (
          <DeadLineCell deadline={dateMaster.deadlineMini} />
        )}

        {activeColumns.includes('executor_id') && (
          <ExecutorCell
            minWidth={columnsOfTables[5].style?.minWidth}
            url={
              executor?.image
                ? urlApp + `/files/${executor.image.url}/download`
                : null
            }
            executorName={
              executor ? `${executor.surname} ${executor.name}` : 'Неизвестен'
            }
          />
        )}
        {activeColumns.includes('task_load') && (
          <TaskWorkLoadingCell
            taskLoad={task.task_load}
            workLoad={task.work_load}
          />
        )}

        {activeColumns.includes('project_name') && (
          <ProjectCell
            data_project={project_of_task}
            data_section={section_of_task}
          />
        )}
        {/* {activeColumns.includes('section_name') && (
        <PartitionCell data_section={section_of_task} />
      )} */}
        {activeColumns.includes('priority_id') && (
          <PriorityCell
            style={{ backgroundColor: 'transparent' }}
            priorityId={task.priority_id}
          />
        )}
      </BlockRightFlex>
    </TaskStyle>
  );
};

const mapStateToProps = (state: State) => {
  // console.log(state)
  return {
    projects: state.commonInfo.projects,
    partitions: state.commonInfo.sections,
    selectedTask: getSelectedTask(state),
    taskAddMode: state.tasksPage.taskAddMode,
    activeColumns: state.tasksPage.activeColumns,
    users: state.commonInfo.users,
  };
};

const mapDispatchToProps = {
  selectTask,
  setShownTaskInfoPlate,
  openExistingTask,
  setTaskAddMode,
  setProjectSections,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskTr);
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(SortableElement(TaskTr));
