import {State} from "../../rootReducer";
import {createSelector} from "reselect";
import {getUsers, getProjects} from '../../common/selectors'
import {Item, User, Project} from "../../common/types";

export const getNewTaskExecutor = (state: State): number | null => state.newTask.executor_id
export const getNewTaskProjectId = (state: State): number | null => state.newTask.project_id
export const getNewTaskInfo = (state: State) => state.newTask
export const getNewTaskSectionId = (state: State) => state.newTask.project_section_id
export const getProjectSections = (state: State) => state.newTask.projectSections

export const executorSelectData = createSelector(
    [getUsers, getNewTaskExecutor],
    (users, executor) => {
        let selected: Item | null = null
        let list = users.map((user: User) => {
            let item = {
                text: `${user.surname} ${user.name}`,
                value: user.id
            }
            if (item.value === executor) selected = item
            return item
        })
        return {list, selected}
    }
)

export const projectSelectData = createSelector(
    [getProjects, getNewTaskProjectId],
    (projects, projectId) => {
        let selected: Item | null = null
        let list = projects.map((project: Project) => {
            let item = {
                text: project.name,
                value: project.id
            }
            if (item.value === projectId) selected = item
            return item
        })
        return {list, selected}
    }
)

export const getCanSend = createSelector(
    [getNewTaskInfo],
    (taskInfo) => {
        let canSend = true
        let notImportant = ['isSendingTask', 'next_id', 'parent_id', 'prev_id', 'id', 'delegatedTo', 'author_id', 'delegated']
        for (let key in taskInfo) {
            // значение ключа существует? значение ключа не равно нулю ? ключ не входит в список необязательных параметров?
            if (!taskInfo[key] && (taskInfo[key] !== 0) && !notImportant.includes(key)) {
                // canSend = false
                break
            }
        }
        return canSend
    }
)


export const getSectionsSelectData = createSelector(
    [getNewTaskSectionId, getProjectSections],
    (id, sections) => {
        let selected = null as Item | null
        let list = sections.map(section => {
            let item = {
                text: section.name,
                value: section.id
            }
            if (section.id === id) selected = item
            return item
        })
        return {selected, list}
    }
)