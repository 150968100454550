import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Icon from '../../Icon';
import palette from '../../palette';
import CustomInput from './CustomInput';

type Props = {
  title?: string;
  iconName?: string;
  necessarily?: boolean;
  inputValue?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  style?: object;
  widthInput?: string;
  children?: ReactNode;
  inputChangeHandler?: (inputValue: string) => void;
};

const WrapperBlockInput = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  min-height: 2.5rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #707070;
  margin: 0.5rem 0;
`;

const TitleStyled = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  position: relative;
  min-width: 8.5rem;
  margin-right: 0.5rem;
`;
const SpanStyle = styled.div`
  margin-left: 0.5rem;
  color: ${palette.darkBlue};
  ${({ necessarily }) =>
    necessarily
      ? `&::after {
    content: '  *';
    color: ${palette.blue};
  }`
      : ''};
`;
const WrapperText = styled.div`
  //min-height: 2.5rem;
  width: ${({ width }) => width || '100%'};
`;

const NameWithInput: React.FC<Props> = ({
  title,
  iconName,
  inputChangeHandler,
  inputValue,
  placeholder,
  disabled,
  widthInput,
  type,
  children,
  style,
  necessarily,
}) => {
  return (
    <WrapperBlockInput>
      {title && (
        <TitleStyled>
          <Icon name={iconName} />
          <SpanStyle necessarily={necessarily}>{title}</SpanStyle>
        </TitleStyled>
      )}
      <WrapperText width={widthInput} style={style}>
        {type && (
          <CustomInput
            type={type}
            value={inputValue}
            disabled={disabled}
            placeholder={placeholder}
            inputChangeHandler={inputChangeHandler}
          />
        )}
        {children}
      </WrapperText>
    </WrapperBlockInput>
  );
};

export default NameWithInput;
