import {
    types,
    fetchTasksAction,
    changeTaskListAction,
    selectProjectAction,
    setProjectAddModeAction,
    setIsFetchingTasksAction,
    setTableFilterAction,
    setStatusFilterAction,
    setTableOrderAction,
    setFilterAddModeAction,
    setFilterDraftAction,
    setFilterAddNewRow,
    setAllOrActualAction,
    setShowColumnsCustomAction,
    setShowableColumns,
    tableOrTreeTogglerAction
} from './action_types'
import {Task} from '../../common/types'
import {Project} from '../../common/types'


export const fetchTasks = (projectId: number, pageNum: number): fetchTasksAction => ({
    type: types.FETCH_TASKS,
    projectId,
    pageNum
})

export const changeTaskList = (tasks: Task[]): changeTaskListAction => ({
    type: types.CHANGE_TASK_LIST,
    tasks
})

export const selectProject = (project: Project | null): selectProjectAction => ({
    type: types.SELECT_PROJECT,
    project
})

export const setProjectAddMode = (mode: boolean): setProjectAddModeAction => ({
    type: types.SET_PROJECT_ADD_MODE,
    mode
})

export const setIsFetchingTasks = (isFetchingTasks: boolean): setIsFetchingTasksAction => ({
    type: types.SET_IS_FETCHING_TASKS,
    isFetchingTasks
})

export const setTableFilter = (filter: string): setTableFilterAction => ({
    type: types.SET_TABLE_FILTER,
    filter
})

export const setOrder = (col_name: string, order_direct: boolean): setTableOrderAction => ({
    type: types.SET_TABLE_ORDER,
    col_name,
    order_direct
})

export const setFilterWindowShowProjects = (mode: boolean): setFilterAddModeAction => ({
    type: types.SET_FILTER_WIN_PROJECTS_MODE,
    mode
})

export const setFiltersProjects = (filterObject: Object): setStatusFilterAction => ({
    type: types.SET_FILTERS_PROJECTS,
    filterObject
})

export const setDraftFilterProjects = (draft: number): setFilterDraftAction => ({
    type: types.SET_DRAFT_VALUE_PROJECTS,
    draft
})

export const setAddNewRowProjects = (value: boolean): setFilterAddNewRow => ({
    type: types.SET_FILTERS_ADD_NEW_ROW_PROJECTS,
    value
})

export const ActualToggleActionProjects = (value: object): setAllOrActualAction => ({
    type: types.SET_ALL_OR_ACTUAL_PROJECTS,
    value
})

export const setShowColumnsProjectsCustom = (show: boolean): setShowColumnsCustomAction => ({
    type: types.SET_SHOW_COLUMNS_WINDOW_PROJECTS,
    show
})


export const setShowProjectsCols = (value: string): setShowableColumns => ({
    type: types.SET_SHOWING_COLS_PROJECTS,
    value
})

export const setTableOrTreeToggler = (value: string): tableOrTreeTogglerAction => ({
    type: types.SET_TABLE_OR_TREE_TOGGLER,
    value
})

