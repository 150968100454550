import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Slider, withStyles } from '@material-ui/core';


const PerformanceSlider = withStyles({
    track: {
        background: '#1BAAF0',
        height: 4
    },
    rail: {
        background: '#1BAAF0',
        height: 4
    },
    thumb: {
        background: '#1BAAF0',
        height: 24,
        width: 24,
        marginTop: -10
    }
})(Slider)


const Performance = ({percents, setPercents}) => {

    const [show, setShow] = useState(false)
    const [sliderValue, setSliderValue] = useState(percents)
    const performance = useRef<HTMLDivElement>(null)

    const outsideClick = useCallback((e: MouseEvent) => {
        if (!performance.current?.contains(e.target as HTMLDivElement)) setShow(false)
    }, [])

    const confirmClick= useCallback(() => {
        setPercents(sliderValue)
        setShow(false)
    }, [setPercents, sliderValue])

    useEffect(() => {
        setSliderValue(percents)
    }, [percents])

    useEffect(() => {
        document.addEventListener('click', outsideClick)
        return () => document.removeEventListener('click', outsideClick)
    }, [outsideClick])

    return (
        <div ref={performance} className={`killer_queen ${percents === 0 ? 'no_value' : percents === 100 ? 'complete' : 'with_value'}`}>
            <div 
                onClick={(e) => {
                    e.stopPropagation()
                    setShow(true)
                }}
                className="value"
            >   
                {percents === 0 ? 'Введите' : percents === 100 ? 'Выполнена' : `${percents}%`}
            </div>
            {show && (
                <div className="slider_plate">
                    <div className="slider_value">{`${sliderValue}%`}</div>
                    <PerformanceSlider
                        value={sliderValue}
                        step={10}
                        onChange={(e, value) => setSliderValue(value)}
                    />
                    <div className="actions">
                        <div onClick={() => setShow(false)} className="btn close">Отмена</div>
                        <div onClick={confirmClick} className="btn secondary">Применить</div>
                    </div>
                </div>
            )}
        </div>
    )
}


export default Performance