import React from 'react';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { rootReducer } from './rootReducer';
import { rootSaga } from './rootSaga';
import LoginPlate from './pages/Login/index';
import Notifier from './common/components/Notifier/Notifier';
import Main from './common/components/Main';
import './sass/main.sass';

const composeEnhancers =
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

const sagaMiddleware = createSagaMiddleware();

export const urlApp: string = 'https://npp-sne.easy-task.ru'; // глобальная переменная урла, чтобы использовать везе и менять в одном месте

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider>
        <BrowserRouter>
          <Notifier />
          <Switch>
            {/* <Route path="/email/verify" component={EmailVerify} /> */}
            <Route
              path={[
                '/login',
                '/forgot-password',
                '/password/recovery',
                '/email',
              ]}
              component={LoginPlate}
            />
            <Route exact path="/" render={() => <Redirect to="/tasks" />} />
            <Route path="/" component={Main} />
          </Switch>
        </BrowserRouter>
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
