import { createSelector } from "reselect";
import { State } from "../../rootReducer";
import { getProjects } from "../../common/selectors";
import { Item } from "../../common/types";


export const getSelectedProject = (state: State) => state.projectsPage.selectedProject

export const getProjectSelectData = createSelector(
    [getProjects, getSelectedProject],
    (projects, selectedProject) => {
        let selected = null as Item | null
        let list = projects.map(project => {
            let item = {
                text: project.name,
                value: project.id,
                subtitle: project.description
            }
            if (project.id === selectedProject?.id) selected = item
            return item
        })
        return {selected, list}
    }
)
