import React, {useEffect, useState} from 'react';
import withTitle from '../../../utils/hoc/withTitle';
import {connect} from 'react-redux';
import {State} from '../../../rootReducer';
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Input from "../../../common/components/Inputs/Input";
import SelectWithSecondaryBtn from "../../../common/components/Selects/SelectWithSecondaryBtn";
import ScheduleDay from "../../NewUser/components/ScheduleDay";
import Button from "../../../common/components/newComponent/Buttons/Button";
import {Item} from "../../../common/types";
import {setFlagRepeatTaskToChange, setStartCyclick} from "../actions";


type Props = {
    repeat: any,
    setStartCyclick: (period?: string, params?: Array<number>, interval?: number, show_modal_window?: boolean, stringForMainPart?: string) => void,
    setFlagRepeatTaskToChange: (val: string) => void
}


const ConfirmEditRepeatTask: React.FC<Props> = ({setFlagRepeatTaskToChange}) => {

    // const [stringForMainPart, setStringForMainPart] = useState<string>('Каждый день')

    // useEffect(() => {
    // console.log(stringForMainPart, checkedDays)
    // }, [stringForMainPart]);

    return (
        <div className="add_pannel_wrapper">
            <div className={`confirm_edit_repeat_task_window`}>
                <div className={'firstRow'}>
                    <div>Изменить повторяющуюся задачу ?</div>
                </div>
                <div className={'secondRow'}>
                    Вы изменяете повторяющуюся задачу. Изменить только эту задачу, или все ее повторения в будущем ?
                </div>

                <div className={'bottomRow'}>
                    <Button title={'Все будущие'} design={'secondary'} onClick={() => {
                        setFlagRepeatTaskToChange('taskAndCyclic')
                    }}/>
                    <Button title={'Только эту задачу'} onClick={() => {
                        setFlagRepeatTaskToChange('task')
                    }}/>
                </div>


            </div>
        </div>
    )
}

const mapStateToProps = (state: State) => {
    return {
        repeat: state.taskInfoPlate.repeat
    }
}

const mapDispatchToProps = {
    setStartCyclick,
    setFlagRepeatTaskToChange
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEditRepeatTask)