import React, { useCallback } from 'react';

import styled from 'styled-components';
import { urlApp } from '../../../../App';

import Icon from '../../Icon';
import palette from '../../palette';
import Checkbox from '../Checkbox';

type Props = {
  value: number;
  urlImage?: any;
  name: string;
  position?: string;
  radio?: boolean;
  checked?: boolean;
  addHandler: (key: number) => void;
  removeHandler: (key: number) => void;
};
const WrapperBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 320px;
  width: 100%;
  height: 48px;
  padding: 0.5rem 0.75rem;

  &:hover {
    cursor: pointer;
    background-color: ${palette.blueLight};
  }
`;

const LeftBlock = styled.div`
  display: flex;
  align-items: center;
`;

const WrapperAvatar = styled.div`
  position: relative;
  height: 2rem;
  width: 2rem;
  border: 1px solid transparent;
  background-color: ${palette.blueLight};
  border-radius: 50%;
  border-color: ${({ checked }) => checked && palette.blue};
`;
const CustomAvatarStyle = styled.div`
  color: ${palette.blue};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 50%;
  height: 100%;
  width: 100%;
`;

const InformationBlock = styled.div`
  font-family: Roboto;
  font-style: normal;
  margin: 0 0.5rem;
`;

const NameStyle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${palette.textBlack};
`;

const PositionStyle = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${palette.gray};
`;

const PeopleItem: React.FC<Props> = ({
  urlImage,
  name,
  position,
  value,
  checked,
  radio,
  addHandler,
  removeHandler,
}) => {
  const onChangeCheckbox = useCallback(
    (e) => {
      e.preventDefault();
      if (!checked) {
        addHandler(value);
      } else {
        removeHandler(value);
      }
    },
    [addHandler, checked, removeHandler, value]
  );
  let minName: string[] = [];
  name.split(' ').map((el) => {
    minName.push(typeof el[0] === 'string' ? el[0].toUpperCase() : el[0]);
  });
  return (
    <WrapperBlock onClick={onChangeCheckbox}>
      <LeftBlock>
        <WrapperAvatar checked={checked}>
          {urlImage ? (
            <Icon
              src={urlApp + `/files/${urlImage}/download`}
              width="100%"
              height="100%"
              style={{ border: '1px solid white' }}
            />
          ) : (
            <CustomAvatarStyle>{minName.join('')}</CustomAvatarStyle>
          )}
        </WrapperAvatar>
        <InformationBlock>
          <NameStyle>{name}</NameStyle>
          <PositionStyle>{position}</PositionStyle>
        </InformationBlock>
      </LeftBlock>
      {!radio && <Checkbox checked={checked} onChange={onChangeCheckbox} />}
    </WrapperBlock>
  );
};

export default PeopleItem;
