import React, { useState, useCallback } from 'react';
import Input from '../../../common/components/Inputs/Input';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { validatePassword } from '../../../utils/validPatterns';
import { fetchData } from '../../../utils/fetchData';
import { CreateNotif } from '../../../utils/createNotification';





const PasswordRecovery = () => {

    const [password, setPassword] = useState('')
    const [password_confirmation, setPasswordConfirm] = useState('')
    const [in_load, setInload] = useState(false)
    const location = useLocation()
    const history = useHistory()

    const formSubmitHandler = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setInload(true)
        let token = decodeURIComponent(location.pathname.split('/password/recovery/')[1]),
            email = location.search.split('?email=')[1]


        let response = await fetchData.post('/api/v1/password/change', JSON.stringify({
            email,
            token,
            password,
            password_confirmation,
        }))
        if (response?.success) {
            CreateNotif('Пароль успешно восстановлен', 'success')
            history.push('/login')
        }
        setInload(false)
    }, [history, location.pathname, location.search, password, password_confirmation])



    return (
        <form onSubmit={formSubmitHandler} className="login_form">
            <div className="sub_title">Восстановите пароль</div>
            <Input
                inputType="password"
                placeholder="Новый пароль"
                withIcon={true}
                value={password}
                title=''
                changeHandler={value => setPassword(value)}
                isValid={(password ? validatePassword(password) : true)}
                validText="Пароль должен состоять из 6-30 символов и включать в себя хотя бы одно число и букву в верхнем регистре"
            />
            <Input
                inputType="password"
                placeholder="Повторите пароль"
                withIcon={true}
                value={password_confirmation}
                title=''
                changeHandler={value => setPasswordConfirm(value)}
                isValid={(password ? validatePassword(password) : true)}
                validText="Пароль должен состоять из 6-30 символов и включать в себя хотя бы одно число и букву в верхнем регистре"
            />
            <button className={`btn primary${in_load ? ' in_load' : ''}`}>Готово</button>
            <Link to="/login">Вход</Link>
        </form>
    )
}


export default PasswordRecovery