import React, {useState, useEffect, useCallback, useMemo} from 'react';
import SelectWithSecondaryBtn from '../../../common/components/Selects/SelectWithSecondaryBtn';
import {connect} from 'react-redux';
import {User, Item, Task, Section} from '../../../common/types';
import {Collapse} from '@material-ui/core'
import {State} from '../../../rootReducer';
import {updateTask} from '../../../common/actions';
import {recalculateTaskLoad, setDelegeteTo, setShownTaskInfoPlate, setTaskWorkLoadToggler} from '../actions';
import {setTaskAddMode} from '../../Tasks/actions';
import Input from "../../../common/components/Inputs/Input";
import Toggler from "../../../common/components/Togglers/Toggler";
import DateMaster from "../../../utils/DateMaster";
import {types} from "../action_types";


type Props = {
    users: User[],
    executor_id: number | undefined,
    selectedTask: Task | null,
    delegeteTo: number | null,
    selectedTaskProjectSections: Section[]
    updateTask: (taskId: number, params: any) => void,
    setDelegeteTo: (delegeteTo: number) => void,
    setTaskAddMode: (mode: boolean) => void,
    setShownTaskInfoPlate: (show: boolean) => void,
    recalculateTaskLoad: (val?: object) => void,
    taskWorkLoadSwitcher: string,
    setTaskWorkLoadToggler: () => void,
    disabled: boolean,
    task_load: number,
    work_load: number
}


const ExecutorSelect: React.FC<Props> = (
    {
        users,
        executor_id,
        selectedTask,
        delegeteTo,
        selectedTaskProjectSections,
        updateTask,
        setDelegeteTo,
        setTaskAddMode,
        setShownTaskInfoPlate,
        taskWorkLoadSwitcher,
        setTaskWorkLoadToggler,
        recalculateTaskLoad,
        disabled = false,
        task_load,
        work_load
    }) => {

    const [InputValue, setInputValue] = useState<any>(selectedTask ? (taskWorkLoadSwitcher === 'task_load' ? selectedTask.task_load : selectedTask.work_load) : (taskWorkLoadSwitcher === 'task_load' ? task_load : work_load))

    useEffect(() => {   // нужно, чтобы в редюсере было значение первого пользователя в чипсине, пока нет selectedTask
        setInputValue(selectedTask ? (taskWorkLoadSwitcher === 'task_load' ? selectedTask.task_load : selectedTask.work_load) : (taskWorkLoadSwitcher === 'task_load' ? task_load : work_load))
    }, [selectedTask])

    // для обнуления хука
    useMemo(() => {
        setInputValue(selectedTask ? (taskWorkLoadSwitcher === 'task_load' ? selectedTask.task_load : selectedTask.work_load) : (taskWorkLoadSwitcher === 'task_load' ? task_load : work_load))
    }, [taskWorkLoadSwitcher])

    return (
        <div className="taskWorkLoad">
            <div className={'innerComponents'}>

                <input type={'text'} className={`input_div ${disabled ? 'disabled' : ''}`}
                       value={(InputValue).toString()}
                       onChange={val => (setInputValue(val.target.value))}
                       onBlur={(e) => {
                           if (selectedTask) {
                               let comparisonValue = (taskWorkLoadSwitcher === 'task_load' ? selectedTask.task_load : selectedTask.work_load)
                               if (comparisonValue !== parseInt(e.target.value)) {
                                   let obj = []
                                   obj[taskWorkLoadSwitcher] = parseInt(e.target.value)
                                   recalculateTaskLoad(obj)
                               }
                           } else {
                               let comparisonValue = (taskWorkLoadSwitcher === 'task_load' ? task_load : work_load)
                               if (comparisonValue !== parseInt(e.target.value)) {
                                   let obj = []
                                   obj[taskWorkLoadSwitcher] = parseInt(e.target.value)
                                   recalculateTaskLoad(obj)
                               }
                           }
                       }}
                />

                <Toggler elemsList={[{name: 'Часы', value: 'task_load'}, {name: 'Проценты', value: 'work_load'}]}
                         selectHandler={(active) => {
                             if (active !== taskWorkLoadSwitcher) setTaskWorkLoadToggler()
                         }}
                         activeValue={taskWorkLoadSwitcher}
                />

            </div>
        </div>

        // </div>
    )
}

const mapStateToProps = (state: State) => {
    // console.log(state)
    return {
        work_load: state.taskInfoPlate.work_load,
        task_load: state.taskInfoPlate.task_load,

        taskWorkLoadSwitcher: state.taskInfoPlate.taskWorkLoadSwitcher,
        executor_id: state.taskInfoPlate.selectedTask?.executor_id,
        users: state.taskInfoPlate.projectUsers,
        selectedTask: state.taskInfoPlate.selectedTask,
        delegeteTo: state.taskInfoPlate.delegateTo,
        selectedTaskProjectSections: state.taskInfoPlate.projectSections
    }
}

const mapDispatchToProps = {
    updateTask,
    setDelegeteTo,
    setTaskAddMode,
    setShownTaskInfoPlate,
    setTaskWorkLoadToggler,
    recalculateTaskLoad
}


// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ExecutorSelect)