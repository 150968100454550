import {all} from 'redux-saga/effects';
import watchFetchToken from './pages/Login/saga';
import {
    watchFetchTasks,
    watchSetStatusFilterTasks,
    watchFiltersActualToggle,
    watchSetTableOrderTasks,
    watchLoadSpecificTask,
    watchFetchTasksVisualization,
} from './pages/Tasks/saga';
import {
    watchFetchProjectTasks,
    watchSetStatusFilterProjects,
    watchSetTableOrderProjects,
    watchFiltersActualToggleProjects,
} from './pages/Projects/saga';
import {
    watchFetchCommonInfo,
    watchUpdateTask,
    watchFetchExistingUser,
    watchNotificationsRead,
    watchLoadNextPage,
} from './common/saga';
import {
    watchCreateProject,
    watchUploadFile,
    watchDeleteFile,
    watchRemoveProjectSection,
    watchChangeSectionName,
    watchUpdateProject,
    watchAddProjectSection,
} from './pages/NewProject/saga';
import {
    watchCreateUser,
    watchPasswordChange,
    watchPasswordReset,
} from './pages/NewUser/saga';
import {
    watchOpenDeps,
    watchCreateDep,
    watchFetchUsers,
    watchGetDepGroupUsers,
    watchAddUserToDepartment,
    watchAddUserToGroup,
    watchRemoveUserFromDepartment,
    watchRemoveUserFromGroup,
    watchCreateGroup,
    watchSetTableOrderUsers,
    watchUpdateDepGroupInfo,
    watch_delete_dep_group,
    watchGetNextPageAmongAll
} from './pages/Users/saga'
import {watchFetchSections, watchDelegateTask, watchSetRecalculateLoad} from './pages/NewTask/saga'
import {
    watchDeleteComment,
    watchUploadCommentFile,
    watchDeleteCommentFile,
    watchSendComment,
    watchFetchProjectSections,
    watchGetComments,
    watchRecalculateLoad,
    watchDispatchCheckboxItem,
    watchDeligateConfirm,
    watchCreateTask, watchChangeTemplRepeatTask
} from './pages/TaskInfoPlate/saga'

export function* rootSaga() {
    yield all([
        watchCreateTask(),
        watchFetchToken(),
        watchFetchTasks(),
        watchUpdateDepGroupInfo(),
        watchSetStatusFilterTasks(),
        watchLoadSpecificTask(),
        watchFetchProjectTasks(),
        watchSetStatusFilterProjects(),
        watchSetTableOrderProjects(),
        watchFiltersActualToggleProjects(),
        watchFetchCommonInfo(),
        watchCreateProject(),
        watchUploadFile(),
        watchDeleteFile(),
        watchCreateUser(),
        // watchOpenGroups(),
        watchOpenDeps(),
        watchCreateDep(),
        watchSetRecalculateLoad(),
        watchFetchUsers(),
        watchUpdateTask(),
        watchDeleteComment(),
        watchUploadCommentFile(),
        watchDeleteCommentFile(),
        watchSendComment(),
        watchGetDepGroupUsers(),
        watchFetchExistingUser(),
        watchAddUserToDepartment(),
        watchAddUserToGroup(),
        watchRemoveUserFromDepartment(),
        watchRemoveUserFromGroup(),
        watchCreateGroup(),
        watchRemoveProjectSection(),
        watchChangeSectionName(),
        watchUpdateProject(),
        watchAddProjectSection(),
        watchFetchSections(),
        watchFetchProjectSections(),
        watchDelegateTask(),
        watchPasswordChange(),
        watchFiltersActualToggle(),
        watchPasswordReset(),
        watchSetTableOrderTasks(),
        watchSetTableOrderUsers(),
        watchGetComments(),
        watchNotificationsRead(),
        watchLoadNextPage(),
        watchRecalculateLoad(),
        watchDispatchCheckboxItem(),
        watch_delete_dep_group(),
        watchGetNextPageAmongAll(),
        watchDeligateConfirm(),
        watchFetchTasksVisualization(),
        watchChangeTemplRepeatTask()
    ])
}