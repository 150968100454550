import React, { useCallback, useEffect } from 'react';
import TaskDateTime from '../../../common/components/DatePickers/DateTimePiker';
import withTitle from '../../../utils/hoc/withTitle';
import { connect } from 'react-redux';
import {
  recalculateTaskLoad,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setTaskEnd,
} from '../actions';
import { State } from '../../../rootReducer';
import { updateTask } from '../../../common/actions';

const TaskEnd = ({
  end,
  minDate,
  setTaskEnd,
  delegated,
  recalculateTaskLoad,
  selectedTask,
  updateTask,
  flagForActionCyclycTask,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
}) => {
  const acceptHandler = useCallback(
    function (e): any {
      setTaskEnd(e);
      recalculateTaskLoad();
      if (selectedTask) {
        if (selectedTask.cyclic_task_id !== 0) {
          // эта задача вообще циклическая или сама по себе ?
          // значит циклическая
          if (flagForActionCyclycTask === '') {
            setCyclicTaskToChange({ end: e }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
          } else if (flagForActionCyclycTask === 'task') {
            updateTask(selectedTask!.id, { end: e });
          } else {
            updateTask(selectedTask!.id, { end: e });
            sendReqToChangeTemplRepTask({ end: e });
          }
        } else {
          updateTask(selectedTask!.id, { end: e });
        }

        // updateTask(selectedTask!.id, { end: e });
      }
    },
    [selectedTask]
  );

  let [date, time] = selectedTask
    ? selectedTask.end.split(' ')
    : minDate.split(' ');
  let minDateObj = new Date(`${date.split('-').reverse().join('-')} ${time}`);

  // in particular cases, 'end' date comes here in format dd/mm/yyyy hh:mm:ss
  // expected format is dd-mm-yyyy
  end = end.replace(/\//g, '-');

  return (
    <TaskDateTime
      disabled={delegated}
      acceptHandler={acceptHandler}
      date={selectedTask ? selectedTask.end : end}
      minDate={minDateObj}
    />
  );
};

const mapStateToProps = (state: State) => {
  return {
    selectedTask: state.taskInfoPlate.selectedTask,
    end: state.taskInfoPlate.end,
    minDate: state.taskInfoPlate.begin,
    delegated: state.taskInfoPlate.delegated,
    flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
  };
};

const mapDispatchToProps = {
  updateTask,
  setTaskEnd,
  recalculateTaskLoad,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskEnd);
