import React from 'react';
import { TableRow } from '@material-ui/core';
import { Cell } from './styledComponents';

type Props = {
    title: string
}

const TitleTr: React.FC<Props> = ({ title }) => {
    return (
        <TableRow>
            <Cell
                style={{ paddingLeft: 24 }}
                colSpan={6}
            >
                {title}
            </Cell>
        </TableRow>
    )
}


export default TitleTr