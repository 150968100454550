import {
    types,
    setUserNameAction,
    setUserEmailAction,
    clearUserAction,
    setUserPatronymicAction,
    setUserSurnameAction,
    setUserRoleAction,
    setUserPositionAction,
    setUserDepartmentAction,
    setUserBirthDayAction,
    setUserScheduleAction,
    setUserWorkBeginAction,
    setUserWorkEndAction,
    setUserDaysOffAction,
    setUserPasswordAction,
    createUserAciton,
    setIsSendingAction,
    openUserAction,
    setUserImageAction,
    setPassChangeModeAction,
    setPassReset,
    changePass
} from './action_types'
import {DayOff, User} from '../../common/types'


export const setUserName = (name: string): setUserNameAction => ({
    type: types.SET_USER_NAME,
    name
})

export const setUserPassword = (password: string): setUserPasswordAction => ({
    type: types.SET_USER_PASSWORD,
    password
})

export const setUserEmail = (email: string): setUserEmailAction => ({
    type: types.SET_USER_EMAIL,
    email
})

export const clearUser = (): clearUserAction => ({
    type: types.CLEAR_USER
})

export const setUserPatronymic = (patronymic: string): setUserPatronymicAction => ({
    type: types.SET_USER_PATRONYMIC,
    patronymic
})

export const setUserSurname = (surname: string): setUserSurnameAction => ({
    type: types.SET_USER_SURNAME,
    surname
})

export const setUserRole = (roles: number[]): setUserRoleAction => ({
    type: types.SET_USER_ROLE,
    roles
})

export const setUserPosition = (positions: number[]): setUserPositionAction => ({
    type: types.SET_USER_POSITION,
    positions
})

export const setUserDepartment = (departments: number[]): setUserDepartmentAction => ({
    type: types.SET_USER_DEPARTMENT,
    departments
})

export const setUserBirthDay = (birthday: string): setUserBirthDayAction => ({
    type: types.SET_USER_BIRTHDAY,
    birthday
})

export const setUserSchedule = (schedule: number[]): setUserScheduleAction => ({
    type: types.SET_USER_SCHEDULE,
    schedule
})

export const setUserWorkBegin = (time: string): setUserWorkBeginAction => ({
    type: types.SET_USER_WORK_BEGIN,
    time
})

export const setUserWorkEnd = (time: string): setUserWorkEndAction => ({
    type: types.SET_USER_WORK_END,
    time
})

export const setUserDaysOff = (daysOff: DayOff[]): setUserDaysOffAction => ({
    type: types.SET_USER_DAYS_OFF,
    daysOff
})

export const createUser = (update: boolean): createUserAciton => ({
    type: types.CREATE_USER,
    update
})

export const setIsSending = (isSending: boolean): setIsSendingAction => ({
    type: types.SET_IS_SENDING,
    isSending
})

export const openExistingUser = (user: User): openUserAction => ({
    type: types.OPEN_USER,
    user
})

export const setUserImage = (image: File | null): setUserImageAction => ({
    type: types.SET_USER_IMAGE,
    image
})

export const setChangePasswordMode = (mode: boolean): setPassChangeModeAction => ({
    type: types.SET_PASS_CHANGE_MODE,
    mode
})

export const PasswordReset = (mode: boolean): setPassReset => ({
    type: types.PASS_RESET,
    mode
})

export const ChangePassword = (oldPassword: string, password: string ): changePass => ({
    type: types.CHANGE_PASS,
    oldPassword,
    password
})