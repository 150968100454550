import { State } from "../../rootReducer";
import { createSelector } from "reselect";
import { getDeps } from "../../common/selectors";
import { Department, Workgroup } from "../../common/types";

export const getShowingDepGroupPlate = (state: State) => state.usersPage.showAddUserToGroupDep
export const getDepGroupPlateType = (state: State) => state.usersPage.depGroupPlateType
export const getFilter = (state: State) => state.usersPage.dep_filter
export const getGroups = (state: State): Workgroup[] => state.commonInfo.workgroups
export const getSelectedDepGroupId = (state: State) => state.usersPage.selectedDepGroupId

export const getDepGroups = (state: State, props) => {
    return state.commonInfo.workgroups
}

export const getSelectedDepGroups = createSelector(
    [getSelectedDepGroupId, getGroups],
    (depId, groups) => {
        return groups.filter(group => group.department_id === depId)
    }
)

export const getDepList = createSelector(
    [getFilter, getDeps],
    (filter, deps) => {
        return deps.filter((dep: Department) => dep.name.toLocaleLowerCase().match(filter.toLocaleLowerCase()))
    }
)

export const getGroupList = createSelector(
    [getFilter, getGroups],
    (filter, groups) => {
        return groups.filter((group: Workgroup) => group.name.toLocaleLowerCase().match(filter.toLocaleLowerCase()))
    }
)

// export const getSelectedDep = createSelector(
//     [getSelectedDepGroupId, getDeps],
//     (id, deps) => {
//         return deps.find(dep => dep.id === id)
//     }
// )

export const getGroupCounter = createSelector(
    [getSelectedDepGroupId, getGroups],
    (depId, groups) => {
        return groups.filter(group => group.department_id === depId).length
    }
)


