import { Action } from "redux";

export enum types {
    FETCH_TOKEN = 'FETCH_TOKEN'
}



export interface fetchTokenAction extends Action<types.FETCH_TOKEN> {
    email: string,
    password: string
}