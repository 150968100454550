import React from 'react';
import {setTaskProject, fetchProjectSections} from '../actions';
// import withTitle from '../../../utils/hoc/withTitle';
import {connect} from 'react-redux';
import SelectWithSecondaryBtn from '../../../common/components/Selects/SelectWithSecondaryBtn';
import {SelectData} from '../../../common/types';
import {State} from '../../../rootReducer';
import {projectSelectData} from '../selectors';

type Props = {
    selectData: SelectData;
    delegated: boolean;
    setTaskProject: (id: number) => void;
    fetchProjectSections: (id: number) => void;
};

const ProjectSelect: React.FC<Props> = ({
                                            selectData,
                                            delegated,
                                            setTaskProject,
                                            fetchProjectSections,
                                        }) => {
    return (
        <SelectWithSecondaryBtn
            disabled={delegated}
            list={selectData.list.sort((a, b) => (a.text > b.text ? 1 : -1))}
            selected={selectData.selected}
            selectHandler={(value, target) => {
                setTaskProject(value as number);
                fetchProjectSections(value as number);

                let closest = target.closest('.input_div');
                if (closest) {
                    if (value) closest.classList.add('text_exist');
                    else closest.classList.remove('text_exist');
                }
            }}
        />
    );
};

const mapStateToProps = (state: State) => {
    return {
        selectData: projectSelectData(state),
        delegated: state.newTask.delegated,
    };
};

const mapDispatchToProps = {
    setTaskProject,
    fetchProjectSections,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSelect);
