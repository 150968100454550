import React from 'react';
import {State} from "../../../rootReducer";
import {ActualToggleAction} from "../../../pages/Tasks/actions";
import {connect} from "react-redux";

type Props = {
    name: string,
    isOpen: boolean,
    subtitle: string,
    clickHandler: (val: boolean) => void,
    exec_auth: string,
    ActualToggleAction: (val: object) => void,
    context: string
}


const NameWithSubtitle: React.FC<Props> = ({context, exec_auth, clickHandler, name, subtitle, isOpen, ActualToggleAction}) => {
    return (
        <div className="name_with_subtitle">
            <div className={'name_arrow'} onClick={() => clickHandler(true)}>
                <div className="main_name">{name}</div>
                <div className={`arrow_wrapper${isOpen ? ' highlighted' : ''}`}>
                    <div className={`blue_arrow${isOpen ? ' rotated' : ''}`}/>
                </div>
            </div>
            {context === 'tasks' &&
            <div className={'description'}>
                <div onClick={() => ActualToggleAction({erase_applied_filters: false, exec_auth_value: 'executor'})}
                     className={`sub_title ${exec_auth === 'executor' ? 'active' : ''}`}>Исполнитель
                </div>
                <div onClick={() => ActualToggleAction({erase_applied_filters: false, exec_auth_value: 'author'})}
                     className={`sub_title ${exec_auth === 'author' ? 'active' : ''}`}>Автор
                </div>
            </div>
            }
        </div>
    )
}


const mapStateToProps = (state: State) => ({
    exec_auth: state.tasksPage.filters.exec_auth
})

const mapDispatchToProps = {
    ActualToggleAction
}

export default connect(mapStateToProps, mapDispatchToProps)(NameWithSubtitle)