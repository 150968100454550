import React from 'react';
import withTitle from '../../../utils/hoc/withTitle';
import { connect } from 'react-redux';
import SelectWithSecondaryBtn from '../../../common/components/Selects/SelectWithSecondaryBtn';
import { setUserDepartment } from '../actions';
import { State } from '../../../rootReducer';
import { getDepSelectData } from '../selectors';
import { SelectData, User } from '../../../common/types';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';

type Props = {
  depSelectData: SelectData;
  userId: number;
  currentUserInfo: User | null;
  setUserDepartment: (position: number[]) => void;
};

//отдел можно менять для новых пользователей и если ты админ или директор

const DepartmentSelect: React.FC<Props> = ({
  depSelectData,
  userId,
  currentUserInfo,
  setUserDepartment,
}) => {
  return (
    <NameWithInput title="Отдел" iconName="bag">
      <SelectWithSecondaryBtn
        disabled={
          userId === 0 ||
          currentUserInfo?.roles.includes(1) ||
          currentUserInfo?.roles.includes(2)
            ? false
            : true
        }
        list={depSelectData.list}
        selected={depSelectData.selected}
        selectHandler={(value) => setUserDepartment([value as number])}
      />
    </NameWithInput>
  );
};

const mapStateToProps = (state: State) => {
  return {
    depSelectData: getDepSelectData(state),
    userId: state.newUser.id,
    currentUserInfo: state.commonInfo.currentUserInfo,
  };
};

const mapDispatchToProps = {
  setUserDepartment: setUserDepartment,
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentSelect);
