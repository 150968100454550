import React from 'react';
import { connect } from 'react-redux';
import { State } from '../../../rootReducer';
import { Role } from '../../types';
import styled from 'styled-components';
import { columnsOfTables } from '../../shared_data';

type Props = {
  name: string;
  desc: string | undefined; // undefined потому что по задаче может быть еще не назначено исполнителя. Из ProjectTaskTr, TaskTr может быть undefined
  roles: Role[];
  className?: string;
};
const WrapperNameCell = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  line-height: 16px;
  /* max-width: ${columnsOfTables[1].style.minWidth}; */
  min-width: ${columnsOfTables[2].style.minWidth};
`;
const TitleStyle = styled.div`
  padding-top: 12px;
  font-weight: 500;
  font-size: 14px;
  color: #212121;
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  padding-right: 4px;
  text-overflow: ellipsis; /* Добавляем многоточие */
`;

const DescriptionStyle = styled.div`
  font-weight: normal;
  font-size: 13px;
  color: #a8a8a8;
  white-space: nowrap; /* Запрещаем перенос строк */
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  padding-right: 4px;
  text-overflow: ellipsis; /* Добавляем многоточие */
`;

const NameCell: React.FC<Props> = ({ name, desc, className }) => {
  return (
    <WrapperNameCell>
      <TitleStyle>{name}</TitleStyle>
      <DescriptionStyle>{desc}</DescriptionStyle>
    </WrapperNameCell>
  );
};

const mapStateToProps = (state: State) => {
  return {
    roles: state.commonInfo.roles,
  };
};

export default connect(mapStateToProps)(NameCell);
