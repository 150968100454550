import React, { useState, useCallback } from 'react';
import { Row, BodyCell } from './styledComponents';
import Priority from '../../../common/components/Priority/Priority';
import Performance from './Performance'
import Comment from './Сomment';

const TaskTr = ({ taskInfo }) => {

    const [hours, setHours] = useState(taskInfo.hours)
    const [percents, setPercents] = useState(taskInfo.percents)
    const [comment, setComment] = useState(taskInfo.comment)

    const onlyNumberChangeHandler = useCallback(e => {
        let string = e.currentTarget.value.replace(/[^\d]/g, '')
        setHours(string)
    }, [])

    return (
        <Row>
            <BodyCell style={{ width: 400, paddingLeft: 24 }}>{taskInfo.name}</BodyCell>
            <BodyCell align="right">{taskInfo.load}</BodyCell>
            <BodyCell align="right">
                <Priority id={taskInfo.priority_id} />
            </BodyCell>
            <BodyCell style={{ paddingTop: 11, paddingBottom: 11 }} align="right">
                <input
                    onChange={onlyNumberChangeHandler}
                    className={`killer_queen${hours ? ' with_value' : ''}`}
                    value={hours || ''} placeholder="Введите"
                />
            </BodyCell>
            <BodyCell style={{ paddingTop: 11, paddingBottom: 11 }} align="center">
                <Performance percents={percents} setPercents={setPercents} />
            </BodyCell>
            <BodyCell style={{ width: 295 }}>
                <Comment comment={comment} setComment={setComment}/>
            </BodyCell>
        </Row>
    )
}


export default TaskTr