import React, { useCallback, useMemo, useState } from 'react';
import AddButton from '../../../common/components/Buttons/AddButton';
// import DisplayTypeSelect from '../../../common/components/Selects/DisplayTypeSelect'
import UserProjectSelect from '../../../common/components/Selects/UserProjectSelect';
import { User, Item, Task } from '../../../common/types';
import { connect } from 'react-redux';
import {
  setTaskAddMode,
  setShowVisulaization,
  changeTaskList,
  fetchTasks,
  setTableFilter,
  setShowColumnsCustom,
} from '../actions';
import { State } from '../../../rootReducer';
import FilterButton from '../../../common/components/Buttons/filterButton';
import TasksFilters from './TasksFilters';
import TableSearch from '../../../common/components/TableSearch/TableSearch';
import ColumnsWindow from '../../../common/ColumnsCustomWindow';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import ViewWeekRoundedIcon from '@material-ui/icons/ViewWeekRounded';
import { setShownTaskInfoPlate } from '../../TaskInfoPlate/actions';
import { store } from '../../../App';
import Button from '../../../common/components/newComponent/Buttons/Button';

type Props = {
  users: User[];
  selectedUserId: number;
  showVisual: boolean;
  setTaskAddMode: (mode: boolean) => void;
  setShowVisulaization: (show: boolean) => void;
  setShowColumnsCustom: (show: boolean) => void;
  appliedFiltersExist: boolean;
  showFiltersWindow: boolean;
  showColumnsWindow: boolean;
  tableFilter: string;
  setTableFilter: (filter: string) => void;
  changeTaskList: (tasks: Task[]) => void;
  fetchTasks: (pageNum: number) => void;
  setShownTaskInfoPlate: (val: boolean) => void;
};

const TasksActions: React.FC<Props> = ({
  tableFilter,
  setShownTaskInfoPlate,
  setTableFilter,
  fetchTasks,
  changeTaskList,
  users,
  selectedUserId,
  showVisual,
  setTaskAddMode,
  setShowVisulaization,
  setShowColumnsCustom,
  showColumnsWindow,
  appliedFiltersExist,
  showFiltersWindow,
}) => {
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(
    null
  );

  const dataForUserSelect = useMemo(() => {
    let selected = null as Item | null;
    let list = users.map((user) => {
      let item = {
        text: `${user.surname} ${user.name}`,
        value: user.id,
        subtitle: user.email,
      };
      if (user.id === selectedUserId) selected = item;
      return item;
    });
    return { selected, list };
  }, [selectedUserId, users]);

  const searchChangeHandler = useCallback(
    (filter: string) => {
      setTableFilter(filter);
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          changeTaskList([]);
          fetchTasks(1);
        }, 500)
      );
    },
    [changeTaskList, fetchTasks, setTableFilter, timer]
  );

  return (
    <div className="tasks_actions main_actions grid">
      <UserProjectSelect type="tasks" itemsList={dataForUserSelect.list} />

      <div className="sub_actions">
        <div
          className={`columns_select ${showColumnsWindow ? 'active' : ''}`}
          onClick={() => {
            setShowColumnsCustom(!showColumnsWindow);
          }}
        />

        {/*<ViewWeekRoundedIcon onClick={() => {*/}
        {/*    setShowColumnsCustom(!showColumnsWindow)*/}
        {/*}} className={`columns_select${showColumnsWindow ? ' active' : ''}`}*/}
        {/*/>*/}
        <UpdateRoundedIcon
          onClick={() => setShowVisulaization(!showVisual)}
          className={`visualization_button${showVisual ? ' active' : ''}`}
        />

        <TableSearch
          searchValue={tableFilter}
          setSearchValue={searchChangeHandler}
          isOpen={!!tableFilter}
        />
      </div>

      <FilterButton
        context={'tasks'}
        text="Фильтр"
        active={appliedFiltersExist}
        addedClass={' filter_btn'}
      />

      {/*<DisplayTypeSelect selectedElem={{text: 'Список', value: 'list'}} selectHandler={() => {            }}/>*/}
      <Button
        title="Задача"
        icon="plus_circle"
        style={{ padding: '.5rem', width: '100px' }}
        onClick={() => {
          setShownTaskInfoPlate(true);
        }}
      />

      {showFiltersWindow && <TasksFilters />}
      {showColumnsWindow && <ColumnsWindow context={'tasks'} />}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    appliedFiltersExist: Boolean(
      Object.keys(state.tasksPage.filters.appliedFilters).length
    ),
    users: state.commonInfo.users,
    selectedUserId: state.tasksPage.selectedUserId,
    showVisual: state.tasksPage.showVisualization,
    showFiltersWindow: state.tasksPage.filtersWindowShow,
    tableFilter: state.tasksPage.tableFilter,
    showColumnsWindow: state.tasksPage.showColumnsWindow,
  };
};

const mapDispatchToProps = {
  setTaskAddMode,
  setShowVisulaization,
  setShowColumnsCustom,
  setTableFilter,
  changeTaskList,
  fetchTasks,
  setShownTaskInfoPlate,
  // setColsWindowShow
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksActions);
