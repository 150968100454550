import React, {useContext, useMemo, useRef} from 'react';
import {SelectContext} from './context'


const SelectBtn = ({searchString, setSearchString}) => {

    const context = useContext(SelectContext)
    let plate = useRef<HTMLDivElement>(null)

    function typing(event) {
        setSearchString(event.target.value)
    }

    // console.log(searchString)

    useMemo(() => {
        setSearchString('')
        if (context.selectedItem && context.selectedItem?.text !== '') {
            plate.current?.closest('.input_div')?.classList.add('text_exist')
        } else {
            plate.current?.closest('.input_div')?.classList.remove('text_exist')
        }
    }, [context.selectedItem])


    return (
        <div className={`secondary${context.isOpen ? ' is_open' : ''}${context.disabled ? ' disabled' : ''}`}
             ref={plate}>
            <div className={'current_item_text'} style={{display: 'block'}}>{context.selectedItem?.text}</div>
            <div className={'input_search_box'} style={{display: 'none'}}>
                <input type={'text'} onChange={event => typing(event)} value={searchString}
                       placeholder={'Выберите'}/>
            </div>
        </div>
    )
}


export default SelectBtn