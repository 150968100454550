import React, {useEffect, useCallback, useRef} from 'react'
import {connect} from 'react-redux'
import {State} from '../rootReducer'
import {setFilterWindowShow} from '../pages/Tasks/actions'
// import {setShowColumnsProjectsCustom, setShowProjectsCols} from "../pages/Projects/actions"
// import ColumnsCheckboxItem from "./components/ColumnsCustomItem/ColumnsCheckboxItem";
// import {columnsOfTables} from "./shared_data"
import {load_nextpage_notifications, showNotifWindow} from "./actions"
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded'
// import TaskStatus from "./components/TaskStatus/TaskStatus"
// import {Notification} from "./types"
import DateMaster from "../utils/DateMaster"
import {store, urlApp} from "../App"
import {mark_notification_as_read} from "./actions"
import {selectTask, setShownTaskInfoPlate} from "../pages/TaskInfoPlate/actions"
import {Task} from './types'
import {loadSpecificTask} from "../pages/Tasks/actions"

type Props = {
    showNotifWindow: (val: boolean) => void,
    remote_notifications: object,
    mark_notification_as_read: (id_notification: string, id_current_user: number, readonly?: boolean) => void
    users: any,
    end: boolean,
    tasks: Task[],
    setShownTaskInfoPlate: (val: boolean) => void,
    selectTask: (val: Task) => void
    load_nextpage_notifications: (val: string) => void,
    loadSpecificTask: (val: number) => void
}

const BuildNotificationRow = ({id_key, avatar_url, user_name, item, unread_mark = false, readClick, users, textAction}) => {
    // console.log(item.data)

    let avatara, fullname
    if (item.data && item.data.author_notif) {
        let author_id = parseInt(item.data.author_notif)
        users.forEach(user => {
            if (user.id === author_id) {
                if (user.image) avatara = user.image.url
                fullname = user.name + ' ' + user.surname
            }
        })
    }

    return (
        <div key={id_key} className={'notification_item'}>
            <div className={'avatar'}>
                {avatara && <img alt={'ava'} src={urlApp + `/files/${avatara ? avatara : ''}/download`}/>}
                {!avatara && <div className={'default_avatar'}/>}
            </div>
            <div className={'payload'}>
                <div className={'name_time'}>
                    <div className={'name_block'}>
                        {fullname ? fullname : 'формат некорректен'}
                        <div className={'read_mark'} onClick={() => {
                            readClick(item.id, item.notifiable_id)
                        }}>
                            {unread_mark && <FiberManualRecordRoundedIcon/>}
                        </div>
                    </div>
                    <div className={'time_ago'}>{new DateMaster(new Date(item.created_at)).commentDate}</div>
                </div>
                <div className={'action_text'} onClick={() => {
                    if (item.data.task_id) textAction(item.data.task_id, item.id, item.notifiable_id)
                }}>
                    <div className={'action'}
                         dangerouslySetInnerHTML={{__html: item.data && item.data.text && fullname? item.data.text : 'формат уведомления некорректен'}}>
                        {/*{item.data && item.data.text ? item.data.text : 'формат уведомления некорректен'}*/}
                    </div>
                    {/*<div className={'subject_of_action'}>{/*</div>*/}
                </div>
                {/*{item.data.comment && <div className={'additional_content'}>*/}
                {/*    <p>{item.data.comment}</p>*/}
                {/*</div>}*/}
                {/*{item.data.after && <TaskStatus id={parseInt(item.data.after)} taskId={item.data.task_id}/>}*/}
            </div>
        </div>
    )
}


const NotificationWindow: React.FC<Props> = ({loadSpecificTask, tasks, showNotifWindow, users, end, remote_notifications, mark_notification_as_read, load_nextpage_notifications}) => {

    // self-close for window
    let plate = useRef<HTMLDivElement>(null)
    const outsideClickHandler = useCallback(function (e: Event): any {
        let targetEl = e.target as Element
        if (e.target as Node && plate.current && !targetEl.closest('.' + plate.current.className)) {
            showNotifWindow(false)
        }
    }, [setFilterWindowShow])
    useEffect(() => {
        document.addEventListener('click', outsideClickHandler)
        return () => document.removeEventListener('click', outsideClickHandler)
    }, [outsideClickHandler])
    // self-close for window

    let allow = false
    const scrollHandler = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const {scrollHeight, scrollTop, clientHeight} = e.currentTarget
        if (scrollHeight - 1 <= scrollTop + clientHeight) {
            window.requestAnimationFrame(function () {
                if (!end) {
                    load_nextpage_notifications('ok')
                    allow = false
                }
            })
            allow = true
        }
    }, [end])

    const taskClickAction = (task_id) => {
        let found_task, specTask = true

        Object.keys(tasks).forEach((item) => {
            if (tasks[item].id === task_id) found_task = tasks[item]
        })

        if (found_task) {  // найдена в уже приехавших тасках
            setTimeout(() => {
                if (store.getState().tasksPage.taskAddMode) return
                selectTask(found_task)
                setShownTaskInfoPlate(true)
            }, 500)
        } else {
            if (specTask) {
                setTimeout(() => loadSpecificTask(task_id), 0)
                specTask = false
            }
        }
    }

    return (

        <div className={'notification_window'}
             ref={plate}>
            <div className={'header'}>
                <div className={'header_text'}>Уведомления</div>
                <div className={'text_link_action'}>Очистить</div>
            </div>

            <div className={'content'} onScroll={scrollHandler}>
                {remote_notifications['unread'].map((item, id_key) => {
                    let avatar_url = ''
                    let user_name = ''
                    users.forEach(users_item => {
                        if (users_item.id === item.notifiable_id) {
                            if (users_item.image) {
                                avatar_url = users_item.image.url
                            }
                            user_name = users_item.name + ' ' + users_item.surname
                        }
                    })
                    // console.log(item)
                    return (
                        <BuildNotificationRow key={id_key} id_key={id_key} avatar_url={avatar_url}
                                              user_name={user_name}
                                              users={users}
                                              item={item} unread_mark={true}
                                              readClick={(id_notif, id_current_user) => mark_notification_as_read(id_notif, id_current_user)}
                                              textAction={(task_id, id_notif, id_current_user) => {
                                                  taskClickAction(task_id)
                                                  mark_notification_as_read(id_notif, id_current_user, true)
                                              }}/>
                    )
                })}
                {remote_notifications['read'].map((item, id_key) => {
                    let avatar_url = ''
                    let user_name = ''
                    users.forEach(users_item => {
                        if (users_item.id === item.notifiable_id) {
                            if (users_item.image) {
                                avatar_url = users_item.image.url
                            }
                            user_name = users_item.name + ' ' + users_item.surname
                        }
                    })
                    return (
                        <BuildNotificationRow key={id_key} id_key={id_key} avatar_url={avatar_url}
                                              user_name={user_name}
                                              users={users}
                                              item={item} unread_mark={false}
                                              readClick={(id_notif, id_current_user) => mark_notification_as_read(id_notif, id_current_user)}
                                              textAction={(task_id, id_notif, id_current_user) => {
                                                  taskClickAction(task_id)
                                                  mark_notification_as_read(id_notif, id_current_user, true)
                                              }}/>
                    )
                })}
            </div>
        </div>

    )

}

const mapStateToProps = (state: State) => {
    // console.log(state.commonInfo.remote_notifications)
    return {
        tasks: state.tasksPage.tasks,
        end: state.commonInfo.remote_notifications.end,
        remote_notifications: state.commonInfo.remote_notifications,
        users: state.commonInfo.users
    }
}


const mapDispatchToProps = {
    showNotifWindow,
    mark_notification_as_read,
    load_nextpage_notifications,
    selectTask,
    setShownTaskInfoPlate,
    loadSpecificTask
}


export default connect(mapStateToProps, mapDispatchToProps)(NotificationWindow)