// import { type } from 'os';
import React from 'react';
// import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {urlApp} from '../../../App';
// import { State } from '../../../rootReducer';
import Icon from '../Icon';
import palette from '../palette';

type Props = {
    text?: string;
    image?: string | boolean;
    disabled?: boolean;
    onClick?: (e: React.MouseEvent) => void;
};

const WrapperChips = styled.div`
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2rem;
  background: ${palette.bgChips};
  color: ${palette.textBlack};
  margin: 0.25rem 0.25rem 0.25rem 0;
  padding: 5px;
  border-radius: 16px;
`;

const WrapperAvatar = styled.div`
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
`;

const Chips: React.FC<Props> = ({text, image, disabled = false, onClick}) => {
    return (
        <WrapperChips>
            {image && (
                <WrapperAvatar>
                    <Icon
                        width="100%"
                        height="100%"
                        src={image ? urlApp + `/files/${image}/download` : ''}
                    />
                </WrapperAvatar>
            )}

            <div style={{margin: '0 .75rem 0 .25rem'}}>{text}</div>
            {!disabled && (
                <Icon
                    name="rossDurkBlue"
                    onClick={onClick}
                    cursor="pointer"
                    style={{marginRight: '0.5rem'}}
                />
            )}
        </WrapperChips>
    );
};

export default Chips;
