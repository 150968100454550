import {
  types,
  fetchTasksAction,
  changeTaskListAction,
  setTaskAddModeAction,
  setFilterAddModeAction,
  setLoadingTasksAction,
  setSelectedUserIdAction,
  setTableFilterAction,
  setShowVisulaizationAction,
  setShowDaysOffAction,
  setStatusFilterAction,
  setFilterDraftAction,
  setFilterAddNewRow,
  changeVisualisationListAction,
  setAllOrActualAction,
  setTableOrderAction,
  setShowColumnsCustomAction,
  setShowableColumns,
  setExecAuthAction,
  loadSpecificTaskAction,
  fetchTasksVisualizationAction,
  clearVisualizationLish,
  //fetchTasksVisualizationAction,
} from './action_types';
import { Task } from '../../common/types';

export const fetchTasks = (pageNum: number): fetchTasksAction => ({
  type: types.FETCH_TASKS,
  pageNum,
});

export const setFilters = (filterObject: Object): setStatusFilterAction => ({
  type: types.SET_FILTERS,
  filterObject,
});

export const changeTaskList = (tasks: Task[]): changeTaskListAction => ({
  type: types.CHANGE_TASK_LIST,
  tasks,
});

export const fetchVisualizationList = (
  selectedUserId: number
): fetchTasksVisualizationAction => ({
  type: types.FETCH_TASKS_VISUALIZATION,
  selectedUserId,
});

export const changeVisualisationList = (
  tasks: Task[]
): changeVisualisationListAction => ({
  type: types.CHANGE_VISUALISATION_LIST,
  tasks,
});

export const clearVisualizationList = (): clearVisualizationLish => ({
  type: types.CLEAR_VISUALIZATION_LIST,
});

export const setTaskAddMode = (mode: boolean): setTaskAddModeAction => ({
  type: types.SET_TASK_ADD_MODE,
  mode,
});

export const setFilterWindowShow = (mode: boolean): setFilterAddModeAction => ({
  type: types.SET_FILTER_WIN_MODE,
  mode,
});

export const setDraftFilter = (draft: number): setFilterDraftAction => ({
  type: types.SET_DRAFT_VALUE,
  draft,
});

export const setAddNewRow = (value: boolean): setFilterAddNewRow => ({
  type: types.SET_FILTERS_ADD_NEW_ROW,
  value,
});

export const setLoadingTasks = (isLoading: boolean): setLoadingTasksAction => ({
  type: types.SET_LOADING_TASK,
  isLoading,
});

export const setTableFilter = (filter: string): setTableFilterAction => ({
  type: types.SET_TABLE_FILTER,
  filter,
});

export const setSelectedUserId = (id: number): setSelectedUserIdAction => ({
  type: types.SET_SELECTED_USER_ID,
  id,
});

export const setShowVisulaization = (
  show: boolean
): setShowVisulaizationAction => ({
  type: types.SET_SHOW_VISUALIZATION,
  show,
});

export const setShowColumnsCustom = (
  show: boolean
): setShowColumnsCustomAction => ({
  type: types.SET_SHOW_COLUMNS_WINDOW,
  show,
});

export const setShowDaysOff = (show: boolean): setShowDaysOffAction => ({
  type: types.SET_SHOW_DAYS_OFF,
  show,
});

export const ActualToggleAction = (value: object): setAllOrActualAction => ({
  type: types.SET_ALL_OR_ACTUAL,
  value,
});

export const SetExecAuth = (val: string): setExecAuthAction => ({
  type: types.SET_EXEC_AUTH,
  val,
});

export const setOrder = (
  col_name: string,
  order_direct: boolean
): setTableOrderAction => ({
  type: types.SET_TABLE_ORDER,
  col_name,
  order_direct,
});

export const setShowCols = (value: string): setShowableColumns => ({
  type: types.SET_SHOWING_COLS,
  value,
});

export const loadSpecificTask = (value: number): loadSpecificTaskAction => ({
  type: types.LOAD_SPECIFIC_TASK,
  value,
});
