import React, { useState, useRef, useCallback, useEffect } from 'react';
import { cookieMaster } from '../../../utils/CookieMaster';
import { fetchExistingUserData } from '../../actions';
import { State } from '../../../rootReducer';
import { connect, useSelector } from 'react-redux';
import Avatar from '../Avatar/Avatar';
import { useHistory } from 'react-router-dom';
import { urlApp } from '../../../App';

const HeaderAvatar = ({ currentUserInfo, currentId }) => {
  const [show, setShow] = useState<boolean>(false);
  const history = useHistory();
  const avatar = useRef<HTMLDivElement>(null);

  let name: string = `${currentUserInfo?.surname} ${currentUserInfo?.name}`;

  const outsideClick = useCallback((e: Event) => {
    if (!avatar.current?.contains(e.target as HTMLElement)) setShow(false);
  }, []);

  const exitHandler = useCallback(() => {
    cookieMaster.deleteCookie('access_token');
    cookieMaster.deleteCookie('login');
    window.location.pathname = '/login';
  }, []);

  useEffect(() => {
    document.addEventListener('click', outsideClick);
    return () => document.removeEventListener('click', outsideClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={avatar}
      onClick={() => setShow(true)}
      className="header_avatar"
      title="Профайл"
    >
      <Avatar
        style={{ with: '46px', height: '46px' }}
        url={
          currentUserInfo?.image
            ? urlApp + `/files/${currentUserInfo.image.url}/download`
            : null
        }
        name={name && name}
      />
      {show && (
        <div className="avatar_menu">
          <div
            onClick={() => history.push(`/users/${currentId}`)}
            className="my_profile"
          >
            Мой профиль
          </div>
          <div onClick={exitHandler} className="exit">
            Выйти
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  currentUserInfo: state.commonInfo.currentUserInfo,
  currentId: state.commonInfo.current_user,
});

const mapDispatchToProps = {
  fetchExistingUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAvatar);
