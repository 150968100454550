import React from 'react';
import styled, {css} from 'styled-components';
import palette from '../../palette';

type Props = {
    width?: string;
    disabled?: boolean;
    placeholder?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: (e: React.MouseEvent) => void;
};

const styleBlue = css`
  background: ${palette.blueLighter};
  border: 1px solid ${palette.blueLighter};
  input,
  textarea {
    background: ${palette.blueLighter};
  }
  &:hover {
    border: 1px solid ${palette.blueMain};
  }
  &:focus-within {
    background: ${palette.white};
    border: 1px solid ${palette.blueMain};

    input,
    textarea {
      background: ${palette.white};
    }
  }
`;

const StyledInput = styled.input`
  // background: ${palette.white};
  background: ${({disabled}) => disabled ? 'rgba(0, 0, 0, 0.02)' : palette.white};
  border: 1px solid ${palette.lightGray};
  box-sizing: border-box;
  border-radius: 6px;
  max-height: 2.5rem;
  height: 100%;
  width: ${({width}) => width};
  padding: 0.75rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${palette.textBlack};
`;

const SearchInput: React.FC<Props> = ({
                                          width = '100%',
                                          disabled,
                                          onChange,
                                          placeholder = 'Поиск',
                                          onClick
                                      }) => {
    return (
        <StyledInput
            disabled={disabled}
            placeholder={placeholder}
            width={width}
            onChange={onChange}
            onClick={onClick}
        />
    );
};

export default SearchInput;
