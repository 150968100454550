import React, { useState, useEffect, useRef, useCallback } from 'react';



const Comment = ({ comment, setComment }) => {

    const [show, setShow] = useState(false)
    const [commentValue, setCommentValue] = useState(comment)

    const area = useRef<HTMLDivElement>(null)

    const outsideClick = useCallback((e: MouseEvent) => {
        if (!area.current?.contains(e.target as HTMLElement)) setShow(false)
    }, [])

    const confirmClick = useCallback(() => {
        setShow(false)
        setComment(commentValue)
    }, [commentValue, setComment])

    useEffect(() => {
        setCommentValue(comment)
    }, [comment])

    useEffect(() => {
        document.addEventListener('click', outsideClick)
        return () => document.removeEventListener('click', outsideClick)
    })


    return (
        <div ref={area} className="comment_cell">
            <div onClick={() => setShow(true)} className="comment_btn" />
            <div className="comment_body">
                {comment}
            </div>
            {show && (
                <div className="comment_area">
                    <textarea value={commentValue} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setCommentValue(e.target.value)}/>
                    <div onClick={confirmClick} className="btn secondary">Готово</div>
                </div>
            )}
        </div>
    )
}


export default Comment