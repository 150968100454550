import React from 'react';
import { TableRow, TableHead as HeadWrapper } from '@material-ui/core';
import {Cell} from './styledComponents'

const TableHead = () => {
    return (
        <HeadWrapper>
            <TableRow>
                <Cell style={{width: 400, paddingLeft: 24}}>План</Cell>
                <Cell align="right">Трудоемкость, ч</Cell>
                <Cell align="right">Приоритет</Cell>
                <Cell align="right">Затрачено, ч</Cell>
                <Cell align="center">Выполнение</Cell>
                <Cell style={{width: 295}}>Комментарий</Cell>
            </TableRow>
        </HeadWrapper>
    )
}

export default TableHead