import {
    types,
    setTaskInfoActiveTabAction,
    setShownTaskInfoPlateAction,
    selectTaskAction,
    setProjectUsersAction,
    setComentListAction,
    deleteCommentAction,
    uploadCommentFileAction,
    deleteCommentFileAction,
    setCommentFilesAction,
    setCommentTextAction,
    sendCommentAction,
    setIsSendongCommentAction,
    clearCommentFieldAction,
    setParentCommentAction,
    setExecutorScheduleAction,
    setProjectSectionsAction,
    fetchProjectSectionsAction,
    setDelegeteToAction,
    addCommentsListAction,
    getCommentsListAction,
    setChangeCheckboxItem,
    setRecalculateLoadAction,
    setTaskWorkLoadToggle,
    setRewriteWholeTaskList,
    setDeleteCheckboxItem,
    setChangeReducerCheckboxItem,
    delegateConfirmAction,
    successfulDelegatedToAction,
    sendingDelegateAction,
    setTaskNameAction,
    setTaskDescriptionAction,
    setTaskProjectAction,
    setTaskSectionAction,
    setTaskPriorityAction,
    setSetProvideTo,
    setExecutorIdAction,
    setTaskWorkLoadAction,
    setTaskLoadAction,
    createTaskAction,
    setTaskBeginAction,
    setTaskEndAction,
    setDefaultValuesAction,
    setPrevIdAction,
    setNextIdAction,
    successfulCreatedNewTasksAction,
    setParentIdAction,
    setChildTasksAction,
    setStartCiclickAction,
    setFinishCiclickAction,
    sendingCreateNewTaskAction,
    setCyclicTaskToChangeAction,
    setFlagRepeatTaskToChangeAction, sendReqToChangeTemplRepTaskAction, setParameterTriggerAction
} from './action_types';
import {
    Task,
    User,
    Comment,
    File as FileType,
    Schedule,
    Section,
    Item,
} from '../../common/types';

export const setTaskInfoActiveTab = (
    activeTab: string
): setTaskInfoActiveTabAction => ({
    type: types.SET_TASK_INFO_ACTIVE_TAB,
    activeTab,
});

export const setShownTaskInfoPlate = (
    isShownTaskInfoPlate: boolean
): setShownTaskInfoPlateAction => ({
    type: types.SET_SHOWN_TASK_INFO_PLATE,
    isShownTaskInfoPlate,
});

export const selectTask = (task: Task | null): selectTaskAction => ({
    type: types.SELECT_TASK,
    task,
});

export const setProjectUsers = (users: User[]): setProjectUsersAction => ({
    type: types.SET_PROJECT_USERS,
    users,
});

export const setComentList = (commentList: Comment[]): setComentListAction => ({
    type: types.SET_COMMENTS_LIST,
    commentList,
});

export const addCommentsList = (
    commentList: Comment[]
): addCommentsListAction => ({
    type: types.ADD_COMMENTS_LIST,
    commentList,
});

export const getCommentsList = (pageNum: number): getCommentsListAction => ({
    type: types.GET_COMMENTS_LIST,
    pageNum,
});

export const deleteComment = (
    taskId: number,
    commentId: number
): deleteCommentAction => ({
    type: types.DELETE_COMMENT,
    taskId,
    commentId,
});

export const uploadFile = (file: File): uploadCommentFileAction => ({
    type: types.UPLOAD_FILE,
    file,
});

export const deleteFile = (id: number): deleteCommentFileAction => ({
    type: types.DELETE_FILE,
    id,
});

export const setCommentFiles = (files: FileType[]): setCommentFilesAction => ({
    type: types.SET_COMMENT_FILES,
    files,
});

export const setCommentText = (text: string): setCommentTextAction => ({
    type: types.SET_COMMENT_TEXT,
    text,
});

export const sendComment = (task_Id?: number): sendCommentAction => ({
    type: types.SEND_COMMENT,
    task_Id,
});

export const setIsSendongComment = (
    isSending: boolean
): setIsSendongCommentAction => ({
    type: types.SET_IS_SENDING_COMMENT,
    isSending,
});

export const setIsSendingDelegate = (
    isSending: boolean
): sendingDelegateAction => ({
    type: types.SET_IS_SENDING_DELEGATE,
    isSending,
});


export const isSendingNewTask = (isSending: boolean): sendingCreateNewTaskAction => ({
    type: types.SET_IS_SENDING_CREATE_NEW_TASK,
    isSending,
});


export const clearCommentField = (): clearCommentFieldAction => ({
    type: types.CLEAR_COMMENT_FILED,
});

export const setParentComment = (
    parent: Comment | null
): setParentCommentAction => ({
    type: types.SET_PARENT_COMMENT,
    parent,
});

export const setExecutorSchedule = (
    schedule: Schedule
): setExecutorScheduleAction => ({
    type: types.SET_EXECUTOR_SCHEDULE,
    schedule,
});

export const setProjectSections = (
    sections: Section[]
): setProjectSectionsAction => ({
    type: types.SET_PROJECT_SECTIONS,
    sections,
});

export const fetchProjectSections = (
    projectId: number
): fetchProjectSectionsAction => ({
    type: types.FETCH_PROJECT_SECTIONS,
    projectId,
});

export const setDelegeteTo = (delegeteTo: number): setDelegeteToAction => ({
    type: types.SET_DELEGETE_TO,
    delegeteTo,
});

export const delegateConfirm = (
    users_ids: number[]
): delegateConfirmAction => ({
    type: types.DELEGATE_CONFIRM_ACTION,
    users_ids,
});

export const setTaskWorkLoadToggler = (): setTaskWorkLoadToggle => ({
    type: types.SET_TASKWORKLOAD_TOGGLER,
});

export const recalculateTaskLoad = (
    arg?: object
): setRecalculateLoadAction => ({
    type: types.SET_RECALCULATE,
    arg,
});

export const dispatchCheckboxItem = (
    orderNumber: number | null,
    text: string,
    checkboxState: boolean,
    actionType: string
): setChangeCheckboxItem => ({
    type: types.DISPATCH_CHECKBOX_ITEM,
    orderNumber,
    text,
    checkboxState,
    actionType,
});

export const rewriteWholeTaskList = (
    newArray: any[]
): setRewriteWholeTaskList => ({
    type: types.REWRITE_WHOLE_TASKLIST,
    newArray,
});

export const changeReducerCheckboxItem = (
    orderNumber: number | null,
    text: string,
    checkboxState: boolean,
    actionType?: string,
    id?: number
): setChangeReducerCheckboxItem => ({
    type: types.UPDATE_REDUCER_CHECKBOX_ITEM,
    orderNumber,
    text,
    checkboxState,
    id,
    actionType,
});

export const setTaskName = (name: string): setTaskNameAction => ({
    type: types.SET_TASK_NAME,
    name,
});

export const setTaskDescription = (description: string): setTaskDescriptionAction => ({
    type: types.SET_TASK_DESCRIPTION,
    description,
});

export const setTaskProject = (projectId: number): setTaskProjectAction => ({
    type: types.SET_TASK_PROJECT,
    projectId,
});

export const setTaskSection = (id: number): setTaskSectionAction => ({
    type: types.SET_TASK_SECTION,
    id,
});

export const setTaskPriority = (priority: number): setTaskPriorityAction => ({
    type: types.SET_TASK_PRIORITY,
    priority,
});

export const setProvideTo = (val: number): setSetProvideTo => ({
    type: types.SET_PROVIDE_TO,
    val,
});

export const setExecutorId = (value: number | null): setExecutorIdAction => ({
    type: types.SET_EXECUTOR_ID,
    value,
});

export const setTaskWorkLoad = (workLoad: any): setTaskWorkLoadAction => ({
    type: types.SET_TASK_WORKLOAD,
    workLoad,
});

export const setTaskLoad = (taskLoad: any): setTaskLoadAction => ({
    type: types.SET_TASKLOAD,
    taskLoad,
});

export const setStartCyclick = (
    period?: string,
    params?: Array<number>,
    interval?: number,
    show_modal_window?: boolean,
    settings_string?: string
): setStartCiclickAction => ({
    type: types.SET_START_CYCLICK,
    period,
    params,
    interval,
    show_modal_window,
    settings_string
});

export const setFinishCyclick = (
    ending_condition?: string,
    end_count?: number | null,
    end_date?: string | null
): setFinishCiclickAction => ({
    type: types.SET_FINISH_CYCLICK,
    ending_condition,
    end_count,
    end_date,
});

export const createTask = (executor_id?: number): createTaskAction => ({
    type: types.CREATE_TASK,
    executor_id,
});

export const setTaskBegin = (begin: string): setTaskBeginAction => ({
    type: types.SET_TASK_BEGIN,
    begin,
});

export const setTaskEnd = (end: string): setTaskEndAction => ({
    type: types.SET_TASK_END,
    end,
});

export const setPrevId = (value: number | null): setPrevIdAction => ({
    type: types.SET_PREV_ID,
    value,
});

export const setNextId = (value: number | null): setNextIdAction => ({
    type: types.SET_NEXT_ID,
    value,
});

export const setParentId = (value: number | null): setParentIdAction => ({
    type: types.SET_PARENT_ID,
    value,
});

export const setChildTasks = (value: any[]): setChildTasksAction => ({
    type: types.SET_CHILD_TASKS,
    value,
});

export const setDefaultValues = (): setDefaultValuesAction => ({
    type: types.SET_DEFAULT_VALUES,
})


export const setParameterTrigger = (): setParameterTriggerAction => ({
    type: types.SET_PARAMETER_TRIGGER,
})


export const setCyclicTaskToChange = (obj: {}): setCyclicTaskToChangeAction => ({
    type: types.SET_CYCLIC_TASK_CHANGE,
    obj
})


export const setFlagRepeatTaskToChange = (val: string): setFlagRepeatTaskToChangeAction => ({
    type: types.SET_FLAG_REPEAT_TASK_CHANGE,
    val
})


export const sendReqToChangeTemplRepTask = (obj: {}): sendReqToChangeTemplRepTaskAction => ({
    type: types.SEND_REQ_CHANGE_TEMPL_REP_TASK,
    obj
})


// export const successfulCreatedNewTasks = (value: {}): successfulCreatedNewTasksAction => ({
export const successfulCreatedNewTasks = (value: {
    executorsIds: [];
    createdTasks: any[];
    checkItems: [];
}): successfulCreatedNewTasksAction => ({
    type: types.SUCCESSFUL_CREATED_NEW_TASKS,
    value,
});
