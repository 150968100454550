import {Action} from "redux";
import {User} from "../../common/types";

export enum types {
    ADD_USER_TO_GROUP_DEP = '@@USERS/ADD_USER_TO_GROUP_DEP',
    SET_SHOW_DEP_GROUP_PLATE = '@@USERS/SET_SHOW_DEP_GROUP_PLATE',
    OPEN_DEP_GROUP_PLATE = '@@USERS/OPEN_DEP_GROUP_PLATE',
    SET_DEP_GROUP_PLATE_TYPE = '@@USERS/SET_DEP_GROUP_PLATE_TYPE',
    SET_USER_ADD_MODE = '@@USERS/SET_USER_ADD_MODE',
    SET_DEP_FILTER = '@@USERS/SET_DEP_FILTER',
    SELECT_DEP_GROUP = '@@USERS/SELECT_DEP_GROUP',
    OPEN_DEPS = '@@USERS/OPEN_DEPS',
    OPEN_GROUPS = '@@USERS/OPEN_GROUPS',
    SAVE_DEP = '@@USERS/SAVE_DEP',
    SAVE_GROUP = '@@USERS/SAVE_GROUP',
    FETCH_USERS = '@@USERS/FETCH_USERS',
    SET_IS_FETCHING_USERS = '@@USERS/SET_IS_FETCHING_USERS',
    IS_CREATING_DEP = '@@USERS/IS_CREATING_DEP',
    GET_USERS = '@@USERS/GET_USERS',
    SET_USERS = '@@USERS/SET_USERS',
    ADD_USER_TO_DEP_GROUP = '@@USERS/ADD_USER_TO_DEP_GROUP',
    ADD_USER_TO_GROUP = '@@USERS/ADD_USER_TO_GROUP',
    REMOVE_USER_FROM_DEP_GROUP = '@@USERS/REMOVE_USER_FROM_DEP_GROUP',
    REMOVE_USER_FROM_GROUP = '@@USERS/REMOVE_USER_FROM_GROUP',
    SET_TABLE_FILTER = '@@USERS/SET_TABLE_FILTER',
    SET_TABLE_ORDER_USERS = '@@USERS/SET_TABLE_ORDER_USERS',
    SHOW_WINDOW_CREATE_DEPGROUP = '@@USERS/SHOW_WINDOW_CREATE_DEPGROUP',
    UPDATE_DEP_GROUP_INFO = '@@USERS/UPDATE_DEP_GROUP_INFO',
    DELETE_DEP_GROUP = '@@USERS/DELETE_DEP_GROUP',
    GET_NEXT_PAGE_AMONG_ALL = '@@USERS/GET_NEXT_PAGE_AMONG_ALL',
    PUT_FETCHED_USERS_BY_PAGES = '@@USERS/PUT_FETCHED_USERS_BY_PAGES'
}

export interface setAddUserToGroupDepAction extends Action<types.ADD_USER_TO_GROUP_DEP> {
    show: boolean
}

export interface openDepGroupPlateAction extends Action<types.OPEN_DEP_GROUP_PLATE> {
}

export interface fetchUsersAction extends Action<types.FETCH_USERS> {
    pageNum: number
}

export interface openDepsAction extends Action<types.OPEN_DEPS> {
}

export interface openGroupsAction extends Action<types.OPEN_GROUPS> {
}

export interface setUserAddModeAction extends Action<types.SET_USER_ADD_MODE> {
    mode: boolean
}

export interface setDepFilterAction extends Action<types.SET_DEP_FILTER> {
    filter: string
}

export interface selectDepGroupAction extends Action<types.SELECT_DEP_GROUP> {
    id: number | null,
    totalUsers: number | null
}

export interface saveDepAction extends Action<types.SAVE_DEP> {
    name: string,
    head_id: number
}

export interface saveGroupAction extends Action<types.SAVE_GROUP> {
    name: string,
    head_id: number
}

export interface setIsFetchingUsersAction extends Action<types.SET_IS_FETCHING_USERS> {
    isFetchingUsers: boolean
}

export interface isCreatingDepAction extends Action<types.IS_CREATING_DEP> {
    isCreatingDep: boolean
}


export interface showWindowCreateDepGroupAction extends Action<types.SHOW_WINDOW_CREATE_DEPGROUP> {
    val: boolean
}

export interface updateDepGroupInfoAction extends Action<types.UPDATE_DEP_GROUP_INFO> {
    name: string,
    head_id: number
}


export interface setDepGroupTypeAction extends Action<types.SET_DEP_GROUP_PLATE_TYPE> {
    plateType: string | null
}

export interface setUsersAction extends Action<types.SET_USERS> {
    users: User[]
}

export interface addUserToDepGroupAction extends Action<types.ADD_USER_TO_DEP_GROUP> {
    depGroupId: number,
    user: User
}

export interface addUserToGroupAction extends Action<types.ADD_USER_TO_GROUP> {
    groupId: number,
    user: User
}

export interface removeUserFromDepGroupAction extends Action<types.REMOVE_USER_FROM_DEP_GROUP> {
    depId: number,
    userId: number
}

export interface removeUserFromGroupAction extends Action<types.REMOVE_USER_FROM_GROUP> {
    groupId: number,
    userId: number
}

export interface setTableFilterAction extends Action<types.SET_TABLE_FILTER> {
    filter: string
}

export interface setTableOrderAction extends Action<types.SET_TABLE_ORDER_USERS> {
    col_name: string
    order_direct: boolean
}

export interface deleteDepGroupAction extends Action<types.DELETE_DEP_GROUP> {
}


export interface getNextPageAmongAllAction extends Action<types.GET_NEXT_PAGE_AMONG_ALL> {
    filter: string,
    page?: number
}

export interface putFetchedUsersByPagesAction extends Action<types.PUT_FETCHED_USERS_BY_PAGES> {
    users: User[],
    page?: number
}



export type UsersAction =
    setAddUserToGroupDepAction |
    openDepGroupPlateAction |
    setUserAddModeAction |
    setDepFilterAction |
    selectDepGroupAction |
    openDepsAction |
    openGroupsAction |
    saveDepAction |
    saveGroupAction |
    fetchUsersAction |
    setIsFetchingUsersAction |
    isCreatingDepAction |
    setDepGroupTypeAction |
    setUsersAction |
    addUserToDepGroupAction |
    addUserToGroupAction |
    removeUserFromDepGroupAction |
    removeUserFromGroupAction |
    setTableFilterAction |
    setTableOrderAction |
    showWindowCreateDepGroupAction |
    updateDepGroupInfoAction |
    deleteDepGroupAction |
    getNextPageAmongAllAction |
    putFetchedUsersByPagesAction