import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import {
  setProjectSections,
  removeProjectSection,
  changeSectionName,
} from '../actions';
import { State } from '../../../rootReducer';
import { SectionInComponent } from '../../../common/types';
import styled from 'styled-components';
import palette from '../../../common/components/palette';

type Props = {
  name: string;
  index: number;
  id: number;
  sections: SectionInComponent[];
  setProjectSections: (sections: SectionInComponent[]) => void;
  removeProjectSection: (sectionId: number) => void;
  changeSectionName: (sectionId: number, name: string) => void;
};

const WrapperInput = styled.div`
  height: 100%;
  border-radius: 6px;
  position: relative;
  border: 1px solid ${palette.blackLighter};
`;

const SectionElem: React.FC<Props> = ({
  id,
  name,
  index,
  sections,
  changeSectionName,
  setProjectSections,
  removeProjectSection,
}) => {
  const [isEdit, setEdit] = useState<boolean>(false);
  const [inputValue, setValue] = useState<string>(name);

  useEffect(() => {
    setValue(name);
  }, [name]);

  const input = useRef<HTMLInputElement>(null);

  const blurHandler = useCallback(() => {
    if (inputValue === name) return;
    if (id) {
      changeSectionName(id, inputValue);
    } else {
      let clone = sections.slice();
      clone.splice(index, 1, { name: inputValue, id: 0 });
      setProjectSections(clone);
    }
    setEdit(false);
  }, [
    changeSectionName,
    id,
    index,
    inputValue,
    name,
    sections,
    setProjectSections,
  ]);

  const deleteHandler = useCallback(() => {
    if (id) {
      removeProjectSection(id);
    } else {
      let clone = sections.slice();
      clone.splice(index, 1);
      setProjectSections(clone);
    }
  }, [id, removeProjectSection, sections, index, setProjectSections]);

  return (
    <li>
      {isEdit ? (
        <WrapperInput>
          <input
            ref={input}
            value={inputValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setValue(e.currentTarget.value)
            }
            onBlur={blurHandler}
          />
        </WrapperInput>
      ) : (
        <div>
          <span>{name}</span>
          <img
            onClick={() => {
              setEdit(true);
              setTimeout(() => input.current?.focus(), 0);
            }}
            src={require('../../../img/pen.png')}
            alt="pen"
          />
          <img
            onClick={() => deleteHandler()}
            src={require('../../../img/red_union.png')}
            alt="delete"
          />
        </div>
      )}
    </li>
  );
};

const mapStateToProps = (state: State) => {
  return {
    sections: state.newProject.sections,
  };
};

const mapDispatchToProps = {
  setProjectSections,
  removeProjectSection,
  changeSectionName,
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionElem);
