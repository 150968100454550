import React from 'react';
import PeopleItem from './Items/People';
import styled from 'styled-components';
import { Item } from '../../types';

type Props = {
  users: Item[];
  selectedUsers: Item[];
  checked?: boolean;
  radio?: boolean;
  addHandler: (value: number) => void;
  removeHandler: (value: number) => void;
};

const WrapperBlockPeople = styled.div`
  position: absolute;
  top: 2.75rem;
  z-index: 1;
  max-height: 304px;
  width: 100%;
  overflow: auto;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
`;
const CheckboxPeople: React.FC<Props> = ({
  users,
  selectedUsers,
  removeHandler,
  addHandler,
  radio,
}) => {
  return (
    <WrapperBlockPeople>
      {users.map((user) => {
        return (
          <PeopleItem
            key={user.value}
            value={+user.value}
            urlImage={user.urlImage}
            name={user.rus_name ? user.rus_name : user.text}
            position={user.position}
            checked={selectedUsers.some((i) => i.value === user.value)}
            radio={radio}
            addHandler={addHandler}
            removeHandler={removeHandler}
          />
        );
      })}
    </WrapperBlockPeople>
  );
};

export default CheckboxPeople;
