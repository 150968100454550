import {cookieMaster} from './CookieMaster';
import {CreateNotif} from './createNotification';
import {urlApp, store} from '../App';
import {setVerified} from '../common/actions';

export const loginBodyParts = {
    grant_type: 'password',
    client_id: '2',
    client_secret: '67KYLMbv2Zfj9wy8CS0Iyb0glqayQt6Mk5itWnmE',
    // client_secret: 'HIGePrqaTcm4BomgJZAFEL7PJWYQDA4ohYIpjyvy',
};

type Headers = {
    [key: string]: string;
};

export class FetchData {
    //private baseUrl: string = urlApp // вынес в App
    // private baseUrl: string = 'http://dev.easy-task.ru'
    private headers: Headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        Authorization: `${cookieMaster.getCookie('access_token')}`,
    };
    public responseHandlerMethod: string = 'json';

    private async makeRequest(
        url: string,
        method: string,
        body: any,
        headers: Headers = this.headers,
        responseHandlerMethod: string = this.responseHandlerMethod
    ): Promise<any | undefined> {
        try {
            // console.log(method, url, body)
            const res = await fetch(`${urlApp}${url}`, {
                method,
                headers: headers,
                body,
            });
            const res_1 = await this.statusCheck(res, responseHandlerMethod);
            return this.responseHandler(res_1, url);
        } catch (err) {
            throw err;
        }
    }

    private statusCheck(
        response: Response,
        responseHandlerMethod: string
    ): Promise<any> | undefined {
        if (response.status === 401) {
            // cookieMaster.deleteCookie('access_token')
            // cookieMaster.deleteCookie('login')
            // window.location.pathname = '/login'
            // CreateNotif('')
            // window.history.replaceState(null, '', '/login')
            return response[responseHandlerMethod]();
        } else if (response.status === 403) {
            store.dispatch(setVerified(false));
            return response[responseHandlerMethod]();
        } else {
            return response[responseHandlerMethod]();
        }
    }

    private responseHandler(response: any, url: string) {
        if (response.error) {
            if (response.details) {
                Object.values(response.details).forEach((detail) => {
                    if (typeof detail === 'string') CreateNotif(detail);
                    else (detail as string[]).forEach((text) => CreateNotif(text));
                });
            } else {
                // если битая авторизация, то перенаправлять на логин
                if (response.error === 'Unauthenticated.') {
                    window.location.assign(`${urlApp}/login`);
                }
                CreateNotif(response.error);
            }
        } else {
            if (url.includes('/api/v1/projects?')) {
                return response;
            }
            if (url.includes('/api/v1/projectsections?')) {
                return response;
            }

            return response.data || response;
        }
    }

    public get = (
        url: string,
        headers?: Headers,
        responseHandlerMethod?: string
    ) => {
        return this.makeRequest(url, 'GET', null, headers, responseHandlerMethod);
    };

    public post = (
        url: string,
        body: any,
        headers?: Headers,
        responseHandlerMethod?: string
    ) => {
        return this.makeRequest.call(
            this,
            url,
            'POST',
            body,
            headers,
            responseHandlerMethod
        );
    };

    public patch = (
        url: string,
        body: any,
        headers?: Headers,
        responseHandlerMethod?: string
    ) => {
        return this.makeRequest(url, 'PATCH', body, headers, responseHandlerMethod);
    };

    public delete = (
        url: string,
        headers?: Headers,
        responseHandlerMethod?: string
    ) => {
        return this.makeRequest(
            url,
            'DELETE',
            null,
            headers,
            responseHandlerMethod
        );
    };

    public addHeaders(headers: Headers): void {
        this.headers = {
            ...this.headers,
            ...headers,
        };
    }
}

export const fetchData = new FetchData();
