import { Action } from 'redux';
import {
  Position,
  Department,
  Role,
  Status,
  User,
  Project,
  Priority,
  Workgroup,
  Section,
  Notification,
  Schedule,
} from './types';

export enum types {
  FETCH_COMMON_INFO = '@@COMMON/FETCH_COMMON_INFO',
  ADD_COMMON_INFO = '@@COMMON/ADD_COMMON_INFO',
  ENQUEUE_SNACKBAR = '@@COMMON/ENQUEUE_SNACKBAR',
  CLOSE_SNACKBAR = '@@COMMON/CLOSE_SNACKBAR',
  REMOVE_SNACKBAR = '@@COMMON/REMOVE_SNACKBAR',
  SET_USERS = '@@COMMON/SET_USERS',
  CHANGE_WORK_SCHEDULE = '@@COMMON/CHANGE_WORK_SCHEDULE',
  SET_DEPARTMENTS = '@@COMMON/SET_DEPARTMENTS',
  SET_WORKGROUPS = '@@COMMON/SET_WORKGROUPS',
  SET_PROJECTS = '@@COMMON/SET_PROJECTS',
  SET_SECTIONS = '@@COMMON/SET_SECTIONS',
  UPDATE_TASK = '@@COMMON/UPDATE_TASK',
  SET_VERIFYED = '@@COMMON/SET_VERIFYED',
  FETCH_EXISTING_USER_DATA = '@@COMMON/FETCH_EXISTING_USER_DATA',
  SET_SHOW_NOTIF_WINDOW = '@@COMMON/SET_SHOW_NOTIF_WINDOW',
  SET_CURRENT_USER_INFO = '@@COMMON/SET_CURRENT_USER_INFO',
  SET_NOTIFICATION_READ = '@@COMMON/SET_NOTIFICATION_READ',
  SET_REMOTE_NOTIFICATIONS = '@@COMMON/SET_REMOTE_NOTIFICATIONS',
  SET_LOAD_NEXTPAGE_NOTIFICATIONS = '@@COMMON/SET_LOAD_NEXTPAGE_NOTIFICATIONS',
  SET_ADD_NOTIFICATIONS_REDUCER = '@@COMMON/SET_ADD_NOTIFICATIONS_REDUCER',
}

export interface fetchCommonInfoAction
  extends Action<types.FETCH_COMMON_INFO> {}

export interface changeWorkScheduleAction
  extends Action<types.CHANGE_WORK_SCHEDULE> {
  schedule: Schedule[];
}

export interface addCommonInfoAction extends Action<types.ADD_COMMON_INFO> {
  projects: Project[];
  users: User[];
  statuses: Status[];
  roles: Role[];
  positions: Position[];
  departments: Department[];
  priorities: Priority[];
  workgroups: Workgroup[];
  sections: Section[];
  unread_notifications: Notification[];
  read_notifications: Notification[];
  load_next: string;
  end: boolean;
}

export interface enqueueSnackbarAction extends Action<types.ENQUEUE_SNACKBAR> {
  notification: any;
}

export interface closeSnackbarAction extends Action<types.CLOSE_SNACKBAR> {
  key: number;
}

export interface removeSnackbarAction extends Action<types.REMOVE_SNACKBAR> {
  key: number;
}

export interface setUsersAction extends Action<types.SET_USERS> {
  users: User[];
}

export interface setDepartmentsAction extends Action<types.SET_DEPARTMENTS> {
  departments: Department[];
}

export interface setWorkgroupsAction extends Action<types.SET_WORKGROUPS> {
  workgroups: Workgroup[];
}

export interface setProjectsAction extends Action<types.SET_PROJECTS> {
  projects: Project[];
}

export interface setSectionsAction extends Action<types.SET_SECTIONS> {
  sections: Section[];
}

export interface updateTaskAction extends Action<types.UPDATE_TASK> {
  taskId: number;
  params: any;
  withNotif: boolean;
}

export interface setVerifiedAction extends Action<types.SET_VERIFYED> {
  verified: boolean;
}

export interface fetchExistingUserDataAction
  extends Action<types.FETCH_EXISTING_USER_DATA> {
  id: number;
}

export interface setCurrentUserInfoAction
  extends Action<types.SET_CURRENT_USER_INFO> {
  user: User;
}

export interface setShowNotifWindow
  extends Action<types.SET_SHOW_NOTIF_WINDOW> {
  val: boolean;
}

export interface setMarkNotificationRead
  extends Action<types.SET_NOTIFICATION_READ> {
  id_notification: string;
  id_current_user: number;
  readonly?: boolean;
}

export interface setChangeRemoteNotifs
  extends Action<types.SET_REMOTE_NOTIFICATIONS> {
  unread: object;
  read: object;
  load_next?: string;
  end?: boolean;
}

export interface setLoadNextPageNotifications
  extends Action<types.SET_LOAD_NEXTPAGE_NOTIFICATIONS> {
  context: string;
}

// export interface setAddNotificationsReducer extends Action<types.SET_ADD_NOTIFICATIONS_REDUCER> {
//     context: string
// }

export type CommonAction =
  | fetchCommonInfoAction
  | addCommonInfoAction
  | enqueueSnackbarAction
  | closeSnackbarAction
  | removeSnackbarAction
  | setUsersAction
  | setDepartmentsAction
  | setWorkgroupsAction
  | setProjectsAction
  | setSectionsAction
  | updateTaskAction
  | setVerifiedAction
  | fetchExistingUserDataAction
  | setCurrentUserInfoAction
  | setShowNotifWindow
  | setMarkNotificationRead
  | setChangeRemoteNotifs
  | setLoadNextPageNotifications
  | changeWorkScheduleAction;
// setAddNotificationsReducer
