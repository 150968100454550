import React from 'react';
import {setTaskSection} from '../actions';
import withTitle from '../../../utils/hoc/withTitle';
import {connect} from 'react-redux';
import SelectWithSecondaryBtn from '../../../common/components/Selects/SelectWithSecondaryBtn';
import {State} from '../../../rootReducer';
import {getSectionsSelectData} from '../selectors';
import {SelectData} from '../../../common/types';

type Props = {
    sectionsData: SelectData;
    delegated: boolean;
    setTaskSection: (id: number) => void;
};

const SectionSelect: React.FC<Props> = ({
                                            sectionsData,
                                            delegated,
                                            setTaskSection,
                                        }) => {
    return (
        <SelectWithSecondaryBtn
            list={sectionsData.list}
            selected={sectionsData.selected}
            disabled={sectionsData.list.length === 0 || delegated}
            selectHandler={(value) => setTaskSection(value as number)}
        />
    );
};

const mapStateToProps = (state: State) => ({
    sectionsData: getSectionsSelectData(state),
    delegated: state.newTask.delegated,
});

const mapDispatchToProps = {
    setTaskSection,
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionSelect);
