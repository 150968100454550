import React from 'react';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import {connect} from "react-redux";
import {State} from "../../../rootReducer";
import {setFilterWindowShow} from '../../../pages/Tasks/actions'
import {setFilterWindowShowProjects} from "../../../pages/Projects/actions";

type Props = {
    text: string,
    active: boolean,
    addedClass: string,
    setFilterWindowShow: (mode: boolean) => void,
    setFilterWindowShowProjects: (mode: boolean) => void,
    context: string,
    wholeState: State
    // clickOnActive: () => void,
    // clickOnPassive: () => void
}


const FilterButton: React.FC<Props> = ({context, wholeState, text, active, addedClass, setFilterWindowShow, setFilterWindowShowProjects}) => {

    let showFiltersWindow,
        exec_auth

    if (context === 'tasks') {
        showFiltersWindow = wholeState.tasksPage.filtersWindowShow
        exec_auth = wholeState.tasksPage.filters.exec_auth
    } else {
        showFiltersWindow = wholeState.projectsPage.filtersWindowShow
        exec_auth = wholeState.projectsPage.filters.exec_auth
    }

    return (
        <div onClick={() => {
            if (showFiltersWindow) {
                context === 'tasks' ? setFilterWindowShow(false) : setFilterWindowShowProjects(false)
            } else {
                context === 'tasks' ? setFilterWindowShow(true) : setFilterWindowShowProjects(true)
            }
        }}
             className={`btn ${(active || exec_auth !== '') ? ' active' : ''}${addedClass}`}
        >
            <FilterListRoundedIcon/>
            {text}
        </div>
    )
}

const mapStateToProps = (state: State) => {
    // console.log(state)
    return {
        users: state.commonInfo.users,
        wholeState: state
    }
}

const mapDispatchToProps = {
    setFilterWindowShow,
    setFilterWindowShowProjects
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterButton)