import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import {
  setShownTaskInfoPlate,
  setTaskInfoActiveTab,
  setProjectUsers,
  setComentList,
  setExecutorSchedule,
  fetchProjectSections,
  rewriteWholeTaskList,
  selectTask,
  setTaskName,
  setDefaultValues,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setFlagRepeatTaskToChange,
} from './actions';
import { Task, User, Comment, Schedule } from '../../common/types';
import Toggler, { ToggleElem } from '../../common/components/Togglers/Toggler';
import InfoTab from './components/InfoTab';
import CommentTab from './components/CommentTab';
import { getSelectedTask, getTaskInfoActiveTab } from './selectors';
import { State } from '../../rootReducer';
import { fetchData } from '../../utils/fetchData';
import './styles.sass';
import PrioritySelect from '../../common/components/Selects/PrioritySelect';
import { updateTask } from '../../common/actions';
import TaskStatus from '../../common/components/TaskStatus/TaskStatus';
import DateMaster from '../../utils/DateMaster';
import { TextareaAutosize } from '@material-ui/core';
import styled from 'styled-components';
import palette from '../../common/components/palette';
import Icon from '../../common/components/Icon';

type Props = {
  isShownTaskInfoPlate: boolean;
  selectedTask: Task | null;
  taskInfoActiveTab: string;
  taskName: string;
  users: User[];
  setShownTaskInfoPlate: (isShownTaskInfoPlate: boolean) => void;
  setTaskInfoActiveTab: (activeTab: string) => void;
  setProjectUsers: (users: User[]) => void;
  setComentList: (commentList: Comment[]) => void;
  updateTask: (id: number, params: any) => void;
  setExecutorSchedule: (schedule: Schedule) => void;
  fetchProjectSections: (projectId: number) => void;
  // changeCheckboxItem: (orderNumber: string | null, text: string | null, checkboxState: boolean) => void,
  rewriteWholeTaskList: (response: any) => void;
  currentUserId: number | undefined;
  currentUserTopRole: number | undefined;
  selectTask: (val: Task | null) => void;
  setTaskName: (name: string) => void;
  setDefaultValues: () => void;
  flagForActionCyclycTask: string;
  rememberObjectCyclicTaskToChange: {};
  sendReqToChangeTemplRepTask: (obj: {}) => void;
  setCyclicTaskToChange: (val: {}) => void;
  setFlagRepeatTaskToChange: (val: string) => void;
};

const togglerElems: ToggleElem[] = [
  {
    name: 'Информация',
    value: 'info',
  },
  {
    name: 'Комментарии',
    value: 'comments',
  },
  // ,{
  //     name: 'История',
  //     value: 'history'
  // }
];

const WrapperTaskInfoPlate = styled.div``;

const TaskInfoPlate: React.FC<Props> = (props) => {
  const {
    setShownTaskInfoPlate,
    setTaskInfoActiveTab,
    setProjectUsers,
    setComentList,
    updateTask,
    setExecutorSchedule,
    fetchProjectSections,
    selectedTask,
    users,
    taskInfoActiveTab,
    rewriteWholeTaskList,
    currentUserId,
    currentUserTopRole,
    selectTask,
    setTaskName,
    setDefaultValues,
    isShownTaskInfoPlate,
    taskName,
    flagForActionCyclycTask,
    rememberObjectCyclicTaskToChange,
    sendReqToChangeTemplRepTask,
    setCyclicTaskToChange,
    setFlagRepeatTaskToChange,
  } = props;

  const [className, setClassName] = useState('');
  const [titleStyle, setTitleStyle] = useState({});
  // const [TaskNameHook, setTaskNameHook] = useState(taskName);

  useEffect(() => {
    if (selectedTask) {
      setTitleStyle({
        background: 'white',
        borderColor: 'white',
        borderRadius: '6px',
        fontSize: '20px',
        color: '#212121',
        padding: '0',
        height: '50px',
      });
    } else {
      setTitleStyle({
        background: 'FFF',
        borderRadius: '6px',
        padding: '12px',
        height: '50px',
        color: '#212121',
      });
    }
  }, [selectedTask]);

  // при открытии должен ходить запрос на получение чеклиста. Изначально задача чеклист в себе не хранит.
  useEffect(() => {
    (async () => {
      if (flagForActionCyclycTask !== '') setFlagRepeatTaskToChange(''); // обнуляю определенное значение для подтверджения изменения повторящейся задачи.
      setTaskName('');

      if (selectedTask) setShownTaskInfoPlate(true);
      const taskId = selectedTask?.id;
      if (!taskId) return;
      let checklistForTask = await fetchData.get(
        `/api/v1/tasks/${taskId}/checklists`
      );
      if (checklistForTask) {
        // когда слишком много кликаешь с задачи на задачу, то выпадает ошибка в снекбаре too many attempts - и сервер вместо нормального ответа присылает undefined. Чтобы все не крашнулось, есть это условие.
        checklistForTask = Object.values(checklistForTask);
        rewriteWholeTaskList(checklistForTask);
      }
    })();
    // Do something
    setTaskName(selectedTask?.name || '');
  }, [selectedTask?.id]);

  useEffect(() => {
    // запрашивать список юзеров по проекту
    (async () => {
      const projectId = selectedTask?.project_id;
      if (!projectId) return;
      let usersForProject = await fetchData.get(
        `/api/v1/projects/${projectId}/users`
      );
      // console.log(usersForProject)
      if (usersForProject) {
        // когда слишком много кликаешь с задачи на задачу, то выпадает ошибка в снекбаре too many attempts - и сервер вместо нормального ответа присылает undefined. Чтобы все не крашнулось, есть это условие.
        usersForProject = Object.values(usersForProject);
        let usersList = users.filter((user) =>
          usersForProject.includes(user.id)
        );
        setProjectUsers(usersList);
      }
      setClassName('is_shown');
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedTask?.id, setProjectUsers, users])
  }, [setProjectUsers, users]);

  useEffect(() => {
    (async () => {
      const taskId = selectedTask?.id;
      if (!taskId) return;
      const commentList = await fetchData.get(
        `/api/v1/tasks/${taskId}/comments?order=created_at&page=1&orderType=desc`
      );
      if (commentList) {
        setComentList(commentList);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedTask!.id, setComentList])
  }, [setComentList]);

  useEffect(() => {
    (async () => {
      const executor_id = selectedTask?.executor_id;
      if (!executor_id) return;
      const data = await fetchData.get(`/api/v1/users/${executor_id}/schedule`);
      if (data?.schedule) {
        setExecutorSchedule(data.schedule);
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedTask) fetchProjectSections(selectedTask.project_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTask?.project_id]);

  const dateMaster = useMemo(() => {
    if (selectedTask) return new DateMaster(selectedTask?.end);
  }, [selectedTask]);

  // self-close for window
  const outsideClickHandler = useCallback(
    function (e: Event): any {
      let targetAsElement = e.target as Element;
      // thisIsTaskTr
      // for (let i = 0; i < targetAsElement.classList.length; i++) {
      //     if (targetAsElement.classList[i] === 'task_tr') thisIsTaskTr = true
      // }
      // console.log(targetAsElement.parentElement?.parentElement?.parentElement?.parentElement)

      // console.log(!targetAsElement.closest('.task_info_plate'), !targetAsElement.closest('.tasks_table_content'), !targetAsElement.closest('.MuiDialog-root'))
      // если у него нету родителя ".task_info_plate" и это не другой таск
      if (
        !targetAsElement.closest('.task_info_plate') &&
        !targetAsElement.closest('.tasks_table_content') &&
        !targetAsElement.closest('.MuiDialog-root')
      ) {
        // setTimeout(() => setShownTaskInfoPlate(false), 100)
      }
    },
    [setShownTaskInfoPlate]
  );

  useEffect(() => {
    document.addEventListener('click', outsideClickHandler);
    return () => document.removeEventListener('click', outsideClickHandler);
  }, [outsideClickHandler]);

  //  отслеживает подтвердждения изменений повторяющихся задач
  useEffect(() => {
    if (selectedTask && Object.keys(rememberObjectCyclicTaskToChange).length) {
      updateTask(selectedTask.id, rememberObjectCyclicTaskToChange);
      if (flagForActionCyclycTask !== 'task')
        sendReqToChangeTemplRepTask(rememberObjectCyclicTaskToChange);
      setCyclicTaskToChange({}); // скрыть окно с подтверждением действия
    }
  }, [flagForActionCyclycTask]);

  return (
    <WrapperTaskInfoPlate>
      <div
        className={`task_info_plate ${className} ${
          isShownTaskInfoPlate ? 'is_shown' : ''
        }`}
      >
        <div className="task_name">
          <Icon
            style={{ marginBottom: '8px' }}
            name="cross"
            onClick={() => {
              setDefaultValues();
              setShownTaskInfoPlate(false);
            }}
          />

          {/*disabled={currentUserId === selectedTask.author_id ? '' : (currentUserTopRole === 1 ? '' : 'disabled')}*/}

          <TextareaAutosize
            id={'rightPlate_name_textarea'}
            aria-label=""
            placeholder="Введите название"
            onChange={(e) => {
              setTaskName(e.currentTarget.value);
            }}
            className={
              currentUserId === selectedTask?.author_id
                ? ''
                : currentUserTopRole === 1
                ? ''
                : 'disabled'
            }
            style={titleStyle}
            // disabled={currentUserId === selectedTask.author_id ? false : (currentUserTopRole !== 1)}
            onBlur={(e) => {
              if (selectedTask) {
                // setEditDescMode(false)
                if (e.target.value !== selectedTask?.name) {
                  // если новое значение отличается от начального
                  // мгновенно обновляю значение для заколлапсированного состояния
                  // setRenewValueDesc(e.target.value)
                  // send value to Saga

                  if (selectedTask.cyclic_task_id !== 0) {
                    // эта задача вообще циклическая или сама по себе ?
                    // значит циклическая
                    if (flagForActionCyclycTask === '') {
                      setCyclicTaskToChange({ name: e.target.value }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                    } else if (flagForActionCyclycTask === 'task') {
                      updateTask(selectedTask!.id, { name: e.target.value });
                    } else {
                      updateTask(selectedTask!.id, { name: e.target.value });
                      sendReqToChangeTemplRepTask({ name: e.target.value });
                    }
                  } else {
                    updateTask(selectedTask!.id, { name: e.target.value });
                  }
                }
              } else setTaskName(e.target.value);
            }}
            value={taskName}
          />
        </div>

        {selectedTask && (
          <div className="tabs_desc">
            <div className={'first_row'}>
              <Toggler
                elemsList={togglerElems}
                selectHandler={(active) =>
                  setTaskInfoActiveTab(active as string)
                }
                activeValue={taskInfoActiveTab}
              />

              <div className={'iconsBar'}>
                <div className={'attachments'}>
                  <div className={'count'}>0</div>
                  <div className={'icon'} />
                </div>
                <div className={'comments'}>
                  <div className={'count'}>0</div>
                  <div className={'icon'} />
                </div>
                <div className={'bell'} />
              </div>

              {/*если статус в работе и дедлайн прошел - он подменяется на просрочено*/}
              {selectedTask && (
                <TaskStatus
                  id={
                    dateMaster &&
                    selectedTask.status_id === 10 &&
                    new Date(
                      dateMaster.year,
                      dateMaster.month,
                      dateMaster.day,
                      dateMaster.hours,
                      dateMaster.minutes
                    ).getTime() <= new Date(Date.now()).getTime()
                      ? 14
                      : selectedTask.status_id
                    // 14
                  }
                  taskId={selectedTask.id}
                  disabled={
                    currentUserId === selectedTask.author_id ||
                    currentUserId === selectedTask.executor_id
                      ? false
                      : currentUserTopRole !== 1
                  }
                />
              )}
            </div>
          </div>
        )}

        {taskInfoActiveTab === 'info' && <InfoTab />}
        {taskInfoActiveTab === 'comments' && <CommentTab />}
      </div>
    </WrapperTaskInfoPlate>
  );
};

const mapStateToProps = (state: State) => {
  // console.log(state.taskInfoPlate.selectedTask)
  return {
    currentUserId: state.commonInfo.currentUserInfo?.id,
    currentUserTopRole: state.commonInfo.currentUserInfo?.roles[0],
    selectedTask: state.taskInfoPlate.selectedTask,
    taskName: state.taskInfoPlate.name,
    users: state.commonInfo.users,
    taskInfoActiveTab: getTaskInfoActiveTab(state),
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
    rememberObjectCyclicTaskToChange:
      state.taskInfoPlate.rememberObjectCyclicTaskToChange,
  };
};

const mapDispatchToProps = {
  setShownTaskInfoPlate,
  setTaskInfoActiveTab,
  setProjectUsers,
  setComentList,
  updateTask,
  setExecutorSchedule,
  fetchProjectSections,
  rewriteWholeTaskList,
  selectTask,
  setTaskName,
  setDefaultValues,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setFlagRepeatTaskToChange,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(TaskInfoPlate);
