import React, { useState, useEffect, useCallback, useRef } from 'react';
import withTitle from '../../../utils/hoc/withTitle';
import { State } from '../../../rootReducer';
import { connect } from 'react-redux';
import InputWithUnit from '../../../common/components/Inputs/InputWithUnit';
import { updateTask } from '../../../common/actions';
import { calculateTaskLoad } from '../../../utils/calculateTaskLoad';
import { Task, Schedule, Item } from '../../../common/types';
import Toggler from '../../../common/components/Togglers/Toggler';
import SelectWithSecondaryBtn from '../../../common/components/Selects/SelectWithSecondaryBtn';
import { fetchData } from '../../../utils/fetchData';
import {
  sendReqToChangeTemplRepTask,
  setChildTasks,
  setCyclicTaskToChange,
  setNextId,
  setParentId,
  setPrevId,
} from '../actions';

type Props = {
  selectedTask: Task;
  schedule: Schedule | null;
  updateTask: (taskId: number, params: any, withNotif?: boolean) => void;
  prev_id: number | null;
  next_id: number | null;
  parent_id: number | null;
  child_tasks: number[];
  disabledComponent: boolean;
  project_id: number | null;
  setPrevId: (value: number | null) => void;
  setChildTasks: (value: any[]) => void;
  setNextId: (value: number | null) => void;
  setParentId: (value: number | null) => void;
  flagForActionCyclycTask: string;
  setCyclicTaskToChange: (obj: {}) => void;
  sendReqToChangeTemplRepTask: (obj: {}) => void;
};

const Dependencies: React.FC<Props> = ({
  selectedTask,
  schedule,
  updateTask,
  disabledComponent,
  prev_id,
  next_id,
  parent_id,
  child_tasks,
  project_id,
  setPrevId,
  setChildTasks,
  setNextId,
  setParentId,
  flagForActionCyclycTask,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
}) => {
  const [dependenciesActiveTab, setDependenciesActiveTab] = useState<string>(
    ''
  ); // пользователи, которые выбраны в чипсах
  const [tasksForProject, setTasksForProject] = useState<Item[]>([]); // это главное хранилище тасков по проекту. сюда суммируются другие локалстэйты.

  const [resultRequestTaskInfo, setResultRequestTaskInfo] = useState<Item[]>(
    []
  ); // это главное хранилище тасков по проекту. сюда суммируются другие локалстэйты.

  const [resultRequestProjectInfo, setResultRequestProjectInfo] = useState<
    Item[]
  >([]); // это главное хранилище тасков по проекту. сюда суммируются другие локалстэйты.

  const [page, setPage] = useState<number>(1);
  // const [valueSearch, setValueSearch] = useState('');
  let dependenciesInput;

  const DependenciesItem = ({ task, onClick }) => {
    return (
      <div className={'dependenciesItem'}>
        <div className={'content'}>{task?.text}</div>
        <div className={'deleteButton'} onClick={() => onClick(task.value)} />
      </div>
    );
  };

  const cleaning = async () => {
    await setTasksForProject([]);
    await setResultRequestProjectInfo([]);
    await setResultRequestTaskInfo([]);
  };

  const searchData = (valueSearch) => {
    if (valueSearch === '') {
      setPage(1);
      if (selectedTask) {
        cleaning();
        requestTasksByProjectInfo(selectedTask?.project_id);
      } else if (project_id) {
        cleaning();
        requestTasksByProjectInfo(project_id);
      }
    } else {
      project_id &&
        fetchData
          .get(
            `/api/v1/tasks?project_id=${project_id}${
              valueSearch && '&search=$'
            }${valueSearch && valueSearch}`
          )
          .then((data) => {
            let array = data.map((el) => {
              return { value: el.id, text: el.name };
            });
            setTasksForProject(array);
          });
    }
  };

  useEffect(() => {
    setPage(1);
    cleaning();
    setPrevId(null);
    setNextId(null);
    setChildTasks([]);
    setParentId(null);
    // очищать при переключении на другую задачу, где другой проект.
    if (page === 1) {
      if (selectedTask) {
        cleaning();
        requestTasksByProjectInfo(selectedTask?.project_id);
      } else if (project_id) {
        cleaning();
        requestTasksByProjectInfo(project_id);
      }
    }
  }, [project_id]);

  const requestTasksByProjectInfo = async (projectId) => {
    if (page === 1) {
      cleaning();
    }

    await fetchData
      .get(`/api/v1/tasks?project_id=${projectId}&page=${page}`)
      .then((data) => {
        if (page === 1) {
          cleaning();
          setResultRequestProjectInfo([
            ...data.map((item) => ({ value: item.id, text: item.name })),
          ]);
        } else {
          setResultRequestProjectInfo([
            ...resultRequestProjectInfo,
            ...data.map((item) => ({ value: item.id, text: item.name })),
          ]);
        }
      });
  };

  const requestTaskInfo = (taskId) => {
    fetchData.get(`/api/v1/tasks/${taskId}`).then((allInfoByTask) => {
      if (allInfoByTask)
        setResultRequestTaskInfo([
          { value: allInfoByTask.id, text: allInfoByTask.name },
        ]);
    });
  };

  useEffect(() => {
    if (tasksForProject.length === 20 * (page - 1)) {
      if (selectedTask) {
        requestTasksByProjectInfo(selectedTask?.project_id);
      } else if (project_id) {
        requestTasksByProjectInfo(project_id);
      }
    }
  }, [page]);

  useEffect(() => {
    if (selectedTask?.prev_id || selectedTask?.next_id) {
      if (tasksForProject.length) {
        if (
          selectedTask &&
          selectedTask.prev_id &&
          !tasksForProject.find((item) => item.value === selectedTask.prev_id)
        ) {
          // если айтем не найден среди тех тасков по проекту, что уже загружены
          requestTaskInfo(selectedTask.prev_id);
        }
        if (
          selectedTask &&
          selectedTask.next_id &&
          !tasksForProject.find((item) => item.value === selectedTask.next_id)
        ) {
          // если айтем не найден среди тех тасков по проекту, что уже загружены
          requestTaskInfo(selectedTask.next_id);
        }
      } else {
        if (selectedTask?.next_id) requestTaskInfo(selectedTask?.next_id);
        else if (selectedTask?.prev_id) requestTaskInfo(selectedTask?.prev_id);
      }
    }
  }, [selectedTask?.id]);

  useEffect(() => {
    if (page === 1) {
      setTasksForProject([]);
    }
    // этот эффект собирает данные в 1 стэйт по 2м стэйтам - resultRequestTaskInfo и resultRequestProjectInfo
    const buffer = [];

    resultRequestTaskInfo.forEach((second) => {
      let match = false;
      tasksForProject.forEach((main) => {
        if (main.value === second.value) match = true;
      });
      if (!match) {
        // @ts-ignore
        buffer.push(second);
      }
    });

    resultRequestProjectInfo.forEach((second) => {
      let match = false;
      tasksForProject.forEach((main) => {
        if (main.value === second.value) match = true;
      });
      if (!match) {
        // @ts-ignore
        buffer.push(second);
      }
    });

    setTasksForProject([...tasksForProject, ...buffer]);
  }, [resultRequestTaskInfo, resultRequestProjectInfo, page]);

  ///////////////
  useEffect(() => {
    // если selectedTask?.prev_id есть, а метаданных по нему в листе тасков еще нет
    if (selectedTask?.prev_id) {
      let found_item = tasksForProject.find(
        (item) => item.value === selectedTask?.prev_id
      );
      if (!found_item) requestTaskInfo(selectedTask?.prev_id);
    }
  }, [selectedTask?.prev_id]);

  useEffect(() => {
    // если selectedTask?.next_id есть, а метаданных по нему в листе тасков еще нет
    if (selectedTask?.next_id) {
      let found_item = tasksForProject.find(
        (item) => item.value === selectedTask?.next_id
      );
      if (!found_item) requestTaskInfo(selectedTask?.next_id);
    }
  }, [selectedTask?.next_id]);

  useEffect(() => {
    // если selectedTask?.parent_id есть, а метаданных по нему в листе тасков еще нет
    if (selectedTask?.parent_id) {
      let found_item = tasksForProject.find(
        (item) => item.value === selectedTask?.parent_id
      );
      if (!found_item) requestTaskInfo(selectedTask?.parent_id);
    }
  }, [selectedTask?.parent_id]);

  useEffect(() => {
    // если selectedTask?.child_tasks есть, а метаданных по нему в листе тасков еще нет
    if (selectedTask?.child_tasks?.length) {
      selectedTask?.child_tasks.forEach((child_task) => {
        let found_item = tasksForProject.find(
          (item) => item.value === child_task
        );
        if (!found_item) requestTaskInfo(child_task);
      });
    }
  }, [selectedTask?.child_tasks]);
  //////////////

  useEffect(() => {}, [project_id]);

  return (
    <div className={'dependencies'}>
      <div className={'firstRow'}>
        <Toggler
          elemsList={[
            { name: 'Предыдущая', value: 'Предыдущая' },
            { name: 'Следующая', value: 'Следующая' },
            { name: 'Родительская', value: 'Родительская' },
            { name: 'Дочерняя', value: 'Дочерняя' },
          ]}
          selectHandler={(active) => {
            setDependenciesActiveTab(active as string);
          }}
          activeValue={dependenciesActiveTab}
        />
      </div>

      {['Предыдущая', 'Следующая', 'Родительская', 'Дочерняя'].indexOf(
        dependenciesActiveTab
      ) !== -1 &&
        ((dependenciesActiveTab === 'Предыдущая' &&
          !(prev_id || selectedTask?.prev_id)) ||
          (dependenciesActiveTab === 'Следующая' &&
            !(next_id || selectedTask?.next_id)) ||
          (dependenciesActiveTab === 'Родительская' &&
            !(parent_id || selectedTask?.parent_id)) ||
          (dependenciesActiveTab === 'Дочерняя' &&
            !(child_tasks?.length || selectedTask?.child_tasks?.length))) &&
        !disabledComponent && (
          <div id="dependencies" className={'secondRow'}>
            <SelectWithSecondaryBtn
              setPage={setPage}
              searchData={searchData}
              page={page}
              list={tasksForProject}
              selected={null}
              disabled={disabledComponent || !tasksForProject.length}
              selectHandler={(value) => {
                switch (dependenciesActiveTab) {
                  case 'Предыдущая':
                    if (selectedTask) {
                      if (selectedTask.cyclic_task_id !== 0) {
                        // эта задача вообще циклическая или сама по себе ?
                        // значит циклическая
                        if (flagForActionCyclycTask === '') {
                          setCyclicTaskToChange({ prev_id: value }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                        } else if (flagForActionCyclycTask === 'task') {
                          updateTask(selectedTask.id, { prev_id: value });
                        } else {
                          updateTask(selectedTask.id, { prev_id: value });
                          sendReqToChangeTemplRepTask({ prev_id: value });
                        }
                      } else {
                        updateTask(selectedTask.id, { prev_id: value });
                      }
                    } else setPrevId(value as number);
                    break;
                  case 'Следующая':
                    if (selectedTask) {
                      if (selectedTask.cyclic_task_id !== 0) {
                        // эта задача вообще циклическая или сама по себе ?
                        // значит циклическая
                        if (flagForActionCyclycTask === '') {
                          setCyclicTaskToChange({ next_id: value }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                        } else if (flagForActionCyclycTask === 'task') {
                          updateTask(selectedTask.id, { next_id: value });
                        } else {
                          updateTask(selectedTask.id, { next_id: value });
                          sendReqToChangeTemplRepTask({ next_id: value });
                        }
                      } else {
                        updateTask(selectedTask.id, { next_id: value });
                      }

                      updateTask(selectedTask.id, { next_id: value });
                    } else setNextId(value as number);
                    break;
                  case 'Родительская':
                    if (selectedTask) {
                      if (selectedTask.cyclic_task_id !== 0) {
                        // эта задача вообще циклическая или сама по себе ?
                        // значит циклическая
                        if (flagForActionCyclycTask === '') {
                          setCyclicTaskToChange({ parent_id: value }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                        } else if (flagForActionCyclycTask === 'task') {
                          updateTask(selectedTask.id, { parent_id: value });
                        } else {
                          updateTask(selectedTask.id, { parent_id: value });
                          sendReqToChangeTemplRepTask({ parent_id: value });
                        }
                      } else {
                        updateTask(selectedTask.id, { parent_id: value });
                      }
                    } else setParentId(value as number);
                    break;
                  case 'Дочерняя':
                    if (selectedTask) {
                      if (selectedTask.cyclic_task_id !== 0) {
                        // эта задача вообще циклическая или сама по себе ?
                        // значит циклическая
                        if (flagForActionCyclycTask === '') {
                          setCyclicTaskToChange({
                            child_tasks: [...child_tasks, value as number],
                          }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                        } else if (flagForActionCyclycTask === 'task') {
                          updateTask(selectedTask.id, {
                            child_tasks: [...child_tasks, value as number],
                          });
                        } else {
                          updateTask(selectedTask.id, {
                            child_tasks: [...child_tasks, value as number],
                          });
                          sendReqToChangeTemplRepTask({
                            child_tasks: [...child_tasks, value as number],
                          });
                        }
                      } else {
                        updateTask(selectedTask.id, {
                          child_tasks: [...child_tasks, value as number],
                        });
                      }
                    } else setChildTasks([...child_tasks, value as number]);
                    break;
                  default:
                    break;
                }
              }}
            />
          </div>
        )}

      {dependenciesActiveTab !== '' && (
        <div className={'thirdRow'}>
          {dependenciesActiveTab === 'Предыдущая' &&
            (prev_id || selectedTask?.prev_id) && (
              <DependenciesItem
                task={tasksForProject.find(
                  (item) =>
                    item.value ===
                    (selectedTask?.prev_id ? selectedTask?.prev_id : prev_id)
                )}
                onClick={(taskId) => {
                  if (selectedTask) {
                    if (selectedTask.cyclic_task_id !== 0) {
                      // эта задача вообще циклическая или сама по себе ?
                      // значит циклическая
                      if (flagForActionCyclycTask === '') {
                        setCyclicTaskToChange({ prev_id: null }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                      } else if (flagForActionCyclycTask === 'task') {
                        updateTask(selectedTask.id, { prev_id: null });
                      } else {
                        updateTask(selectedTask.id, { prev_id: null });
                        sendReqToChangeTemplRepTask({ prev_id: null });
                      }
                    } else {
                      updateTask(selectedTask.id, { prev_id: null });
                    }
                  } else setPrevId(null);
                }}
              />
            )}

          {dependenciesActiveTab === 'Следующая' &&
            (next_id || selectedTask?.next_id) && (
              <DependenciesItem
                task={tasksForProject.find(
                  (item) =>
                    item.value ===
                    (selectedTask?.next_id ? selectedTask?.next_id : next_id)
                )}
                onClick={() => {
                  if (selectedTask) {
                    if (selectedTask.cyclic_task_id !== 0) {
                      // эта задача вообще циклическая или сама по себе ?
                      // значит циклическая
                      if (flagForActionCyclycTask === '') {
                        setCyclicTaskToChange({ next_id: null }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                      } else if (flagForActionCyclycTask === 'task') {
                        updateTask(selectedTask.id, { next_id: null });
                      } else {
                        updateTask(selectedTask.id, { next_id: null });
                        sendReqToChangeTemplRepTask({ next_id: null });
                      }
                    } else {
                      updateTask(selectedTask.id, { next_id: null });
                    }
                  } else setNextId(null);
                }}
              />
            )}

          {dependenciesActiveTab === 'Родительская' &&
            (parent_id || selectedTask?.parent_id) && (
              <DependenciesItem
                task={tasksForProject.find(
                  (item) =>
                    item.value ===
                    (selectedTask?.parent_id
                      ? selectedTask?.parent_id
                      : parent_id)
                )}
                onClick={() => {
                  if (selectedTask) {
                    if (selectedTask.cyclic_task_id !== 0) {
                      // эта задача вообще циклическая или сама по себе ?
                      // значит циклическая
                      if (flagForActionCyclycTask === '') {
                        setCyclicTaskToChange({ parent_id: null }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                      } else if (flagForActionCyclycTask === 'task') {
                        updateTask(selectedTask.id, { parent_id: null });
                      } else {
                        updateTask(selectedTask.id, { parent_id: null });
                        sendReqToChangeTemplRepTask({ parent_id: null });
                      }
                    } else {
                      updateTask(selectedTask.id, { parent_id: null });
                    }
                  } else setParentId(null);
                }}
              />
            )}

          {dependenciesActiveTab === 'Дочерняя' &&
            (selectedTask?.child_tasks
              ? selectedTask?.child_tasks.map((taskId) => {
                  return (
                    <DependenciesItem
                      task={{
                        text: 'название дочерней задачи ' + taskId,
                        value: taskId,
                      }}
                      onClick={(taskId) => {
                        if (selectedTask?.child_tasks?.length) {
                          let found_item = selectedTask?.child_tasks?.findIndex(
                            (item) => item === taskId
                          );
                          if (found_item) {
                            let copied_array = selectedTask?.child_tasks;
                            copied_array.splice(found_item, 1);

                            if (selectedTask.cyclic_task_id !== 0) {
                              // эта задача вообще циклическая или сама по себе ?
                              // значит циклическая
                              if (flagForActionCyclycTask === '') {
                                setCyclicTaskToChange({
                                  child_tasks: copied_array,
                                }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                              } else if (flagForActionCyclycTask === 'task') {
                                updateTask(taskId, {
                                  child_tasks: copied_array,
                                });
                              } else {
                                updateTask(taskId, {
                                  child_tasks: copied_array,
                                });
                                sendReqToChangeTemplRepTask({
                                  child_tasks: copied_array,
                                });
                              }
                            } else {
                              updateTask(taskId, { child_tasks: copied_array });
                            }
                          }
                        }
                      }}
                    />
                  );
                })
              : child_tasks.map((taskId) => {
                  return (
                    <DependenciesItem
                      key={taskId}
                      task={{
                        text: 'название дочерней задачи ' + taskId,
                        value: taskId,
                      }}
                      onClick={(taskId) => {
                        let found_item = child_tasks.findIndex(
                          (item) => item === taskId
                        );
                        if (found_item !== -1) {
                          let copied_array = child_tasks.map((item) => item);
                          copied_array.splice(found_item, 1);
                          setChildTasks(copied_array);
                        }
                      }}
                    />
                  );
                }))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  selectedTask: state.taskInfoPlate.selectedTask!,
  schedule: state.taskInfoPlate.executorSchedule,

  project_id: state.taskInfoPlate.project_id,

  child_tasks: state.taskInfoPlate.child_tasks,
  prev_id: state.taskInfoPlate.prev_id,
  next_id: state.taskInfoPlate.next_id,
  parent_id: state.taskInfoPlate.parent_id,
  flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
});

const mapDispatchToProps = {
  updateTask,
  setPrevId,
  setNextId,
  setParentId,
  setChildTasks,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dependencies);
