import React, { useEffect, useState } from 'react';
import UsersActions from './components/UsersActions';
import UsersTable from './components/UsersTable';
import DepGroupPlate from './components/DepGroupPlate';
import { fetchUsers, openDeps, setUserAddMode } from './actions';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { State } from '../../rootReducer';
import { User } from '../../common/types';
import { openExistingUser } from '../NewUser/actions';
// import {getShowingDepGroupPlate} from "./selectors";
// import PriorityToggler from "../../common/components/Togglers/PriorityToggler";
// import NameDescriptionTextField from "../../common/components/Inputs/NameDescriptionTextField";
// import ProjectSelect from "../NewTask/components/ProjectSelect";
// import SectionSelect from "../NewTask/components/SectionSelect";
// import ExecutorSelect from "../NewTask/components/ExecutorSelectWithDelegate";
// import Author from "../NewTask/components/Author";
// import TaskBegin from "../NewTask/components/TaskBegin";
// import TaskEnd from "../NewTask/components/TaskEnd";
// import TaskWorkLoad from "../NewTask/components/TaskWorkLoad";
// import QualitySpeedToggler from "../NewTask/components/QualitySpeedToggler";
// import TaskAddictions from "../NewTask/components/TaskAddictions";
import styled from 'styled-components';

type Props = {
  users: User[];
  fetchUsers: (pageNum: number) => void;
  openExistingUser: (user: User) => void;
  setUserAddMode: (mode: boolean) => void;
  // showDepGroupPlate: boolean,
  openDeps: () => void;
};

const WrapperTableUser = styled.div`
  display: flex;
`;
const BlockUsersStyle = styled.div`
  min-width: calc(100vw - 304px);
  width: 100%;
`;

const Users: React.FC<Props> = ({
  openDeps,
  users,
  fetchUsers,
  openExistingUser,
  setUserAddMode,
}) => {
  const { id } = useParams();
  const [firstRender, setFirstRender] = useState<boolean>(true);

  if (firstRender) {
    setFirstRender(false);
    openDeps();
  }

  useEffect(() => {
    fetchUsers(1);
  }, [fetchUsers]);

  useEffect(() => {
    if (id && users.length > 0) {
      let user = users.find((u) => u.id === +id);
      if (user) {
        openExistingUser(user);
      }
      setUserAddMode(true);
    }
  }, [id, openExistingUser, setUserAddMode, users]);

  return (
    <WrapperTableUser
    //   className="user_table_wrapper"
    >
      <DepGroupPlate />
      <BlockUsersStyle>
        <UsersActions />
        <UsersTable />
      </BlockUsersStyle>
    </WrapperTableUser>
  );
};

const mapStateToProps = (state: State) => {
  // console.log(state)
  return {
    // showDepGroupPlate: getShowingDepGroupPlate(state),
    users: state.commonInfo.users,
  };
};

const mapDispatchToProps = {
  fetchUsers,
  openExistingUser,
  setUserAddMode,
  openDeps,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
