import React, { useState, useEffect, useCallback } from 'react';


type Props = {
    defaultValue: number
    unit: string
    changeHandler: (value: string) => void
    className?: string,
    disabled?: boolean
}




const InputWithUnit: React.FC<Props> = ({ unit, changeHandler, defaultValue, className, disabled }) => {

    const [value, setValue] = useState<string>('')
    const [isFocused, setFocused] = useState<boolean>(false)

    useEffect(() => {
        if (defaultValue) setValue(defaultValue.toString())
    }, [defaultValue])

    const onChange = useCallback(e => {
        let string = e.currentTarget.value.replace(/[^\d]/g, '')
        setValue(string)
    }, [])

    const onFocus = useCallback(() => {
        setFocused(true)
    }, [])

    const onBlur = useCallback(() => {
        changeHandler(value)
        // setValue(defaultValue.toString())
        setFocused(false)
    }, [changeHandler, value])

    return (
        <div className={`input_with_unit ${className ? className : ''}${isFocused ? ' is_focused' : ''}${disabled ? ' disabled' : ''}`}>
            <div className="input_wrapper">
                <input
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={value}
                />
            </div>
            <div className="unit">{unit}</div>
        </div>
    )
}



export default InputWithUnit