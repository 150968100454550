import React, { useState, useCallback, useEffect, useRef } from 'react';
import UserTr from './UserTr';
import { User } from '../../../common/types';
import { State } from '../../../rootReducer';
import { connect } from 'react-redux';
import { fetchUsers } from '../actions';
import { throttle } from '../../../utils/throttle';
import styled from 'styled-components';

type Props = {
  userList: User[];
};

const UsersTableContent: React.FC<Props> = ({ userList }) => {
  return (
    <
      //   className={`table_content users_table_content${
      //     selectedDepGroupId ? ' is_dep_group' : ''
      //   }`}
    >
      {userList.map((user) => (
        <UserTr key={user.id} user={user} />
      ))}
    </>
  );
};

const mapStateToProps = (state: State) => ({
  userList: state.usersPage.depGroupUsers,
});

export default connect(mapStateToProps)(UsersTableContent);
