import React, {useState, useEffect, useCallback, useRef, createRef, useMemo} from 'react'
import {Item} from '../../../common/types'
import {connect} from 'react-redux'
import {State} from '../../../rootReducer'
import {setFilterWindowShow, setFilters, setDraftFilter, setAddNewRow, ActualToggleAction} from '../actions'
import SelectWithSecondaryBtn from "../../../common/components/Selects/SelectWithSecondaryBtn"
import ClearIcon from '@material-ui/icons/Clear'
import AddRoundedIcon from '@material-ui/icons/AddRounded'

type Props = {
    // tasks: Task[],
    // tableFilter: string,
    // fetchTasks: (projectId: number, pageNum: number) => void
    setFilters: any,
    setFilterWindowShow: (show: boolean) => void,
    statuses: any,
    tasks_list: object[],
    TypesItemsList: any,
    filters: Object,
    setDraftFilter: any,
    filterDraft: number,
    filterAddNewRow: boolean,
    exec_auth: string,
    setAddNewRow: (value: boolean) => void,
    ActualToggleAction: (value: object) => void,
}

const FilterRow = ({TypesItemsList, ValuesItemsList, TypeId, ValueId, lastRow, setFiltersAction, setDraftAction, setAddNewRowAction}) => {

    const FilteredParameter = useMemo(() => {
        let selected: Item | null = null
        TypesItemsList.forEach((item) => {
            if (item.value === TypeId) selected = item
        })
        return {selected, TypesItemsList}
    }, [TypeId])

    const FilteredParameterValue = useMemo(() => {
        let selected: Item | null = null
        ValuesItemsList.forEach((item) => {
            if (item.value === ValueId) selected = item
        })
        return {selected, ValuesItemsList}
    }, [ValueId, TypeId])

    return (
        <div className={'container_filter_row'}>
            <div className={'filter_key'}>
                <SelectWithSecondaryBtn
                    list={FilteredParameter.TypesItemsList}
                    selected={FilteredParameter.selected}
                    selectHandler={(value) => {
                        setDraftAction(value)
                    }}
                    disabled={!!FilteredParameterValue.selected}
                />
            </div>
            <div className={'filter_value'}>
                {FilteredParameter.selected && <SelectWithSecondaryBtn
                    list={FilteredParameterValue.ValuesItemsList}
                    selected={FilteredParameterValue.selected}
                    selectHandler={(value) => {
                        setFiltersAction(TypeId, value, ValueId)
                    }}
                />}
            </div>

            {lastRow && FilteredParameterValue.selected &&
            <div className={'addNewFilterButton'} onClick={(e) => {
                setAddNewRowAction(true)
            }}>
                <AddRoundedIcon/>
            </div>}

        </div>
    )
}


const ProjectTableContent: React.FC<Props> = ({tasks_list, statuses, setFilters, TypesItemsList, filters, setFilterWindowShow, setDraftFilter, filterDraft, filterAddNewRow, setAddNewRow, ActualToggleAction, exec_auth}) => {

    // формат статусов не подходит под тип Item, использующийся в компонентах SelectWithSecondaryBtn, поэтому переконвертирую.
    statuses = statuses.map((statusItem) => ({text: statusItem.name, value: statusItem.id}))

    // self-close for window
    let plate = useRef<HTMLDivElement>(null)
    const outsideClickHandler = useCallback(function (e: Event): any {
        if (document.getElementsByClassName('filter_plate')[0] && document.contains(e.target as Node) && !plate.current?.contains(e.target as Node)) {
            setFilterWindowShow(false)
        }
    }, [setFilterWindowShow])
    useEffect(() => {
        document.addEventListener('click', outsideClickHandler)
        return () => document.removeEventListener('click', outsideClickHandler)
    }, [outsideClickHandler])
    // self-close for window

    let cloneFilters = {}; // независимый клонированный объект filters
    for (let key in filters) {
        cloneFilters[key] = []
        filters[key].forEach((each_val) => {
            cloneFilters[key].push(each_val)
        })
    }

    // для draft
    let draftItemsList = Object.keys(statuses).map((stat) => {
        return statuses[stat]
    })

    switch (filterDraft) {
        case 1:
            if (Object.keys(filters).length) {
                cloneFilters[1].forEach((valueId) => {
                    Object.keys(draftItemsList).forEach((statusKey) => {
                        if (draftItemsList[statusKey]['value'] === valueId) {
                            delete draftItemsList[statusKey]
                        }
                    })
                })
            }
            break
        default:
            draftItemsList = []
    }

    // нужно еще раз клонировать все фильтры статусов, чтобы была возможность их изменять.
    // console.log(statuses, cloneFilters)
    const appliedFiltersKeys = Object.keys(cloneFilters)

    return (
        <div className={'filter_plate'}
             ref={plate}>
            <div className={'container_headline_filter'}>
                <div className={'contents_key_headline'}>Задачи</div>
                <div className={'contents_value_headline'}>
                    <div className={exec_auth === '' ? '' : 'active'} onClick={function (event) {
                        ActualToggleAction({erase_applied_filters: true, exec_auth_value: 'executor'})
                    }}>Все
                    </div>
                    <div className={exec_auth === '' ? 'active' : ''} onClick={function (event) {
                        ActualToggleAction({erase_applied_filters: true, exec_auth_value: ''})
                    }}>Актуальные
                    </div>
                </div>
            </div>

            {/*беру каждый из видов фильтров*/}

            <div className={'filter_rows'}>
                {Boolean(appliedFiltersKeys.length) && appliedFiltersKeys.map((item) => {
                    //  эта переменная отображает, последняя ли строка сейчас строится (для добавления кнопки + в конце)
                    // let lastRow: boolean = appliedFiltersKeys[appliedFiltersKeys.length - 1] === item

                    // прохожу циклом по всему массиву значений, если оно не одно
                    return (
                        cloneFilters[item].map((valueItem) => {

                            // индивидуальный лист доступных значений для каждой строки примененного фильтра
                            let individualItemsList = Object.keys(statuses).map((stat) => {
                                return statuses[stat]
                            })

                            switch (item) {
                                case '1':
                                    filters[1].forEach((valueId) => {
                                        Object.keys(individualItemsList).forEach((statusKey) => {
                                            if ((individualItemsList[statusKey]['value'] === valueId) && (individualItemsList[statusKey]['value'] !== valueItem)) {
                                                delete individualItemsList[statusKey]
                                            }
                                        })
                                    })
                                    break
                            }

                            // последнее ли это значение в текущем фильтре
                            let lastRow: boolean = (cloneFilters[item][cloneFilters[item].length - 1] === valueItem) && (!filterDraft) && (!filterAddNewRow)
                            // console.log(parseInt(item), valueItem)
                            return (
                                // новая строка с указанными фильтрами
                                <FilterRow TypesItemsList={TypesItemsList}
                                           ValuesItemsList={individualItemsList}
                                           TypeId={parseInt(item)}
                                           ValueId={valueItem}
                                           lastRow={lastRow}
                                           setFiltersAction={(key, value, oldValue) => {
                                               // ты перевыбираешь значение
                                               if (appliedFiltersKeys.length) {
                                                   appliedFiltersKeys.forEach((eachKey) => {
                                                       if (parseInt(eachKey) === key) {
                                                           if (cloneFilters[key].includes(oldValue)) {
                                                               // меняем значение
                                                               cloneFilters[key].splice(cloneFilters[key].indexOf(oldValue), 1, value)
                                                           }
                                                       }
                                                   })
                                               } else {
                                                   cloneFilters[key] = [value]
                                               }

                                               setFilters(cloneFilters)
                                           }}
                                           setDraftAction={setDraftFilter}
                                           setAddNewRowAction={setAddNewRow}
                                           key={item + '-' + valueItem}
                                />
                            )
                        })
                    )
                })}

                {(filterAddNewRow || Boolean(filterDraft)) && <FilterRow TypesItemsList={TypesItemsList}
                                                                         ValuesItemsList={draftItemsList}
                                                                         TypeId={filterDraft ? filterDraft : null}
                                                                         ValueId={null}
                                                                         lastRow={false}
                                                                         setFiltersAction={(key, value) => {
                                                                             // console.log(appliedFiltersKeys)
                                                                             if (appliedFiltersKeys.length) {
                                                                                 appliedFiltersKeys.forEach((eachKey) => {
                                                                                     // это тип "статус"
                                                                                     // console.log(eachKey, key)
                                                                                     if (parseInt(eachKey) === key) {
                                                                                         // console.log(cloneFilters[key], value)
                                                                                         if (cloneFilters[key].includes(value)) {
                                                                                             // console.log('такой уже есть')
                                                                                         }
                                                                                         cloneFilters[key].push(value)
                                                                                     }
                                                                                 })
                                                                             } else {
                                                                                 cloneFilters[key] = [value]
                                                                             }
                                                                             // console.log(key, value, cloneFilters)
                                                                             setFilters(cloneFilters)
                                                                         }}
                                                                         setDraftAction={setDraftFilter}
                                                                         setAddNewRowAction={setAddNewRow}
                />}

            </div>


            <div className={'applied_filters'}>
                {Boolean(appliedFiltersKeys.length) && appliedFiltersKeys.map((item) => {
                    let ValuesItemsList
                    switch (parseInt(item)) {
                        case 1:
                            ValuesItemsList = statuses
                            break
                    }

                    return (
                        cloneFilters[item].map((valueItem) => {
                            let showing_filter_name
                            TypesItemsList.forEach((ListItem) => {
                                if (ListItem.value === parseInt(item)) showing_filter_name = ListItem.text
                            })

                            let showing_filter_value
                            ValuesItemsList.forEach((ListItem) => {
                                if (ListItem.value === parseInt(valueItem)) showing_filter_value = ListItem.text
                            })

                            return (
                                <div data-filter_id={item} data-filter_value={valueItem} key={valueItem}
                                     id={'filter_panel-' + item + '-' + valueItem} className={'applied_filter_item'}>

                                    <div className={'filter_panel_name'}>
                                        {showing_filter_name + ' - ' + showing_filter_value}
                                    </div>

                                    <ClearIcon onClick={function (e) {
                                        const parent = e.currentTarget.parentElement
                                        const gotParams = {
                                            param: parent?.getAttribute('data-filter_id'),
                                            value: parent?.getAttribute('data-filter_value')
                                        }
                                        // удаляю искомое значение в объекте примененных фильтров
                                        appliedFiltersKeys.forEach((eachKey) => {
                                            if (eachKey === gotParams.param) {
                                                cloneFilters[eachKey].forEach((deletable_value) => {
                                                    if (deletable_value.toString() === gotParams.value) {
                                                        cloneFilters[eachKey].splice(cloneFilters[eachKey].indexOf(deletable_value), 1)
                                                        // если значений по этому типу фильтра в массиве больше нет, то ключ удаляю
                                                        if (!cloneFilters[eachKey].length) delete cloneFilters[eachKey]
                                                    }
                                                })
                                            }
                                        })
                                        if (!Object.keys(cloneFilters).length) setAddNewRow(true)

                                        setFilters(cloneFilters)
                                    }
                                    }/>
                                </div>)
                        })
                    )
                })}
            </div>

        </div>
    )
}


const mapStateToProps = (state: State) => {
    return {
        filters: state.tasksPage.filters.appliedFilters,
        TypesItemsList: state.tasksPage.filters.filterParametersList,
        filterDraft: state.tasksPage.filters.draft,
        filterAddNewRow: state.tasksPage.filters.addNewRow,
        exec_auth: state.tasksPage.filters.exec_auth,
        tasks_list: state.tasksPage.tasks,
        statuses: state.commonInfo.statuses,
        tableFilter: state.projectsPage.tableFilter.toLocaleLowerCase()
    }
}

const mapDispatchToProps = {
    // setFilters посылает весь модифицированный объект фильтров целиком, а не модифицирует какой то один.
    setFilters,
    setFilterWindowShow,
    setDraftFilter,
    setAddNewRow,
    ActualToggleAction
}


export default connect(mapStateToProps, mapDispatchToProps)(ProjectTableContent)