import {types, TaskInfoPlateAction} from './action_types'
import {Task, User, Comment, File as FileType, Schedule, Section, Item} from '../../common/types'
import {Reducer} from 'redux'
import {strict} from "assert";

export const begin = dateFormatUTC(Date.now())
const end = dateFormatUTC(Date.now() + 3600000);

export function dateFormatUTC(arg) {
    let date = new Date(arg);
    let dateNumber: any = date.getDate();
    let month: any = date.getMonth() + 1; // because numeration of month starts from 0
    let hours: any = date.getHours();
    let minutes: any = date.getMinutes();
    let seconds: any = date.getSeconds();
    if (month < 10) month = '0' + month;
    if (dateNumber < 10) dateNumber = '0' + dateNumber;
    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    if (seconds < 10) seconds = '0' + seconds;

    return (
        dateNumber +
        '-' +
        month +
        '-' +
        date.getFullYear() +
        ' ' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds
    );
}

const initialState = {
    isShownTaskInfoPlate: false,
    selectedTask: null as Task | null,
    executor_id: null as number | null,
    name: '',
    description: '',
    begin: begin,
    end: end,
    taskInfoActiveTab: 'info',
    projectUsers: [] as User[],
    projectSections: [] as Section[],
    commentList: [] as Comment[],
    commentsLimit: 20,
    pageNum: 1,
    commentFiles: [] as FileType[],
    text: '',
    workflow_id: 1,
    priority_id: 2,
    provide_to: 0,
    task_load: 1 as any,
    work_load: 100 as any,
    project_section_id: null as number | null,
    project_id: null as number | null,
    parentComment: null as Comment | null,
    isSendingComment: false,
    isSendingDelegate: false,
    isSendingCreateNewTasks: false,
    executorSchedule: null as Schedule | null,
    delegateTo: null as number | null,
    checkList: [],  // у айтема чеклиста есть текст и состояние булево. После того, как он отправлен на сервер, у него появляется 3й параметр - id
    delegated: false,
    taskWorkLoadSwitcher: 'task_load',

    prev_id: null as number | null,
    next_id: null as number | null,
    parent_id: null as number | null,
    child_tasks: [] as any[],

    successfulCreatedTasks: {},

    //////// edit cyclic task
    rememberObjectCyclicTaskToChange: {} as any,    // храним объект со значениями, которые нужно поменять, когда всплывает модальное окно подтверждения изменения
    flagForActionCyclycTask: '', // может иметь 3 значения - '', 'task', 'taskAndCyclic'
    ////////

    repeat: {
        period: '', // '', 'every day', 'every week', 'every month', 'settings'
        params: [] as number[], // дни недели ИЛИ числа месяца
        interval: 1, // повторять каждые N недели и т.д. (1 по умолчанию)
        ending_condition: 'never',  // 'after count', 'after date'
        end_count: null as number | null,
        end_date: null as string | null,
        show_modal_window: false,
        settings_string: ''
    },
    parameter_trigger: true // просто какой то параметр, который служит как триггер у useEffect, когда нужно вызвать действие из саги руками.
    // successfulDelegatedTo: [] as any[]  // этот параметр используется только для делегирования задач пользователям. Компонент ExecutorSelectWithDelegate
}

export type TaskInfoPlateState = typeof initialState


const reducer: Reducer<TaskInfoPlateState, TaskInfoPlateAction> = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_DEFAULT_VALUES:
            return {
                ...initialState,
                isShownTaskInfoPlate: true
            }
        case types.SET_TASK_NAME:
            return {
                ...state,
                name: action.name,
            };
        case types.SET_TASK_DESCRIPTION:
            return {
                ...state,
                description: action.description,
            }
        case types.SET_TASK_PROJECT:
            return {
                ...state,
                project_id: action.projectId,
            };
        case types.SET_TASK_SECTION:
            return {
                ...state,
                project_section_id: action.id,
            }
        case types.SET_TASK_PRIORITY:
            return {
                ...state,
                priority_id: action.priority,
            }
        case types.SET_PROVIDE_TO:
            return {
                ...state,
                provide_to: action.val,
            }

        case types.SET_TASK_WORKLOAD:
            return {
                ...state,
                work_load: action.workLoad,
            }
        case types.SET_TASKLOAD:
            return {
                ...state,
                task_load: action.taskLoad,
            }

        case types.SET_SHOWN_TASK_INFO_PLATE:
            return {
                ...state,
                isShownTaskInfoPlate: action.isShownTaskInfoPlate,
                // commentList: action.isShownTaskInfoPlate ? state.commentList : [],
                commentList: [],
                // checkList: action.isShownTaskInfoPlate ? state.checkList : []
                checkList: []
            }
        case types.GET_COMMENTS_LIST:
            return {
                ...state,
                pageNum: action.pageNum
            }
        case types.SELECT_TASK:
            // console.log(action)
            return {
                ...state,
                selectedTask: action.task,
                delegateTo: null,
                commentList: [...state.commentList],
                commentsLimit: initialState.commentsLimit,
                pageNum: initialState.pageNum,
                // isShownTaskInfoPlate: action.task ? true : state.isShownTaskInfoPlate
            }
        case types.SET_TASK_INFO_ACTIVE_TAB:
            return {
                ...state,
                taskInfoActiveTab: action.activeTab
            }
        case types.SET_PROJECT_USERS:
            // console.log(action.users)
            return {
                ...state,
                projectUsers: action.users
            }
        case types.ADD_COMMENTS_LIST:
            // in case of adding
            return {
                ...state,
                commentList: [...state.commentList, ...action.commentList],
            }
        case types.SET_COMMENTS_LIST:
            // in case of delete
            return {
                ...state,
                commentList: [...action.commentList]
            }
        case types.SET_COMMENT_FILES:
            return {
                ...state,
                commentFiles: action.files
            }
        case types.SET_COMMENT_TEXT:
            return {
                ...state,
                text: action.text
            }
        case types.SET_IS_SENDING_COMMENT:
            return {
                ...state,
                isSendingComment: action.isSending
            }
        case types.SET_IS_SENDING_DELEGATE:
            return {
                ...state,
                isSendingDelegate: action.isSending
            }
        case types.SET_IS_SENDING_CREATE_NEW_TASK:
            return {
                ...state,
                isSendingCreateNewTasks: action.isSending
            }
        case types.CLEAR_COMMENT_FILED:
            return {
                ...state,
                commentFiles: [],
                text: '',
                parentComment: null,
            }
        case types.SET_PARENT_COMMENT:
            return {
                ...state,
                parentComment: action.parent
            }
        case types.SET_EXECUTOR_SCHEDULE:
            return {
                ...state,
                executorSchedule: action.schedule
            }
        case types.SET_PROJECT_SECTIONS:
            return {
                ...state,
                projectSections: action.sections
            }
        case types.DISPATCH_CHECKBOX_ITEM:
            // console.log('dispatch item')
            let new_copy_checklist = state.checkList.map(item => {
                return item
            })

            if (action.actionType !== 'delete') { // это может быть create и patch
                let currentElem
                // @ts-ignore
                if (new_copy_checklist[action.orderNumber - 1]) {
                    // @ts-ignore
                    const idElem = new_copy_checklist[action.orderNumber - 1].id
                    currentElem = {
                        name: action.text,
                        done: action.checkboxState,
                        id: idElem
                    }
                } else {
                    currentElem = {
                        name: action.text,
                        done: action.checkboxState
                    }
                }

                if (action.orderNumber != null) {
                    // @ts-ignore
                    new_copy_checklist[parseInt(action.orderNumber) - 1] = currentElem
                }
            }

            return {
                ...state,
                checkList: new_copy_checklist
            }
        case types.SET_TASKWORKLOAD_TOGGLER:
            return {
                ...state,
                taskWorkLoadSwitcher: state.taskWorkLoadSwitcher === 'task_load' ? 'work_load' : 'task_load'
                // delegateTo: action.delegeteTo
            }
        case types.REWRITE_WHOLE_TASKLIST:
            return {
                ...state,
                checkList: action.newArray
                // taskWorkLoadSwitcher: state.taskWorkLoadSwitcher === 'task_load' ? 'work_load' : 'task_load'
                // delegateTo: action.delegeteTo
            }

        case types.SET_EXECUTOR_ID:
            return {
                ...state,
                executor_id: action.value
                // taskWorkLoadSwitcher: state.taskWorkLoadSwitcher === 'task_load' ? 'work_load' : 'task_load'
                // delegateTo: action.delegeteTo
            }

        case types.SET_TASK_BEGIN:
            return {
                ...state,
                begin: action.begin,
            }

        case types.SET_TASK_END:
            return {
                ...state,
                end: action.end,
            }

        case types.SET_PREV_ID:
            return {
                ...state,
                prev_id: action.value,
            }

        case types.SET_NEXT_ID:
            return {
                ...state,
                next_id: action.value,
            }

        case types.SET_PARAMETER_TRIGGER:
            return {
                ...state,
                parameter_trigger: !state.parameter_trigger
            }

        case types.SET_START_CYCLICK:
            return {
                ...state,
                repeat: {
                    ...state.repeat,
                    period:
                        action.period !== undefined ? action.period : state.repeat.period,
                    params:
                        action.params !== undefined ? action.params : state.repeat.params,
                    interval:
                        action.interval !== undefined
                            ? action.interval
                            : state.repeat.interval,
                    show_modal_window: action.show_modal_window === undefined ? state.repeat.show_modal_window : action.show_modal_window,
                    settings_string: action.settings_string === undefined ? state.repeat.settings_string : action.settings_string
                },
            };
        case types.SET_FINISH_CYCLICK:
            return {
                ...state,
                repeat: {
                    ...state.repeat,
                    ending_condition:
                        action.ending_condition !== undefined
                            ? action.ending_condition
                            : state.repeat.ending_condition,
                    end_count:
                        action.end_count !== undefined
                            ? action.end_count
                            : state.repeat.end_count,
                    end_date:
                        action.end_date !== undefined
                            ? action.end_date
                            : state.repeat.end_date,
                },
            };
        // case types.SET_REPEAT_OBJ:
        //     return {
        //         ...state,
        //         repeat: {
        //             every: action.every !== undefined ? action.every : state.repeat.every,
        //             stop: action.stop !== undefined ? action.stop : state.repeat.stop,
        //             endDate: action.endDate !== undefined ? action.endDate : state.repeat.endDate,
        //             stopAfterNTimes: action.stopAfterNTimes !== undefined ? action.stopAfterNTimes : state.repeat.stopAfterNTimes,
        //         },
        //     }

        case types.SET_PARENT_ID:
            return {
                ...state,
                parent_id: action.value,
            }

        case types.SET_CHILD_TASKS:
            return {
                ...state,
                child_tasks: action.value,
            }

        case types.SUCCESSFUL_CREATED_NEW_TASKS:
            return {
                ...state,
                successfulCreatedTasks: action.value
            }

        case types.SET_CYCLIC_TASK_CHANGE:
            return {
                ...state,
                rememberObjectCyclicTaskToChange: action.obj
            }

        case types.SET_FLAG_REPEAT_TASK_CHANGE:
            return {
                ...state,
                flagForActionCyclycTask: action.val
            }

        case types.UPDATE_REDUCER_CHECKBOX_ITEM:
            let copy_checklist = state.checkList.map(item => (item))
            let currentElem
            // @ts-ignore
            if (copy_checklist[action.orderNumber - 1]) {
                if (action.actionType === 'delete' && action.orderNumber) {
                    copy_checklist.splice(action.orderNumber - 1, 1)
                } else {
                    // похоже что айдишник используется в API, именно по нему идет адресация среди всех комментариям в БД
                    // @ts-ignore
                    let idElem = copy_checklist[action.orderNumber - 1].id
                    if (!idElem) {
                        if (action.id) idElem = action.id
                    }
                    currentElem = {
                        name: action.text,
                        done: action.checkboxState
                    }
                    if (state.selectedTask) currentElem['id'] = idElem  // если задача создана
                }
            } else {
                currentElem = {
                    name: action.text,
                    done: action.checkboxState
                }
            }

            if ((action.orderNumber != null) && (action.actionType !== 'delete')) {
                // @ts-ignore
                copy_checklist[parseInt(action.orderNumber) - 1] = currentElem
            }
            // console.log(copy_checklist)
            return {
                ...state,
                checkList: copy_checklist
            }

        // case types.SUCCESSFUL_DELEGATED_TO:
        //     return {
        //         ...state,
        //         successfulDelegatedTo: [...state.successfulDelegatedTo, action.value]
        //     }

        default:
            return state
    }
}

export default reducer