import { types, CommonAction } from './action_types';
import {
  Project,
  User,
  Status,
  Role,
  Position,
  Department,
  Priority,
  Workgroup,
  Section,
} from './types';
import { Reducer } from 'redux';
import { cookieMaster } from '../utils/CookieMaster';

const initialState = {
  projects: [] as Project[],
  users: [] as User[],
  statuses: [] as Status[],
  roles: [] as Role[],
  positions: [] as Position[],
  wasFetched: false,
  notifications: [] as any[],
  remote_notifications: {
    unread: [],
    read: [],
    load_next: 'unread',
    offset: 0,
    end: false,
  },
  priorities: [] as Priority[],
  departments: [] as Department[],
  workgroups: [] as Workgroup[],
  sections: [] as Section[],
  current_user: parseInt(cookieMaster.getCookie('user_id')),
  currentUserInfo: null as null | User,
  isVerified: true,
  show_notification_window: false,
};

export type CommonState = typeof initialState;

// @ts-ignore
const commonReducer: Reducer<CommonState, CommonAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.ADD_COMMON_INFO:
      return {
        ...state,
        projects: action.projects || [],
        users: action.users || [],
        statuses: action.statuses || [],
        roles: action.roles || [],
        positions: action.positions || [],
        departments: action.departments || [],
        priorities: action.priorities || [],
        workgroups: action.workgroups || [],
        sections: action.sections || [],
        wasFetched: true,
        remote_notifications: {
          unread: action.unread_notifications || [],
          read: action.read_notifications || [],
          load_next: action.read_notifications.length
            ? 'read'
            : state.remote_notifications.load_next,
          offset: action.read_notifications.length
            ? action.read_notifications.length
            : action.unread_notifications.length,
          end: action.end ? action.end : state.remote_notifications.end,
        },
      };
    case types.CHANGE_WORK_SCHEDULE:
      return {
        ...state,
        scheduleUser: action.schedule,
        // schedule.workTime:
      };
    case types.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
          },
        ],
      };
    case types.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((notif: any) =>
          notif.key === action.key
            ? { ...notif, dismissed: true }
            : { ...notif }
        ),
      };
    case types.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notif: any) => notif.key !== action.key
        ),
      };
    case types.SET_USERS:
      return {
        ...state,
        users: action.users,
      };
    case types.SET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      };
    case types.SET_DEPARTMENTS:
      return {
        ...state,
        departments: action.departments,
      };
    case types.SET_WORKGROUPS:
      return {
        ...state,
        workgroups: action.workgroups,
      };
    case types.SET_SECTIONS:
      return {
        ...state,
        sections: action.sections,
      };
    case types.SET_VERIFYED:
      return {
        ...state,
        isVerified: action.verified,
      };
    case types.SET_CURRENT_USER_INFO:
      return {
        ...state,
        currentUserInfo: action.user,
      };
    case types.SET_SHOW_NOTIF_WINDOW:
      return {
        ...state,
        show_notification_window: action.val,
      };
    case types.SET_REMOTE_NOTIFICATIONS:
      // let new_offset
      // if (action.load_next && action.load_next === state.remote_notifications.load_next) {
      //     // let current_position = action.load_next
      //     new_offset = state.remote_notifications[state.remote_notifications.load_next].length
      // } else {
      //     new_offset = state.remote_notifications[state.remote_notifications.load_next].length
      // }

      return {
        ...state,
        remote_notifications: {
          // unread: action.unread[0] ? new_unread : state.remote_notifications.unread,
          unread: action.unread,
          read: action.read,
          load_next: action.load_next
            ? action.load_next
            : state.remote_notifications.load_next,
          offset:
            state.remote_notifications[state.remote_notifications.load_next]
              .length,
          end: action.end ? true : state.remote_notifications.end,
        },
      };

    default:
      return state;
  }
};

export default commonReducer;
