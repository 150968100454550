import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Task } from '../../../common/types';
import TaskTr from './TaskTr';
//import { SortableContainer } from 'react-sortable-hoc';
import { State } from '../../../rootReducer';
import { connect } from 'react-redux';
import { fetchTasks, changeTaskList } from '../actions';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import TasksTableHead from './TasksTableHead';

type Props = {
  tasks: Task[];
  tableFilter: string;
  fetchTasks: (pageNum: number) => void;
  changeTaskList: (tasks: Task[]) => void;
  filters: any;
  tasksOrder: Object;
};

const WrapperTasksTable = styled.div`
  height: calc(100vh - 200px);
  margin-left: 40px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const TasksTableContent: React.FC<Props> = ({
  tasks,
  tableFilter,
  fetchTasks,
  changeTaskList,
  filters,
  tasksOrder,
}) => {
  const [pageNum, setPageNum] = useState(1);
  const [isScroll, setScroll] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const scrollHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
      if (
        scrollHeight <= scrollTop + clientHeight + 1 &&
        tasks.length === 20 * pageNum
      ) {
        setPageNum(pageNum + 1);
      }
    },
    [pageNum, tasks.length]
  );

  const { id } = useParams();

  useEffect(() => {
    if (pageNum !== 1) fetchTasks(pageNum);
  }, [fetchTasks, pageNum]);

  useEffect(() => {
    setPageNum(1);
  }, [id, filters.appliedFilters, filters.actual, tasksOrder]);

  useEffect(() => {
    let widthScrol = ref?.current?.offsetWidth || 0;
    let widthTable = ref?.current?.scrollWidth || 0;
    if (widthScrol > widthTable) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }, [ref]);

  return (
    <>
      <TasksTableHead isScroll={isScroll} />
      <WrapperTasksTable onScroll={scrollHandler} ref={ref}>
        {tasks.map((task: Task) => (
          <TaskTr key={task.id} task={task} />
        ))}
      </WrapperTasksTable>
    </>
  );
};

const mapStateToProps = (state: State) => {
  // console.log(state)
  return {
    // project_id:state.tasksPage.tasks,
    tableFilter: state.tasksPage.tableFilter.toLocaleLowerCase(),
    tasks: state.tasksPage.tasks,
    filters: state.tasksPage.filters,
    tasksOrder: state.tasksPage.tasksOrder,
  };
};

const mapDispatchToProps = {
  fetchTasks,
  changeTaskList,
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksTableContent);

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(SortableContainer(TasksTableContent));
