import React, {useCallback, useRef, useState, useEffect, useMemo} from 'react';
import './styles.sass'
import {State} from '../../../../rootReducer';
import {connect} from 'react-redux';
import {Department, User, Workgroup} from '../../../../common/types';
import Search from '../../../../common/components/Inputs/Search';
import NewCheckboxItem from '../../../../common/components/NewCheckboxItem/NewCheckboxItem';
import {
    addUserToDepGroup,
    deleteDepGroup, get_next_page_among_all,
    removeUserFromDepGroup,
    setAddUserToGroupDep, updateDepGroupInfo
} from '../../actions';
import Avatar from "../../../../common/components/Avatar/Avatar";
import {urlApp} from '../../../../App'
import Input from "../../../../common/components/Inputs/Input";
import SelectWithSecondaryBtn from "../../../../common/components/Selects/SelectWithSecondaryBtn";
import {throttle} from "../../../../utils/throttle";
import {CreateNotif} from "../../../../utils/createNotification";

type Props = {
    users: User[],
    depGroupUsers: User[],
    selectedDepGroupId: number | null,
    plateType: string | null,
    addUserToDepGroup: (id: number, user: User) => void,
    removeUserFromDepGroup: (id: number, userId: number) => void,
    setShow: (show: boolean) => void,
    setAddUserToGroupDep: (show: boolean) => void,
    deps: Department[],
    groups: Workgroup[],
    positions: any,
    updateDepGroupInfo: (name: string, head_id: number) => void,
    deleteDepGroup: () => void,
    total_users: number | null,
    get_next_page_among_all: (filter: string, page?: number) => void,
    load_users_by_pages: User[],
    page_to_load: number
}


const AddUsersPlate: React.FC<Props> = ({users, page_to_load, load_users_by_pages, get_next_page_among_all, total_users, deleteDepGroup, updateDepGroupInfo, positions, deps, groups, setAddUserToGroupDep, depGroupUsers, plateType, selectedDepGroupId, setShow, addUserToDepGroup, removeUserFromDepGroup}) => {

    // глава группы
    const head_id = plateType === 'deps' ? deps.find(dep => dep?.id === selectedDepGroupId)?.head_id : groups.find(groups => groups?.id === selectedDepGroupId)?.head_id
    const head_user = depGroupUsers.find(user => user.id === head_id)

    const [remove_confirmation_mode, set_remove_confirmation_mode] = useState<boolean>(false)
    const [filter, setFilter] = useState<string>('')
    const [head_user_obj, setHead_user_obj] = useState<User | undefined>(head_user)
    const [projectName, setProjectName] = useState<string | undefined>(plateType === 'deps' ? deps.find((elem) => elem.id === selectedDepGroupId)?.name : groups.find((elem) => elem.id === selectedDepGroupId)?.name)
    const plate = useRef<HTMLDivElement>(null)
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null)

    //////
    const outsideClickHandler = useCallback((e: Event) => {
        let element = e.target as Element
        if (element.classList.contains('add_pannel_wrapper')) {
            setAddUserToGroupDep(false)
        }
    }, [setShow])

    useEffect(() => {
        document.addEventListener('click', outsideClickHandler)
        return () => document.removeEventListener('click', outsideClickHandler)
    }, [outsideClickHandler])
    ///////

    useMemo(() => {
        setHead_user_obj(head_user)
    }, [head_user])

    // если мы в "отделах", то беру с 5 ролью, если "группах", то 6
    let users_heads_roles = users.filter(user => user.roles.includes(plateType === 'deps' ? 5 : 6))
    let reformated_heads_for_dropdown = users_heads_roles.map(user => {
        return {value: user.id, text: user.name + ' ' + user.surname}
    })


    // изменился фильтр и вызов при инициализации
    useEffect(() => {
        if (timer) clearTimeout(timer)
        setTimer(setTimeout(() => {
            get_next_page_among_all(filter, 1)
        }, 500))
    }, [filter]);


    const scrollHandler = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const {scrollHeight, scrollTop, clientHeight} = e.currentTarget
        // page_to_load соедржит в себе следующую страницу для загрузки. поэтому здесь -1
        if (scrollHeight - 5 <= scrollTop + clientHeight && load_users_by_pages.length === 20 * (page_to_load - 1)) {
            // тротл - чтобы срабатывание происходило не чаще, чем раз в 2 секунды
            let f1000 = throttle(() => get_next_page_among_all(filter), 1500);
            f1000()
        }
    }, [filter, load_users_by_pages])

    return (
        <div className="add_pannel_wrapper" ref={plate}>

            {remove_confirmation_mode && <div className={'remove_confirmation'}>
                <div className="wrapper">
                    <div className={'main_header'}>
                        <div className="main_title">
                            Подтверждение
                        </div>
                        <div className={'close_button'} onClick={() => {
                            set_remove_confirmation_mode(false)
                        }}/>
                    </div>

                    <div className={'text'}>
                        Вы уверены, что хотите удалить {plateType === 'deps' ? 'отдел ?' : "группу ?"}
                    </div>

                    <div className={'buttons_line'}>
                        <div className={'button lg filled blue'} onClick={() => {
                            deleteDepGroup()
                        }}>
                            Удалить
                        </div>
                        <div className={'button lg outlined blue'} onClick={() => {
                            set_remove_confirmation_mode(false)
                        }}>
                            Отмена
                        </div>
                    </div>

                </div>
            </div>}

            {!remove_confirmation_mode && <div ref={plate} className="add_users_plate">
                <div className="wrapper">
                    <div className={'main_header'}>
                        <div className="main_title">
                            {'Редактирование ' + (plateType === 'deps' ? 'отдела' : 'группы')}
                        </div>
                        <div className={'close_button'} onClick={() => {
                            setAddUserToGroupDep(false)
                        }}/>
                    </div>

                    <>
                        <div className={'header'}>Название</div>

                        <div className={'dep_group_name'}>
                            <Input inputType={'text'} placeholder={'Введите название'} value={projectName}
                                   title={'Название проекта является обязательным'}
                                   changeHandler={setProjectName}/>
                        </div>

                        <div className={'header'}>Руководитель</div>

                        {head_user_obj ?
                            <NewCheckboxItem
                                key={head_user_obj.id}
                                action_icon={'cross'}
                                user={head_user_obj}
                                positions={positions}
                                text={`${head_user_obj.surname} ${head_user_obj.name}`}
                                value={head_user_obj.id}
                                checked={depGroupUsers.some(elem => elem.id === head_user_obj.id)}
                                component={<Avatar
                                    url={head_user_obj.image ? urlApp + `/files/${head_user_obj.image.url}/download` : null}/>}
                                handler={(user, icon_type) => {
                                    setHead_user_obj(undefined)
                                }}
                            />
                            :
                            <SelectWithSecondaryBtn
                                list={reformated_heads_for_dropdown}
                                selected={null}
                                selectHandler={(value) => {
                                    setHead_user_obj(users.find(user => user.id === value))
                                }}
                                disabled={false}
                            />
                        }

                        <div className={'header'}>Сотрудники
                            <div>{total_users}</div>
                        </div>

                        <Search
                            value={filter}
                            placeholder="Поиск"
                            changeHandler={(value: string) => {
                                setFilter(value)
                            }}
                        />

                        <div className="user_checkbox" onScroll={scrollHandler}>
                            {load_users_by_pages.map(user => (
                                <NewCheckboxItem
                                    key={user.id}
                                    action_icon={
                                        plateType === 'deps' ?
                                            user.departments ? user.departments.includes(selectedDepGroupId as number) ? 'checked' : 'plus' : 'plus'
                                            :
                                            user.work_groups ? user.work_groups.includes(selectedDepGroupId as number) ? 'checked' : 'plus' : 'plus'
                                    }
                                    user={user}
                                    positions={positions}
                                    text={`${user.surname} ${user.name}`}
                                    value={user.id}
                                    checked={
                                        plateType === 'deps' ?
                                            user.departments ? user.departments.includes(selectedDepGroupId as number) : false
                                            :
                                            user.work_groups ? user.work_groups.includes(selectedDepGroupId as number) : false
                                    }
                                    component={<Avatar
                                        url={user.image ? urlApp + `/files/${user.image.url}/download` : null}/>}
                                    handler={(user, icon_type) => {
                                        if (user.id === head_id && icon_type === 'checked') {
                                            CreateNotif('Нельзя удалить главу отдела')
                                        } else {
                                            if (icon_type === 'checked') removeUserFromDepGroup(selectedDepGroupId!, user.id)
                                            else addUserToDepGroup(selectedDepGroupId!, user)
                                        }
                                    }}
                                />
                            ))}
                        </div>
                    </>

                    <div className={'buttons_line'}>
                        <div className={'leftPart'}>
                            <div
                                className={`button filled blue lg ${!head_user_obj || !projectName?.length ? 'disabled' : ''}`}
                                onClick={() => {
                                    if (head_user_obj && projectName?.length) {
                                        let initial_name: string | undefined = ''
                                        if (plateType === 'deps') initial_name = deps.find((elem) => elem.id === selectedDepGroupId)?.name
                                        else initial_name = groups.find((elem) => elem.id === selectedDepGroupId)?.name

                                        if (projectName !== initial_name || head_user !== head_user_obj) {
                                            updateDepGroupInfo(projectName, head_user_obj.id)
                                        }
                                        setAddUserToGroupDep(false)
                                    }
                                }}>
                                Готово
                            </div>
                            <div className={'button outlined blue lg'} onClick={() => {
                                setAddUserToGroupDep(false)
                            }}>Отмена
                            </div>
                        </div>
                        <div className={'rightPart'}>
                            <div className={'button borderless red lg'} onClick={() => {
                                set_remove_confirmation_mode(true)
                            }}>
                                Удалить
                            </div>
                        </div>
                    </div>

                </div>
            </div>}
        </div>
    )
}

const mapStateToProps = (state: State) => {
    // console.log(state)
    return {
        page_to_load: state.usersPage.page_to_load,
        load_users_by_pages: state.usersPage.load_users_by_pages,
        total_users: state.usersPage.total_users,
        positions: state.commonInfo.positions,
        deps: state.commonInfo.departments,
        groups: state.commonInfo.workgroups,
        users: state.commonInfo.users,
        // groups: getSelectedDepGroups(state),
        depGroupUsers: state.usersPage.depGroupUsers,
        plateType: state.usersPage.depGroupPlateType,
        selectedDepGroupId: state.usersPage.selectedDepGroupId
    }
}


const mapDispatchToProps = {
    addUserToDepGroup,
    removeUserFromDepGroup,
    setAddUserToGroupDep,
    updateDepGroupInfo,
    deleteDepGroup,
    get_next_page_among_all
}


// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(AddUsersPlate)