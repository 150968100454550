import React from 'react';
import './styles.sass';
import Login from './components/Login';
import { Route, Switch } from 'react-router-dom';
import EmailForm from './components/EmailForm';
import PasswordRecovery from './components/PasswordRecovery';
import EmailVerify from './components/EmailVerify';

const LoginPlate = () => {
  return (
    <div className="login_wrapper">
      <div className="login">
        <div className="login_form_wrapper">
          <div className="logo" />
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/forgot-password" component={EmailForm} />
            <Route path="/password/recovery" component={PasswordRecovery} />
            <Route path="/email" component={EmailVerify} />
          </Switch>
        </div>
        <div className="image" />
      </div>
    </div>
  );
};

export default LoginPlate;
