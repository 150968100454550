import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { setUserAddMode } from '../Users/actions';
import { clearUser, createUser } from './actions';
import UserName from './components/UserName';
import UserSurname from './components/UserSurname';
import UserPatronymic from './components/UserPatronymic';
import RoleSelect from './components/RoleSelect';
import PositionSelect from './components/PositionSelect';
import DepartmentSelect from './components/DepartmentSelect';
import Avatar from './components/Avatar';
import UserEmail from './components/UserEmail';
import UserBirthDay from './components/UserBirthDay';
import UserSchedule from './components/UserSchedule';
import DaysOff from './components/DaysOff';
import UserPassword from './components/UserPassword';
import { State } from '../../rootReducer';
import { getCanSend } from './selectors';
import { useHistory } from 'react-router-dom';
import UserChangePassword from './components/UserChangePasswordButton';
import styled from 'styled-components';
import palette from '../../common/components/palette';
import Button from '../../common/components/newComponent/Buttons/Button';
import NameWithInput from '../../common/components/newComponent/Inputs/NameWithInput';
import { fetchData } from '../../utils/fetchData';
import { urlApp } from '../../App';
import Modal from '../../common/components/Modal';

type Props = {
  canSend: boolean;
  isSending: boolean;
  userId: number;
  currentUser: number;
  clearUser: () => void;
  createUser: (update: boolean) => void;
  setUserAddMode: (mode: boolean) => void;
};

const WrapperAddUser = styled.div`
  width: 100%;
  min-height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
`;
const BlockUsers = styled.div`
  padding: 1rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0 100px rgba(41, 47, 76, 0.1);
  margin-top: 20px;
  transform: ${({ visibleModal }) =>
    visibleModal ? 'translateY(0)' : 'translateY(-100vh)'};
  display: flex;
  flex-direction: column;
  width: 584px;
  height: auto;
  filter: ${({ modalDel }) => modalDel && 'blur(10px)'};
`;
const HeadStyle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: ${palette.textBlack};
`;

const TopInformation = styled.div`
  display: flex;
`;

const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ProjectAddPanel: React.FC<Props> = ({
  canSend,
  isSending,
  userId,
  clearUser,
  createUser,
  setUserAddMode,
  currentUser,
}) => {
  const history = useHistory();
  const [modalDel, setModalDel] = useState(false);
  const visibleModal = useSelector(
    (state: State) => state.usersPage.userAddMode
  );
  const currentUserRoles = useSelector(
    (state: State) => state.commonInfo.currentUserInfo?.roles
  );
  const idUser = useSelector((state: State) => state.newUser.id);
  const [verification, setVerification] = useState({
    email_verified: 1,
    disabled: false,
  });
  const sendVerificationRequest = () => {
    setVerification({
      ...verification,
      disabled: true,
    });
    fetch(urlApp + `/email/resend/${idUser}`).catch((error) =>
      console.error(error)
    );
    // fetchData.get(`/email/resend/${idUser}`).then((data) => console.log(data));
  };

  const closeHandler = useCallback(() => {
    setTimeout(() => {
      setUserAddMode(false);
      clearUser();
      history.push('/users');
    }, 300);
  }, [clearUser, history, setUserAddMode]);

  // self-close for window
  let plate = useRef<HTMLDivElement>(null);
  const outsideClickHandler = useCallback(
    function (e: Event): any {
      let element = e.target as Node;
      if (element === plate.current) closeHandler();
    },
    [closeHandler]
  );

  useEffect(() => {
    if (idUser !== 0) {
      fetchData
        .get(`/api/v1/users/${idUser}`)
        .then((data) =>
          setVerification({
            ...verification,
            email_verified: data.email_verified,
          })
        )
        .catch((error) => console.log(error));
    }
  }, [idUser]);

  useEffect(() => {
    document.addEventListener('click', outsideClickHandler);
    return () => document.removeEventListener('click', outsideClickHandler);
  }, [outsideClickHandler]);
  // self-close for window

  return (
    <WrapperAddUser ref={plate}>
      <BlockUsers visibleModal={visibleModal} modalDel={modalDel}>
        <HeadStyle>
          {userId === 0 ? 'Новый сотрудник' : 'Данные сотрудника'}
        </HeadStyle>
        <TopInformation>
          <div
            style={{
              width: '100%',
              marginRight: '.5rem',
              marginBottom: '-8px',
            }}
          >
            <UserSurname />
            <UserName />
            <UserPatronymic />
          </div>
          <Avatar />
        </TopInformation>
        <UserBirthDay />
        <UserEmail />
        {/* {userId === 0 && <UserPassword />} */}
        {userId === currentUser && <UserChangePassword />}
        <RoleSelect />
        <PositionSelect />
        <DepartmentSelect />
        {!!!verification.email_verified && (
          <NameWithInput title="Верификация" iconName="check">
            <Button
              title="Отправить повторно"
              design="outline"
              disabled={verification.disabled}
              onClick={sendVerificationRequest}
              big
            />
          </NameWithInput>
        )}

        <UserSchedule />
        <DaysOff />
        <WrapperButtons>
          <div>
            {(currentUserRoles?.includes(1) || currentUserRoles?.includes(2)) &&
              userId !== 0 && (
                <Button
                  title="Удалить аккаунт"
                  design="transparent"
                  onClick={() => {
                    setModalDel(true);
                  }}
                  style={{ color: 'red' }}
                />
              )}
          </div>
          <div style={{ display: 'flex' }}>
            <Button onClick={closeHandler} title="Отмена" design="secondary" />
            <Button
              onClick={() => createUser(!!userId)}
              title={userId === 0 ? 'Сохранить' : 'Готово'}
              disabled={!canSend}
              isSending={isSending}
              style={{ marginLeft: '.5rem' }}
            />
          </div>
        </WrapperButtons>
      </BlockUsers>
      {modalDel && (
        <Modal
          title="Удалить страницу?"
          description="Вы действительно хотите безвозвратно удалить личный кабинет пользователя?"
        >
          <Button
            onClick={() => {
              setModalDel(false);
            }}
            title="Отмена"
            design="secondary"
            style={{ width: '100%' }}
          />
          <Button
            style={{ width: '100%', marginLeft: '8px' }}
            onClick={() => {
              fetchData
                .delete(`/api/v1/users/${idUser}`)
                .then(() => {
                  closeHandler();
                  setModalDel(false);
                  history.push('/users');
                })
                .catch((e) => console.log(e));
            }}
            title="Удалить"
          />
        </Modal>
      )}
    </WrapperAddUser>
  );
};

const mapStateToProps = (state: State) => {
  return {
    canSend: getCanSend(state),
    isSending: state.newUser.isSending,
    roles: state.newUser.roles,
    userId: state.newUser.id,
    currentUser: state.commonInfo.current_user,
  };
};

const mapDispatchToProps = {
  setUserAddMode,
  clearUser,
  createUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAddPanel);
