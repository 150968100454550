import React from 'react';
import './styles.sass';
import { urlApp } from '../../../App';

type Props = {
  id: number;
  name: string;
  url: string;
  canBeDeleted?: boolean;
  deleteHandler?: (id: number) => void;
};

const FileElem: React.FC<Props> = ({
  id,
  name,
  url,
  canBeDeleted = true,
  deleteHandler,
}) => {
  return (
    <div
      className={'attachment'}
      onClick={() => {
        window.open(urlApp + `/files/${url}/download`);
      }}
    >
      <div className={'icon'}></div>

      <div className={'content'} title={name}>
        <p>{name}</p>
      </div>

      {/*<div className="file_elem">*/}
      {canBeDeleted && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (deleteHandler) deleteHandler(id);
          }}
          className="deleteAttachment"
        />
      )}
      {/*    <img src={file} alt="file"/>*/}
      {/*    <a className="file_name" href={urlApp + `/files/${url}/download`}>{name}</a>*/}
      {/*</div>*/}
    </div>
  );
};

// const FileElem: React.FC<Props> = ({ id, name, url, canBeDeleted = true, deleteHandler }) => {
//     return (
//         <div className="file_elem">
//             {canBeDeleted && <div onClick={() => {
//                 if (deleteHandler) deleteHandler(id)
//             }} className="cross"/>}
//             <img src={file} alt="file" />
//             <a className="file_name" href={urlApp+`/files/${url}/download`}>{name}</a>
//         </div>
//     )
// }

export default FileElem;
