import { types, fetchTokenAction } from './action_types'




export const fetchToken = (email: string, password: string): fetchTokenAction => {
    return {
        type: types.FETCH_TOKEN,
        email,
        password
    }
}