import {
    types,
    saveDepAction,
    addUserToDepGroupAction,
    // addUserToGroupAction,
    removeUserFromDepGroupAction,
    // removeUserFromGroupAction,
    saveGroupAction,
    fetchUsersAction, updateDepGroupInfoAction, deleteDepGroupAction, getNextPageAmongAllAction
} from './action_types'
import {call, put, takeLatest, delay, takeEvery} from 'redux-saga/effects'
import {fetchData} from '../../utils/fetchData'
import {setDepartments, setWorkgroups} from '../../common/actions'
import {
    putFetchedUsersByPages,
    setAddUserToGroupDep,
    setDepGroupType, setIsFetchingUsers,
    setUsers,
    showWindowCreateDepGroup,
    // updateDepGroupInfo
} from './actions'
// import {setUsers as setAllUsers} from '../../common/actions'
import {
    // setShowDepGroupPlate,
    // setIsFetchingUsers,
    isCreatingDep,
    // setDepGroupType,
    selectDepGroup,
    // addUserToDepGroup as addUserToD,
    // addUserToGroup as addUserToG
} from './actions'
import {store} from '../../App'
import {CreateNotif} from '../../utils/createNotification'
// import {Department, User} from "../../common/types";
// import {User} from "../../common/types";

const api_prefix = '/api/v1/'
const company = 'companies/1/'


function* setTableOrder() {
    const {tableFilter, usersOrder, depGroupPlateType, selectedDepGroupId} = store.getState().usersPage
    let plate_type = ''
    if (depGroupPlateType === 'deps') plate_type = 'departments'
    else if (depGroupPlateType === 'groups') plate_type = 'workgroups'

    const users = yield call(fetchData.get, `${api_prefix}${company}${(plate_type && selectedDepGroupId) ? plate_type + '/' + selectedDepGroupId + '/' : ''}users?page=1${tableFilter ? `&search=${tableFilter}` : ''}` + sorting(usersOrder))
    // console.log(users)
    if (users) yield put(setUsers(users))
}

function sorting(tasksOrder) {
    if (tasksOrder.col_name !== '') {
        let result = `&order=` + tasksOrder.col_name
        if (!tasksOrder.order_direct) result += '&orderType=desc' // по умолчанию ascending
        return result
    } else return ''
}


// только для получения конкретной страницы
function* fetchUsers(action) {
    // yield put(setIsFetchingUsers(true))
    const {depGroupUsers: userList, tableFilter, depGroupPlateType, selectedDepGroupId, usersOrder} = store.getState().usersPage
    let plate_type = ''
    if (depGroupPlateType === 'deps') plate_type = 'departments'
    else if (depGroupPlateType === 'groups') plate_type = 'workgroups'
    let {pageNum}: fetchUsersAction = action

    // console.log('fetch ', usersOrder)
    const users = yield call(fetchData.get, `${api_prefix}${company}${(plate_type && selectedDepGroupId) ? plate_type + '/' + selectedDepGroupId + '/' : ''}users?page=${pageNum}${tableFilter ? `&search=${tableFilter}` : ''}` + sorting(usersOrder))

    // console.log(users, pageNum)
    if (users) {
        if (pageNum === 1) yield put(setUsers(users))
        else yield put(setUsers([...userList, ...users]))
    }

    yield put(setIsFetchingUsers(false))
}


function* openDeps() {
    yield delay(200)
    const departments = yield call(fetchData.get, `${api_prefix}companies/1/departments`)
    const workgroups = yield call(fetchData.get, `${api_prefix}companies/1/workgroups`)
    // console.log(departments, workgroups)
    if (departments) {
        yield put(setDepartments(departments))
    }
    if (workgroups) {
        yield put(setWorkgroups(workgroups))
    }
    // let showDepGroupPlate = store.getState().usersPage.showDepGroupPlate
    // yield put(setDepGroupType('deps'))
    // yield put(setDepGroupType('groups'))
    // if (!showDepGroupPlate) yield put(setShowDepGroupPlate(true))
}

// function* openGroups() {
// yield delay(200)
// const workgroups = yield call(fetchData.get, `${api_prefix}workgroups`)
// if (workgroups) {
//     yield put(setWorkgroups(workgroups))
// }
// let showDepGroupPlate = store.getState().usersPage.showDepGroupPlate
// yield put(setDepGroupType('groups'))
// if (!showDepGroupPlate) yield put(setShowDepGroupPlate(true))
// }

function* delete_dep_group({}: deleteDepGroupAction) {
    const {depGroupPlateType, selectedDepGroupId} = store.getState().usersPage
    const {departments, workgroups} = store.getState().commonInfo
    const delete_result = yield call(fetchData.delete, `${api_prefix}${depGroupPlateType === 'deps' ? 'departments' : 'workgroups'}/${selectedDepGroupId}`)
    if (delete_result.success) {

        let selectedId = selectedDepGroupId
        let type = depGroupPlateType

        yield put(setAddUserToGroupDep(false)) // скрываю вообще плашку редактирования группы
        yield put(selectDepGroup(null, null))
        yield put(setDepGroupType(null))
        yield put(setUsers([]))
        yield fetchUsers({pageNum: 1})

        let result
        if (type === 'deps') {
            result = departments.filter(dep => dep.id !== selectedId)
            yield put(setDepartments(result))
        } else {
            result = workgroups.filter(group => group.id !== selectedId)
            yield put(setWorkgroups(result))
        }

        CreateNotif(`${depGroupPlateType === 'deps' ? 'Отдел' : "Группа"} успешно ${depGroupPlateType === 'deps' ? 'удалён' : "удалена"}`, 'success')
    }
}


function* updateDepGroup({name, head_id}: updateDepGroupInfoAction) {
    const {depGroupPlateType, selectedDepGroupId} = store.getState().usersPage
    const {departments, workgroups} = store.getState().commonInfo
    const pathed_result = yield call(fetchData.patch, `${api_prefix}${depGroupPlateType === 'deps' ? 'departments' : 'workgroups'}/${selectedDepGroupId}`, JSON.stringify({
        name,
        head_id
    }))
    if (pathed_result) {
        // console.log(pathed_result)

        let find_obj
        if (depGroupPlateType === 'deps') {
            find_obj = departments.find(dep => dep.id === selectedDepGroupId)
            find_obj.name = pathed_result.name
            find_obj.head_id = pathed_result.head_id

            const newList = departments.map(o => {
                if (o.id === find_obj.id) return find_obj
                return o
            })
            yield put(setDepartments(newList))
        } else {
            find_obj = workgroups.find(group => group.id === selectedDepGroupId)
            find_obj.name = pathed_result.name
            find_obj.head_id = pathed_result.head_id

            const newList = workgroups.map(o => {
                if (o.id === find_obj.id) return find_obj
                return o
            })
            yield put(setWorkgroups(newList))
        }

        CreateNotif(`${depGroupPlateType === 'deps' ? 'Отдел' : "Группа"} успешно ${depGroupPlateType === 'deps' ? 'отредактирован' : "отредактирована"}`, 'success')
    }
}

function* createDep({name, head_id}: saveDepAction) {
    yield put(isCreatingDep(true))
    const dep = yield call(fetchData.post, `${api_prefix}departments`, JSON.stringify({
        name,
        head_id,
        company_id: 1
    }))
    if (dep) {
        yield put(setUsers([]))
        const deps = store.getState().commonInfo.departments.slice()
        const head_user = store.getState().commonInfo.users.find(user => user.id === head_id)
        yield put(setDepartments([...deps, dep]))
        // глава отдела автоматически добавляется в только что созданный отдел
        if (head_user) yield addUserToDepGroup({
            depGroupId: dep.id,
            user: head_user,
            type: types.ADD_USER_TO_DEP_GROUP
        })

        yield put(showWindowCreateDepGroup(false))
        yield put(selectDepGroup(dep.id, 1))
        yield fetchUsers({pageNum: 1})
        yield put(setAddUserToGroupDep(true))
    }
    yield put(isCreatingDep(false))
}

function* createGroup({name, head_id}: saveGroupAction) {
    yield put(isCreatingDep(true))
    const group = yield call(fetchData.post, `${api_prefix}workgroups`, JSON.stringify({
        name,
        head_id,
        company_id: 1
    }))
    if (group) {
        yield put(setUsers([]))// обнуляю пользователей в таблице
        const groups = store.getState().commonInfo.workgroups.slice()
        const head_user = store.getState().commonInfo.users.find(user => user.id === head_id)
        yield put(setWorkgroups([...groups, group])) // добавляю группу в существующие группы
        // глава группы автоматически добавляется в только что созданную группу

        // if (head_user) yield addUserToGroup({groupId: group.id, user: head_user, type: types.ADD_USER_TO_GROUP})
        if (head_user) yield addUserToDepGroup({
            depGroupId: group.id,
            user: head_user,
            type: types.ADD_USER_TO_DEP_GROUP
        })

        // yield addUserToGroup({groupId: group.id, user: head_user, type: types.ADD_USER_TO_GROUP})
        yield put(showWindowCreateDepGroup(false)) // скрываю окно создание отдела\группы
        yield put(selectDepGroup(group.id, 1)) // активным делаю айдишник только что созданной группы
        yield fetchUsers({pageNum: 1})   // запрашиваю пользователей по созданной группе и кладу в таблицу
        yield put(setAddUserToGroupDep(true)) // показываю окно редактирования группы
    }
    yield put(isCreatingDep(false))
}

function* addUserToDepGroup({depGroupId, user}: addUserToDepGroupAction) {
    let plateType = store.getState().usersPage.depGroupPlateType
    let plate_users = store.getState().usersPage.depGroupUsers.slice()
    let {total_users, load_users_by_pages} = store.getState().usersPage

    const result = yield call(fetchData.post, `${api_prefix}${plateType === 'deps' ? 'departments' : 'workgroups'}/${depGroupId}/users`, JSON.stringify({user_id: user.id}))
    if (result && result.success) {
        let data_context = store.getState().commonInfo[plateType === 'deps' ? 'departments' : 'workgroups']

        // добавляю в пользователя отдел, в котором он начал состоять
        load_users_by_pages.find(usr => usr.id === user.id) ?. [plateType === 'deps' ? 'departments' : 'work_groups'].push(depGroupId)
        yield put(putFetchedUsersByPages(load_users_by_pages))
        // @ts-ignore нужно обновлять значение общего количества юзеров в группе
        yield put(selectDepGroup(depGroupId, total_users + 1))

        // @ts-ignore
        let found_item_common = data_context.find(item => item.id === depGroupId)
        if (found_item_common) found_item_common['number_of_employees'] = found_item_common.number_of_employees + 1

        // @ts-ignore
        plateType === 'deps' ? yield put(setDepartments(data_context)) : yield put(setWorkgroups(data_context))
        yield put(setUsers([...plate_users, user]))
        CreateNotif(`Пользователь добавлен в ${plateType === 'deps' ? 'отдел' : 'группу'}`, 'success')
    }
}

// function* addUserToGroup({groupId, user}: addUserToGroupAction) {
//     const result = yield call(fetchData.post, `${api_prefix}workgroups/${groupId}/users`, JSON.stringify({user_id: user.id}))
//     if (result && result.success) {
//         let groups = store.getState().commonInfo.workgroups
//         let users = store.getState().usersPage.depGroupUsers.slice()    ////
//         let {total_users, load_users_by_pages} = store.getState().usersPage ////
//         // добавляю в пользователя группу, в котором он начал состоять
//         load_users_by_pages.find(usr => usr.id === user.id)?.work_groups.push(groupId)
//         yield put(putFetchedUsersByPages(load_users_by_pages)) ////
//         // @ts-ignore нужно обновлять значение общего количества юзеров в группе
//         yield put(selectDepGroup(groupId, total_users + 1))     ////
//         let index = users.findIndex(user => user.id === user.id)    ////
//         let found_group = groups.find(group => group.id === groupId)
//         if (found_group) found_group['number_of_employees'] = found_group.number_of_employees + 1
//         yield put(setWorkgroups(groups))
//         if (index !== -1) {
//             users[index].work_groups.push(groupId)
//             // добавление этого пользователя в список всех пользователей в commonInfo
//             yield put(setAllUsers(users))
//             // добавление пользователя в список пользователей, которые сейчас отображаются на экране
//             if (store.getState().usersPage.selectedDepGroupId === groupId) {
//                 yield put(setUsers([...users, users[index]]))
//             }
//         }
//         CreateNotif('Пользователь добавлен в группу', 'success')
//     }
// }

function* removeUserFromDepartment({depId, userId}: removeUserFromDepGroupAction) {
    let plateType = store.getState().usersPage.depGroupPlateType
    let {load_users_by_pages, page_to_load, total_users} = store.getState().usersPage
    let user_by_pages = load_users_by_pages.find(usr => usr.id === userId)
    let common_data_context = store.getState().commonInfo[plateType === 'deps' ? 'departments' : 'workgroups']
    let depGroupUsers = store.getState().usersPage.depGroupUsers.slice()

    const result = yield call(fetchData.delete, `${api_prefix}${plateType === 'deps' ? 'departments' : 'workgroups'}/${depId}/users/${userId}`)
    if (result && result.success) {
        // удаляю пользователя из отдела, в котором он больше не состоит load_users_by_pages
        let found_dep_index = user_by_pages?.[plateType === 'deps' ? 'departments' : 'work_groups']?.indexOf(depId)
        // @ts-ignore
        if (found_dep_index != null && found_dep_index > -1) {
            user_by_pages?.[plateType === 'deps' ? 'departments' : 'work_groups']?.splice(found_dep_index, 1);
        }
        yield put(putFetchedUsersByPages(load_users_by_pages, page_to_load))    //// load_users_by_pages

        // @ts-ignore нужно обновлять значение общего количества юзеров в группе
        yield put(selectDepGroup(depId, total_users - 1))   //// common

        // @ts-ignore
        let found_dep_common = common_data_context.find(dep => dep.id === depId)
        if (found_dep_common) found_dep_common['number_of_employees'] = found_dep_common.number_of_employees - 1

        // @ts-ignore
        yield put(setDepartments(common_data_context))     //// common
        yield put(setUsers(depGroupUsers.filter(user => user.id !== userId)))   //// depGroupUsers

        CreateNotif('Пользователь удален из отдела', 'success')
    }
}

// function* removeUserFromGroup({groupId, userId}: removeUserFromGroupAction) {
//     const result = yield call(fetchData.delete, `${api_prefix}workgroups/${groupId}/users/${userId}`)
//     if (result && result.success) {
//         let groups = store.getState().commonInfo.workgroups
//         // удаляю пользователя из группы, в котором он больше не состоит
//         let {load_users_by_pages} = store.getState().usersPage
//         let user = load_users_by_pages.find(usr => usr.id === userId)
//         let found_group = user?.work_groups?.indexOf(groupId)
//         // @ts-ignore
//         if (found_group > -1) {
//             if (found_group != null) {
//                 user?.work_groups?.splice(found_group, 1);
//             }
//         }
//         yield put(putFetchedUsersByPages(load_users_by_pages))
//
//         let {total_users} = store.getState().usersPage
//         let users = store.getState().usersPage.depGroupUsers.slice()
//         yield put(setUsers(users.filter(user => user.id !== userId)))
//
//         // @ts-ignore нужно обновлять значение общего количества юзеров в группе
//         yield put(selectDepGroup(groupId, total_users - 1))
//
//         let found_group_common = groups.find(group => group.id === groupId)
//         if (found_group_common) {
//             found_group_common['number_of_employees'] = found_group_common.number_of_employees - 1
//         }
//         yield put(setWorkgroups(groups))
//
//         CreateNotif('Пользователь удален из группы', 'success')
//     }
// }

function* getNextPageAmongAll({filter, page}: getNextPageAmongAllAction) {
    // page - необязательный параметр. Если он есть - значит пришел запрос с новым фильтром, соответственно спрашивает страницу 1. Если его нет - берется из редюсера.
    const {page_to_load, load_users_by_pages} = store.getState().usersPage
    const data = yield call(fetchData.get, `/api/v1/companies/1/users?page=${page ? page : page_to_load}${filter ? `&search=${filter}` : ''}&order=surname&ordertype=desc`)
    if (data.length) {
        if (page) yield put(putFetchedUsersByPages(data, page))
        else yield put(putFetchedUsersByPages([...load_users_by_pages, ...data], page_to_load))
    }
}


export function* watchGetNextPageAmongAll() {
    yield takeEvery(types.GET_NEXT_PAGE_AMONG_ALL, getNextPageAmongAll)
}


export function* watchSetTableOrderUsers() {
    yield takeEvery(types.SET_TABLE_ORDER_USERS, setTableOrder)
}

export function* watchUpdateDepGroupInfo() {
    yield takeEvery(types.UPDATE_DEP_GROUP_INFO, updateDepGroup)
}

export function* watchFetchUsers() {
    yield takeLatest(types.FETCH_USERS, fetchUsers)
}

export function* watchOpenDeps() {
    yield takeLatest(types.OPEN_DEPS, openDeps)
}

// export function* watchOpenGroups() {
//     yield takeLatest(types.OPEN_GROUPS, openGroups)
// }

export function* watch_delete_dep_group() {
    yield takeEvery(types.DELETE_DEP_GROUP, delete_dep_group)
}

export function* watchCreateDep() {
    yield takeLatest(types.SAVE_DEP, createDep)
}

export function* watchCreateGroup() {
    yield takeLatest(types.SAVE_GROUP, createGroup)
}

export function* watchGetDepGroupUsers() {
    // yield takeLatest(types.GET_USERS, getUsers)
}

export function* watchAddUserToDepartment() {
    yield takeEvery(types.ADD_USER_TO_DEP_GROUP, addUserToDepGroup)
}

export function* watchAddUserToGroup() {
    // yield takeEvery(types.ADD_USER_TO_GROUP, addUserToGroup)
}

export function* watchRemoveUserFromDepartment() {
    yield takeEvery(types.REMOVE_USER_FROM_DEP_GROUP, removeUserFromDepartment)
}

export function* watchRemoveUserFromGroup() {
    // yield takeEvery(types.REMOVE_USER_FROM_GROUP, removeUserFromGroup)
}