import {types, fetchTasksAction} from './action_types'
import {takeEvery, call, put} from 'redux-saga/effects'
import {fetchData} from '../../utils/fetchData'
import {changeTaskList, setIsFetchingTasks} from './actions'
import {store} from '../../App'
import {CollectAllFilters, sorting, table_filter} from "../../common/shared_data"


export function* watchFiltersActualToggleProjects() {
    yield takeEvery(types.SET_ALL_OR_ACTUAL_PROJECTS, apply_filters_orders)
}

export function* watchSetStatusFilterProjects() {
    yield takeEvery(types.SET_FILTERS_PROJECTS, apply_filters_orders)
}

export function* watchSetTableOrderProjects() {
    yield takeEvery(types.SET_TABLE_ORDER, apply_filters_orders)
}

export function* watchFetchProjectTasks() {
    yield takeEvery(types.FETCH_TASKS, fetchTasks)
}


function* fetchTasks({projectId, pageNum}: fetchTasksAction) {
    const {
        isFetchingTasks: isLoading,
        tasks,
        tableFilter,
        tasksOrder,
        filters,
        tableOrTreeToggler,
        selectedProject
    } = store.getState().projectsPage

    if (!isLoading && pageNum === 1) yield put(setIsFetchingTasks(true))
    let response
    if (projectId) {
        if (tableOrTreeToggler === 'table') {
            response = yield call(fetchData.get,
                `/api/v1/tasks?project_id=${projectId}&page=${pageNum}` + display_type(filters) + sorting(tasksOrder) + CollectAllFilters(filters) + table_filter(tableFilter)
            )
        } else if (tableOrTreeToggler === 'tree') {
            response = yield call(fetchData.get,
                `/api/v1/projects/${projectId}/trees?` + display_type(filters) + sorting(tasksOrder) + CollectAllFilters(filters) + table_filter(tableFilter)
            )
        }
    }
    if (response) yield put(changeTaskList([...tasks, ...response]))
    yield put(setIsFetchingTasks(false))
}


function* apply_filters_orders() {
    const {filters, tasksOrder, tableFilter, selectedProject, tableOrTreeToggler} = store.getState().projectsPage
    let response
    if (tableOrTreeToggler === 'table') {
        response = yield call(fetchData.get,
            `/api/v1/tasks?project_id=${selectedProject?.id}&page=1` + display_type(filters) + sorting(tasksOrder) + CollectAllFilters(filters) + table_filter(tableFilter)
        )
    } else if (tableOrTreeToggler === 'tree') {
        response = yield call(fetchData.get,
            `/api/v1/projects/${selectedProject?.id}/trees?` + display_type(filters) + sorting(tasksOrder) + CollectAllFilters(filters) + table_filter(tableFilter)
        )
    }
    if (response.data) yield put(changeTaskList([...response.data]))
    else yield put(changeTaskList([...response]))
}

function display_type(argument) {
    return argument.exec_auth === '' ? '&displayType=relevant' : ''
}


