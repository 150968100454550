import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { State } from '../../../rootReducer';
import './styles.sass'



const CommonInfoPreloader = ({dataWasFetched}) => {

    const [showPrelod, setShow] = useState(true)

    useEffect(() => {
        if (dataWasFetched) setTimeout(() => setShow(false), 300)
    }, [dataWasFetched])

    return showPrelod ? (
        <div className="common_preloader_wrapper">
            <div className="common_perloader"/>
            <div className="shadow"/>
        </div>
    ) : null
}


const mapStateToProps = (state: State) => {
    return {
        dataWasFetched: state.commonInfo.wasFetched
    }
}


export default connect(mapStateToProps)(CommonInfoPreloader)