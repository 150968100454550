import React, { useCallback, useEffect } from 'react';
import TaskDateTime from '../../../common/components/DatePickers/DateTimePiker';
import withTitle from '../../../utils/hoc/withTitle';
import { connect } from 'react-redux';
import { recalculateTaskLoad, setFinishCyclick, setTaskEnd } from '../actions';
import { State } from '../../../rootReducer';
import { updateTask } from '../../../common/actions';
import SelectWithSecondaryBtn from '../../../common/components/Selects/SelectWithSecondaryBtn';
import { begin } from '../reducer';
import styled from 'styled-components';
import CustomInput from '../../../common/components/newComponent/Inputs/CustomInput';

const WrapperParametr = styled.div`
  margin-left: 8px;
  span {
    margin-left: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #212121;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const RepeatTask = ({
  disabledComponent,
  selectedTask,
  updateTask,
  repeat,
  setFinishCyclick,
}) => {
  const valueSettingFinish = {
    never: 0,
    'after count': 1,
    'after date': 2,
  };

  const repeat_end = [
    { text: 'Никогда', value: 'never' },
    { text: 'После', value: 'after count' },
    { text: 'В дату', value: 'after date' },
  ];

  const stop_list = [
    { text: '1 раза', value: 1 },
    { text: '2 раз', value: 2 },
    { text: '3 раз', value: 3 },
    { text: '4 раз', value: 4 },
    { text: '5 раз', value: 5 },
    { text: '6 раз', value: 6 },
  ];

  const acceptHandler = (e): any => {
    let date = e.split(' ')[0];
    setFinishCyclick(repeat.ending_condition, repeat.end_count, e);
  };
  const selectHandler = (value) => {
    if (value === 'after date') {
      // console.log(begin, begin.split(' ')[0])

      setFinishCyclick(value, null, begin);
    } else if (value === 'after count') {
      setFinishCyclick(value, 1, null);
    } else {
      setFinishCyclick(value, null, null);
    }
  };

  // console.log(begin)

  return (
    <div className={'endRepeatTask'}>
      <SelectWithSecondaryBtn
        list={repeat_end}
        selected={repeat_end[valueSettingFinish[repeat.ending_condition]]}
        disabled={disabledComponent}
        selectHandler={selectHandler}
      />
      <WrapperParametr>
        {repeat.ending_condition === 'after count' && (
          <>
            <CustomInput
              height="42px"
              width="58px"
              style={{ textAlign: 'center' }}
              type="number"
              disabled={disabledComponent}
              value={repeat.end_count}
              inputChangeHandler={(value) => {
                let counter = +value;
                if (counter < 0) counter = 0;

                setFinishCyclick(repeat.ending_condition, counter);
              }}
            />
            <span>раз(а)</span>
          </>
          // <SelectWithSecondaryBtn
          //   list={stop_list}
          //   selected={repeat.end_count}
          //   disabled={disabledComponent}
          //   selectHandler={(value) => {
          //     console.log(value);
          //     setFinishCyclick(
          //       repeat.ending_condition,
          //       stop_list[(value)].value
          //     );
          //   }}
          // />
        )}

        {repeat.ending_condition === 'after date' && (
          <TaskDateTime
            disabled={disabledComponent}
            acceptHandler={acceptHandler}
            hideTimeBlock={true}
            date={repeat.end_date || new Date()}
            //minDate={new Date()}
          />
        )}
      </WrapperParametr>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  // console.log(state.taskInfoPlate.repeat)
  return {
    selectedTask: state.taskInfoPlate.selectedTask,
    repeat: state.taskInfoPlate.repeat,
    end: state.taskInfoPlate.end,
    minDate: state.taskInfoPlate.begin,
    delegated: state.taskInfoPlate.delegated,
  };
};

const mapDispatchToProps = {
  setFinishCyclick,
  updateTask,
  setTaskEnd,
  recalculateTaskLoad,
};

export default connect(mapStateToProps, mapDispatchToProps)(RepeatTask);
