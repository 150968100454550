import React, {useCallback, useState, useRef, useEffect} from 'react';
import AddButton from '../../../common/components/Buttons/AddButton';
// import DisplayTypeSelect from '../../../common/components/Selects/DisplayTypeSelect'
import UserProjectSelect from '../../../common/components/Selects/UserProjectSelect';
import {Project, SelectData, Section, Task} from '../../../common/types';
import {connect} from 'react-redux';
import {
    setProjectAddMode,
    selectProject,
    setIsFetchingTasks,
    setTableFilter,
    changeTaskList,
    fetchTasks,
    setShowColumnsProjectsCustom, setTableOrTreeToggler,
} from '../actions';
import {getProjects} from '../../../common/selectors';
import {State} from '../../../rootReducer';
import {getProjectSelectData} from '../selectors';
import DotsButton from '../../../common/components/Buttons/DotsButton';
import {openExistingProject} from '../../NewProject/actions';
import {useParams} from 'react-router-dom';
import {fetchData} from '../../../utils/fetchData';
import TableSearch from '../../../common/components/TableSearch/TableSearch';
import {
    changeTaskListAction,
    fetchTasksAction,
    setTableFilterAction,
} from '../action_types';
import FilterButton from '../../../common/components/Buttons/filterButton';
import ViewWeekRoundedIcon from '@material-ui/icons/ViewWeekRounded';
import ProjectsFilters from '../../Projects/components/ProjectFilters';
import ColumnsWindow from '../../../common/ColumnsCustomWindow';
import Button from '../../../common/components/newComponent/Buttons/Button';
import Toggler from "../../../common/components/Togglers/Toggler";

type Props = {
    projects: Project[];
    selectData: SelectData;
    sections: Section[];
    selectedProject: Project | null;
    openExistingProject: (project: Project) => void;
    setProjectAddMode: (mode: boolean) => void;
    selectProject: (project: Project | null) => void;
    setIsFetchingTasks: (isFetching: boolean) => void;
    currentUserRoles: number[] | undefined;
    tableFilter: string;
    setTableFilter: (filter: string) => setTableFilterAction;
    changeTaskList: (tasks: Task[]) => changeTaskListAction;
    fetchTasks: (projecId: number, pageNum: number) => fetchTasksAction;
    projectId: number | null;
    appliedFiltersExist: boolean;
    showColumnsWindow: boolean;
    showFiltersWindow: boolean;
    setShowColumnsProjectsCustom: (show: boolean) => void,
    setTableOrTreeToggler: (val: string) => void,
    tableOrTreeToggler: string
};

const ProjectActions: React.FC<Props> = ({
                                             setShowColumnsProjectsCustom,
                                             appliedFiltersExist,
                                             showFiltersWindow,
                                             showColumnsWindow,
                                             tableFilter,
                                             projectId,
                                             setTableFilter,
                                             changeTaskList,
                                             fetchTasks,
                                             projects,
                                             selectedProject,
                                             sections,
                                             setProjectAddMode,
                                             selectProject,
                                             setIsFetchingTasks,
                                             openExistingProject,
                                             selectData,
                                             currentUserRoles,
                                             setTableOrTreeToggler,
                                             tableOrTreeToggler
                                         }) => {
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(
        null
    );
    // создал изолированную копию объекта, чтобы не перезаписать пропс
    const newRolesArr = currentUserRoles?.map((item) => {
        return item;
    });
    newRolesArr?.sort();

    const [showMenu, setShow] = useState<boolean>(false)
    const {id} = useParams();
    const menu = useRef<HTMLDivElement>(null);

    const outsideClick = useCallback((e: Event) => {
        if (!menu.current?.contains(e.target as HTMLElement)) setShow(false);
    }, []);

    useEffect(() => {
        document.addEventListener('click', outsideClick);
        return () => document.removeEventListener('click', outsideClick);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const editClickHandler = useCallback(async () => {
        const sections = await fetchData.get(`/api/v1/projects/${id}/sections`);
        if (sections) {
            openExistingProject({
                ...selectedProject,
                sections,
            } as Project);
            setProjectAddMode(true);
        }
    }, [id, openExistingProject, selectedProject, setProjectAddMode]);
    const searchChangeHandler = useCallback(
        (filter: string) => {
            if (!projectId) return;
            setTableFilter(filter);
            if (timer) clearTimeout(timer);
            setTimer(
                setTimeout(() => {
                    changeTaskList([]);
                    fetchTasks(projectId, 1);
                }, 500)
            );
        },
        [changeTaskList, fetchTasks, projectId, setTableFilter, timer]
    );

    return (
        <div className="projects_actions main_actions grid">
            {selectData.list.length > 0 && (
                <div className="project_select_wrapper">
                    <UserProjectSelect
                        type="projects"
                        itemsList={selectData.list.sort((a, b) =>
                            a.text > b.text ? 1 : -1
                        )}
                    />
                    {selectedProject && (
                        <DotsButton
                            clickHandler={() => setTimeout(setShow, 0, true)}
                            active={showMenu}
                        >
                            {showMenu && (
                                <div className="project_menu">
                                    <div onClick={editClickHandler} className="edit_project">
                                        Редактировать
                                    </div>
                                </div>
                            )}
                        </DotsButton>
                    )}

                    <Toggler elemsList={[{name: 'Таблица', value: 'table'}, {name: 'Дерево', value: 'tree'}]}
                             selectHandler={(active) => {

                                 if (tableOrTreeToggler && tableOrTreeToggler !== active) {
                                     setTableOrTreeToggler(active as string)
                                     localStorage.setItem('project_show_toggle', active as string)
                                 }
                             }}
                             activeValue={tableOrTreeToggler as string}
                    />
                </div>
            )}

            <div className="sub_actions">
                <div
                    onClick={() => {
                        setShowColumnsProjectsCustom(!showColumnsWindow);
                    }}
                    className={`columns_select${showColumnsWindow ? ' active' : ''}`}
                />

                <TableSearch
                    searchValue={tableFilter}
                    setSearchValue={searchChangeHandler}
                    isOpen={!!tableFilter}
                />
            </div>

            <FilterButton
                context={'projects'}
                text="Фильтр"
                active={appliedFiltersExist}
                addedClass={' filter_btn'}
            />

            {/*<DisplayTypeSelect selectedElem={{ text: 'Список', value: 'list' }} selectHandler={() => { }} />*/}
            {newRolesArr && newRolesArr[0] < 3 && (
                <Button
                    title="Проект"
                    icon="plus_circle"
                    style={{padding: '.5rem', width: '100px'}}
                    onClick={() => setProjectAddMode(true)}
                />
            )}

            {showFiltersWindow && <ProjectsFilters/>}
            {showColumnsWindow && <ColumnsWindow context={'projects'}/>}
        </div>
    );
};

const mapStateToProps = (state: State) => {
    return {
        appliedFiltersExist: Boolean(
            Object.keys(state.projectsPage.filters.appliedFilters).length
        ),
        projects: getProjects(state),
        selectData: getProjectSelectData(state),
        selectedProject: state.projectsPage.selectedProject,
        sections: state.commonInfo.sections,
        showFiltersWindow: state.projectsPage.filtersWindowShow,
        currentUserRoles: state.commonInfo.currentUserInfo?.roles,
        tableFilter: state.projectsPage.tableFilter,
        projectId: state.projectsPage.selectedProject?.id || null,
        showColumnsWindow: state.projectsPage.showColumnsWindow,
        tableOrTreeToggler: state.projectsPage.tableOrTreeToggler
    };
};

const mapDispatchToProps = {
    setProjectAddMode,
    setTableFilter,
    setShowColumnsProjectsCustom,
    changeTaskList,
    fetchTasks,
    selectProject,
    setIsFetchingTasks,
    openExistingProject,
    setTableOrTreeToggler
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectActions);
