import React, {useCallback} from 'react';


type Props = {
    name: string
    selected: boolean,
    addHandler: () => void,
    removeHandler: () => void
}


const ScheduleDay: React.FC<Props> = ({name, selected, addHandler, removeHandler}) => {

    const clickHandler = useCallback(() => {
        if (selected) removeHandler()
        else addHandler()
    }, [addHandler, removeHandler, selected])


    return (
        <div onClick={clickHandler} className={`schedule_day${selected ? ' selected' : ''}`}>
            {name}
        </div>
    )
}


export default ScheduleDay