import React, {useCallback, useEffect} from 'react';
import TaskDateTime from '../../../common/components/DatePickers/DateTimePiker';
import withTitle from '../../../utils/hoc/withTitle';
import {connect} from 'react-redux';
import {recalculateTaskLoad, sendReqToChangeTemplRepTask, setCyclicTaskToChange, setTaskBegin} from '../actions';
import {State} from '../../../rootReducer';
import {Task} from "../../../common/types";
import {updateTask} from "../../../common/actions";

type Props = {
    begin: string;
    maxDate: string;
    delegated: boolean;
    setTaskBegin: (begin: string) => void;
    recalculateTaskLoad: () => void,
    selectedTask: any,
    flagForActionCyclycTask: string,
    setCyclicTaskToChange: (obj: {}) => void,
    sendReqToChangeTemplRepTask: (obj: {}) => void,
    updateTask: (id: number, params: any, withNotif?: boolean) => void
};

const TaskBegin: React.FC<Props> = ({
                                        begin,
                                        maxDate,
                                        delegated,
                                        setTaskBegin,
                                        recalculateTaskLoad,
                                        selectedTask,
                                        updateTask,
                                        flagForActionCyclycTask,
                                        setCyclicTaskToChange,
                                        sendReqToChangeTemplRepTask
                                    }) => {

    const acceptHandler = useCallback(function (e): any {
        setTaskBegin(e)
        recalculateTaskLoad()
        if (selectedTask) {

            if (selectedTask.cyclic_task_id !== 0) {  // эта задача вообще циклическая или сама по себе ?
                // значит циклическая
                if (flagForActionCyclycTask === '') {
                    setCyclicTaskToChange({begin: e})  // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                } else if (flagForActionCyclycTask === 'task') {
                    updateTask(selectedTask!.id, {begin: e})
                } else {
                    updateTask(selectedTask!.id, {begin: e})
                    sendReqToChangeTemplRepTask({begin: e})
                }
            } else {
                updateTask(selectedTask!.id, {begin: e})
            }

        }
    }, [selectedTask])

    let [date, time] = selectedTask ? selectedTask.end.split(' ') : maxDate.split(' ')
    let maxDateObj = new Date(`${date.split('-').reverse().join('-')} ${time}`)

    // in particular cases, 'begin' date comes here in format dd/mm/yyyy hh:mm:ss
    // expected format is dd-mm-yyyy
    begin = begin.replace(/\//g, '-');

    return (
        <TaskDateTime
            disabled={delegated}
            acceptHandler={acceptHandler}
            date={selectedTask ? selectedTask.begin : begin}
            maxDate={maxDateObj}
        />
    );
};

const mapStateToProps = (state: State) => {
    return {
        selectedTask: state.taskInfoPlate.selectedTask,
        begin: state.taskInfoPlate.begin,
        maxDate: state.taskInfoPlate.end,
        delegated: state.taskInfoPlate.delegated,
        flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask
    };
};

const mapDispatchToProps = {
    updateTask,
    setTaskBegin,
    recalculateTaskLoad,
    setCyclicTaskToChange,
    sendReqToChangeTemplRepTask
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskBegin);
