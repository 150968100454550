class DateMaster {
  public date: Date;
  public day: number;
  public month: number;
  public year: number;
  public hours: number;
  public minutes: number;

  static weekDays = {
    0: 'Воскресенье',
    1: 'Понедельник',
    2: 'Вторник',
    3: 'Среда',
    4: 'Четверг',
    5: 'Пятница',
    6: 'Суббота',
  };

  static shortWeekDays = {
    0: 'Вс',
    1: 'Пн',
    2: 'Вт',
    3: 'Ср',
    4: 'Чт',
    5: 'Пт',
    6: 'Сб',
  };

  static monthsNamesByNumber = {
    0: {
      name: 'января',
      shortName: 'янв',
    },
    1: {
      name: 'февраля',
      shortName: 'фев',
    },
    2: {
      name: 'марта',
      shortName: 'мар',
    },
    3: {
      name: 'апреля',
      shortName: 'апр',
    },
    4: {
      name: 'мая',
      shortName: 'мая',
    },
    5: {
      name: 'июня',
      shortName: 'июн',
    },
    6: {
      name: 'июля',
      shortName: 'июл',
    },
    7: {
      name: 'августа',
      shortName: 'авг',
    },
    8: {
      name: 'сентября',
      shortName: 'сент',
    },
    9: {
      name: 'октября',
      shortName: 'окт',
    },
    10: {
      name: 'ноября',
      shortName: 'ноя',
    },
    11: {
      name: 'декабря',
      shortName: 'дек',
    },
  };

  static hoursPostfix = {
    час: [1, 21],
    часа: [2, 3, 4, 22, 23],
    часов: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
  };

  static dateFromIso(string: string) {
    let date = new Date(string),
      day = date.getDate(),
      month = date.getMonth(),
      year = date.getFullYear();
    return `${day} ${DateMaster.monthsNamesByNumber[month].name} ${year}`;
  }

  constructor(data: string | Date) {
    if (typeof data === 'string') {
      let [date, time] = data.split(' ');
      this.date = new Date(`${date.split('-').reverse().join('-')} ${time}`);
    } else {
      this.date = data;
    }
    this.day = this.date.getDate();
    this.month = this.date.getMonth();
    this.year = this.date.getFullYear();
    this.hours = this.date.getHours();
    this.minutes = this.date.getMinutes();
  }

  get deadline() {
    return `${this.day} ${DateMaster.monthsNamesByNumber[this.month].name}`;
  }
  get deadlineMini() {
    return `${this.day} ${
      DateMaster.monthsNamesByNumber[this.month].shortName
    }`;
  }
  get time() {
    return `${this.hours < 10 ? '0' + this.hours : this.hours}:${
      this.minutes < 10 ? '0' + this.minutes : this.minutes
    }`;
  }

  get commentDate(): string {
    let currentTime: number = Date.now(),
      date: number = this.date.valueOf(),
      difference: number = currentTime - date;

    if (difference < 3600000) {
      return `${Math.round(difference / 60000)} минут назад`;
    } else if (difference < 86400000) {
      let hours: number = Math.floor(difference / 3600000),
        postfix: string = 'часа';
      for (let key in DateMaster.hoursPostfix) {
        if (DateMaster.hoursPostfix[key].includes(hours)) postfix = key;
      }
      return `${hours} ${postfix} назад`;
    } else {
      return this.getDate();
    }
  }

  getDate(key = 'name') {
    if (isNaN(this.month)) return '';
    return `${this.day} ${DateMaster.monthsNamesByNumber[this.month][key]} ${
      this.year
    }`;
  }
}

export default DateMaster;
