export const getDifferenceDate = (
  day: number,
  month: number,
  year: number,
  taskDate: string
): number => {
  const nowDate = `${year}/${month}/${day}`;

  // let date1 = new Date(nowDate);
  // let date2 = new Date(taskDate);
  let start = Math.floor(new Date(nowDate).getTime() / (3600 * 24 * 1000));
  let end = Math.floor(new Date(taskDate).getTime() / (3600 * 24 * 1000));
  let daysDiff = end - start; // exact dates

  return daysDiff;
};
