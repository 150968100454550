import React, {useEffect} from 'react'
import CommonInfoPreloader from './CommonInfoPreloader/CommonInfoPreloader'
import Header from './Header/Header'
import ProjectAddPanel from '../../pages/NewProject/index'
import TaskAddPanel from '../../pages/NewTask/index'
import UserAddPanel from '../../pages/NewUser/index'
import UserChangePasswordWindow from '../../pages/NewUser/components/UserChangePasswordWindow'
import {connect} from 'react-redux'
import TaskInfoPlate from '../../pages/TaskInfoPlate/index'
import {State} from '../../rootReducer'
import {Route, useHistory, Switch, Redirect} from 'react-router-dom'
import Tasks from '../../pages/Tasks'
import Projects from '../../pages/Projects'
import Users from '../../pages/Users'
import {cookieMaster} from '../../utils/CookieMaster'
import {fetchCommonInfo} from '../actions'
import {Project} from '../types'
import DayClose from '../../pages/DayClose'
import NotificationWindow from "../NotificationWindow"
import AddDepPlate from "../../pages/Users/components/AddDepPlate"
import {setAddUserToGroupDep, setDepGroupType} from "../../pages/Users/actions"
import AddUsersPlate from "../../pages/Users/components/AddUsersPlate/AddUsersPlate"
import RepeatCustomWindow from "../../pages/TaskInfoPlate/components/RepeatCustomWindow"
import ConfirmEditRepeatTask from "../../pages/TaskInfoPlate/components/ConfirmEditRepeatTask";


type Props = {
    projectAddMode: boolean,
    taskAddMode: boolean,
    userAddMode: boolean,
    isShownTaskInfoPlate: boolean,
    isShownDayClose: boolean,
    currentId: number,
    projects: Project[],
    fetchCommonInfo: () => void,
    changePassWindowShow: boolean,
    show_notification_window: boolean,
    setDepGroupType: (value: string | null) => void,
    showAddUserToGroupDep: boolean,
    showWindowCreateDepGroup: boolean,
    plateType: string | null,
    selectedDepGroupId: null | number,
    repeat_custom_window: boolean,
    showEditCyclicTaskWindow: {}
}


const Main: React.FC<Props> = ({
                                   setDepGroupType,
                                   repeat_custom_window,
                                   selectedDepGroupId,
                                   plateType,
                                   showWindowCreateDepGroup,
                                   showAddUserToGroupDep,
                                   show_notification_window,
                                   taskAddMode,
                                   projectAddMode,
                                   userAddMode,
                                   isShownTaskInfoPlate,
                                   currentId,
                                   projects,
                                   isShownDayClose,
                                   fetchCommonInfo,
                                   changePassWindowShow,
                                   showEditCyclicTaskWindow
                               }) => {

    let history = useHistory()

    useEffect(() => {
        let token = cookieMaster.getCookie('access_token')
        if (token) {
            fetchCommonInfo()
        } else {
            history.replace('/login')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const blur = projectAddMode || taskAddMode || userAddMode || isShownDayClose || showWindowCreateDepGroup || showAddUserToGroupDep || repeat_custom_window || Boolean(Object.keys(showEditCyclicTaskWindow).length)
    return (
        <>
            <main className={`${blur ? 'blur' : ''}`}>
                <CommonInfoPreloader/>
                <Header/>
                <Switch>
                    <Route path="/tasks/:id" component={Tasks}/>
                    {/*<Route path="/tasks/:id/:task_id" component={Tasks}/>*/}
                    <Route exact path="/tasks" render={() => <Redirect to={`/tasks/${currentId}`}/>}/>
                    <Route path="/projects/:id" component={Projects}/>
                    <Route exact path="/projects" render={() => <Redirect to={`/projects/${projects[0]?.id || ''}`}/>}/>
                    <Route path={["/users/:id", "/users"]} component={Users}/>
                </Switch>

                <TaskInfoPlate/>
            </main>
            {projectAddMode && <ProjectAddPanel/>}
            {taskAddMode && <TaskAddPanel/>}
            {userAddMode && <UserAddPanel/>}


            {showWindowCreateDepGroup && <AddDepPlate setShow={() => {
                // если ни группа ни отдел сейчас не активны - то можно плашку обнулить. В противном случае - нельзя.
                if (selectedDepGroupId === null) setDepGroupType(null)
            }}/>}
            {showAddUserToGroupDep && <AddUsersPlate setShow={() => {
                setAddUserToGroupDep(false)
            }}/>}


            {Boolean(repeat_custom_window) && <RepeatCustomWindow/>}

            {Object.keys(showEditCyclicTaskWindow).length > 0 && <ConfirmEditRepeatTask/>}

            {changePassWindowShow && <UserChangePasswordWindow/>}

            {isShownDayClose && <DayClose/>}

            {show_notification_window && <NotificationWindow/>}
        </>
    )
}


const mapStateToProps = (state: State) => {
    // console.log(state)
    return {
        selectedDepGroupId: state.usersPage.selectedDepGroupId,
        showWindowCreateDepGroup: state.usersPage.showWindowCreateDepGroup,
        showAddUserToGroupDep: state.usersPage.showAddUserToGroupDep,
        plateType: state.usersPage.depGroupPlateType,
        show_notification_window: state.commonInfo.show_notification_window,
        projectAddMode: state.projectsPage.projectAddMode,
        taskAddMode: state.tasksPage.taskAddMode,
        userAddMode: state.usersPage.userAddMode,
        isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
        isShownDayClose: state.tasksPage.isShownDayClose,
        currentId: state.commonInfo.current_user,
        projects: state.commonInfo.projects,
        changePassWindowShow: state.newUser.changePassWindowShow,
        repeat_custom_window: state.taskInfoPlate.repeat.show_modal_window,
        showEditCyclicTaskWindow: state.taskInfoPlate.rememberObjectCyclicTaskToChange
    }
}

const mapDispatchToProps = {
    fetchCommonInfo,
    setDepGroupType
}


export default connect(mapStateToProps, mapDispatchToProps)(Main)