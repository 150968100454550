import React, { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import HeaderAvatar from './HeaderAvatar';
import {
  selectTask,
  setShownTaskInfoPlate,
} from '../../../pages/TaskInfoPlate/actions';
import { connect } from 'react-redux';
import { setLoadingTasks } from '../../../pages/Tasks/actions';
import { State } from '../../../rootReducer';
import { setIsFetchingUsers } from '../../../pages/Users/actions';
import { setIsFetchingTasks } from '../../../pages/Projects/actions';
import './styles.sass';
import { showNotifWindow } from '../../actions';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import NotificationWindow from '../../NotificationWindow';

const Header = ({
  selectedTask,
  showNotifWindow,
  notifications,
  show_notification_window,
  selectTask,
  isShownTaskInfoPlate,
  setShownTaskInfoPlate,
  setLoadingTasks,
  setIsFetchingUsers,
  setIsFetchingTasks,
}) => {
  const location = useLocation();

  const navClickHandler = useCallback(
    (e) => {
      if (!e.target.closest('a')) return;
      if (isShownTaskInfoPlate) setShownTaskInfoPlate(false);
      if (selectedTask) selectTask(null);
    },
    [isShownTaskInfoPlate, selectTask, selectedTask, setShownTaskInfoPlate]
  );

  return (
    <header className="grid">
      <div className="logo" />
      <nav onClick={navClickHandler} className="navigation">
        <Link
          tabIndex={-1}
          to="/tasks"
          onClick={() => {
            if (location.pathname !== '/tasks') setLoadingTasks(true);
          }}
        >
          <div
            className={
              `header_icon_round ` +
              (location.pathname.match('/tasks') ? 'active' : '')
            }
          >
            <img
              src={require('../../../img/task.svg')}
              title="Задачи"
              alt={'Задачи'}
            />
          </div>
        </Link>
        <Link
          tabIndex={-1}
          to="/projects"
          onClick={() => {
            if (!location.pathname.match('/projects')) setIsFetchingTasks(true);
          }}
        >
          <div
            className={
              `header_icon_round ` +
              (location.pathname.match('/projects') ? 'active' : '')
            }
          >
            <img
              src={require('../../../img/folder.svg')}
              title="Проекты"
              alt={'Проекты'}
            />
          </div>
        </Link>
        <Link
          tabIndex={-1}
          to="/users"
          onClick={() => {
            if (location.pathname !== '/users') setIsFetchingUsers(true);
          }}
        >
          <div
            className={
              `header_icon_round ` +
              (location.pathname.match('/users') ? 'active' : '')
            }
          >
            <img
              src={require('../../../img/bi_people.svg')}
              title="Пользователи"
              alt={'Пользователи'}
            />
          </div>
        </Link>

        <div
          className={'header_icon_borderless'}
          onClick={() => showNotifWindow(!show_notification_window)}
        >
          <img
            src={require('../../../img/ion_notifications-outline.svg')}
            title="Уведомления"
            alt={'Уведомления'}
          />
          {Boolean(notifications.length) && <FiberManualRecordRoundedIcon />}
        </div>
      </nav>
      <HeaderAvatar />
      {show_notification_window && <NotificationWindow />}
    </header>
  );
};

const mapStateToProps = (state: State) => ({
  notifications: state.commonInfo.notifications,
  show_notification_window: state.commonInfo.show_notification_window,
  selectedTask: state.taskInfoPlate.selectedTask,
  isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
});

const mapDispatchToProps = {
  selectTask,
  setShownTaskInfoPlate,
  setLoadingTasks,
  setIsFetchingUsers,
  setIsFetchingTasks,
  showNotifWindow,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
