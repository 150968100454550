import {
    types,
    setAddUserToGroupDepAction,
    setUserAddModeAction,
    setDepFilterAction,
    openDepGroupPlateAction,
    openDepsAction,
    selectDepGroupAction,
    saveDepAction,
    fetchUsersAction,
    setIsFetchingUsersAction,
    isCreatingDepAction,
    setDepGroupTypeAction,
    openGroupsAction,
    setUsersAction,
    addUserToDepGroupAction,
    addUserToGroupAction,
    removeUserFromDepGroupAction,
    removeUserFromGroupAction,
    saveGroupAction,
    setTableFilterAction,
    setTableOrderAction,
    showWindowCreateDepGroupAction,
    updateDepGroupInfoAction,
    deleteDepGroupAction,
    getNextPageAmongAllAction, putFetchedUsersByPagesAction
} from './action_types'
import {User} from '../../common/types'


export const setAddUserToGroupDep = (show: boolean): setAddUserToGroupDepAction => ({
    type: types.ADD_USER_TO_GROUP_DEP,
    show
})

export const setUserAddMode = (mode: boolean): setUserAddModeAction => ({
    type: types.SET_USER_ADD_MODE,
    mode
})

export const setDepFilter = (filter: string): setDepFilterAction => ({
    type: types.SET_DEP_FILTER,
    filter
})

export const openDepGroupPlate = (): openDepGroupPlateAction => ({
    type: types.OPEN_DEP_GROUP_PLATE
})

export const openDeps = (): openDepsAction => ({
    type: types.OPEN_DEPS
})

// export const openGroups = (): openGroupsAction => ({
//     type: types.OPEN_GROUPS
// })

export const selectDepGroup = (id: number | null, totalUsers: number | null): selectDepGroupAction => ({
    type: types.SELECT_DEP_GROUP,
    totalUsers,
    id
})

export const saveDep = (name: string, head_id: number): saveDepAction => ({
    type: types.SAVE_DEP,
    name,
    head_id
})

export const saveGroup = (name: string, head_id: number): saveGroupAction => ({
    type: types.SAVE_GROUP,
    name,
    head_id
})

export const fetchUsers = (pageNum: number): fetchUsersAction => ({
    type: types.FETCH_USERS,
    pageNum
})

export const setIsFetchingUsers = (isFetchingUsers: boolean): setIsFetchingUsersAction => ({
    type: types.SET_IS_FETCHING_USERS,
    isFetchingUsers
})

export const isCreatingDep = (isCreatingDep: boolean): isCreatingDepAction => ({
    type: types.IS_CREATING_DEP,
    isCreatingDep
})

export const setDepGroupType = (plateType: string | null): setDepGroupTypeAction => ({
    type: types.SET_DEP_GROUP_PLATE_TYPE,
    plateType
})

export const setUsers = (users: User[]): setUsersAction => ({
    type: types.SET_USERS,
    users
})

export const addUserToDepGroup = (depId: number, user: User): addUserToDepGroupAction => ({
    type: types.ADD_USER_TO_DEP_GROUP,
    depGroupId: depId,
    user
})

export const addUserToGroup = (groupId: number, user: User): addUserToGroupAction => ({
    type: types.ADD_USER_TO_GROUP,
    groupId,
    user
})

export const removeUserFromDepGroup = (depId: number, userId: number): removeUserFromDepGroupAction => ({
    type: types.REMOVE_USER_FROM_DEP_GROUP,
    depId,
    userId
})

export const removeUserFromGroup = (groupId: number, userId: number): removeUserFromGroupAction => ({
    type: types.REMOVE_USER_FROM_GROUP,
    groupId,
    userId
})

export const setTableFilter = (filter: string): setTableFilterAction => ({
    type: types.SET_TABLE_FILTER,
    filter
})

export const setOrder = (col_name: string, order_direct: boolean): setTableOrderAction => ({
    type: types.SET_TABLE_ORDER_USERS,
    col_name,
    order_direct
})


export const showWindowCreateDepGroup = (val: boolean): showWindowCreateDepGroupAction => ({
    type: types.SHOW_WINDOW_CREATE_DEPGROUP,
    val
})


export const updateDepGroupInfo = (name: string, head_id: number): updateDepGroupInfoAction => ({
    type: types.UPDATE_DEP_GROUP_INFO,
    name,
    head_id
})

export const deleteDepGroup = (): deleteDepGroupAction => ({
    type: types.DELETE_DEP_GROUP
})

export const get_next_page_among_all = (filter: string, page?: number): getNextPageAmongAllAction => ({
    type: types.GET_NEXT_PAGE_AMONG_ALL,
    filter,
    page
})

export const putFetchedUsersByPages = (users: User[], page?: number): putFetchedUsersByPagesAction => ({
    type: types.PUT_FETCHED_USERS_BY_PAGES,
    users,
    page
})
