import { fetchTasks } from './../Projects/actions';
import { Action } from 'redux';
import { Task } from '../../common/types';

export enum types {
  CHANGE_TASK_LIST = '@@TASKS/CHANGE_TASK_LIST',
  CHANGE_VISUALISATION_LIST = '@@TASKS/CHANGE_VISUALISATION_LIST', //Доработать в сслучае добавление подгрузки при скроле
  FETCH_TASKS = '@@TASKS/FETCH_TASKS',
  FETCH_TASKS_VISUALIZATION = '@@TASKS/FETCH_TASKS_VISUALIZATION',
  CLEAR_VISUALIZATION_LIST = '@@TASKS/CLEAR_VISUALIZATION_LIST',
  SET_SHOWN_TASK_INFO_PLATE = '@@TASKS/SET_SHOWN_TASK_INFO_PLATE',
  SET_TASK_ADD_MODE = '@@TASKS/SET_TASK_ADD_MODE',
  SET_FILTER_WIN_MODE = '@@TASKS/SET_FILTER_WIN_MODE',
  SET_LOADING_TASK = '@@TASKS/SET_LOADING_TASKS',
  SET_TABLE_FILTER = '@@TASKS/SET_TABLE_FILTER',
  SET_FILTERS = '@@TASKS/SET_FILTERS',
  SET_SELECTED_USER_ID = '@@TASK/SET_SELECTED_USER_ID',
  SET_SHOW_VISUALIZATION = '@@TASK/SET_SHOW_VISUALIZATION',
  SET_SHOW_DAYS_OFF = '@@TASK/SET_SHOW_DAYS_OFF',
  SET_STATUS_FILTER = '@@TASK/SET_STATUS_FILTER',
  SET_DRAFT_VALUE = '@@TASKS/SET_DRAFT_STATUS',
  SET_FILTERS_ADD_NEW_ROW = '@@TASKS/SET_FILTERS_ADD_NEW_ROW',
  SET_ALL_OR_ACTUAL = '@@TASKS/SET_ALL_OR_ACTUAL',
  SET_TABLE_ORDER = '@@TASKS/SET_TABLE_ORDER',
  SET_SHOW_COLUMNS_WINDOW = '@@TASKS/SET_SHOW_COLUMNS_WINDOW',
  SET_SHOWING_COLS = '@@TASKS/SET_SHOWING_COLS',
  SET_EXEC_AUTH = '@@TASKS/SET_EXEC_AUTH',
  LOAD_SPECIFIC_TASK = '@@TASKS/LOAD_SPECIFIC_TASK',
}

export interface fetchTasksAction extends Action<types.FETCH_TASKS> {
  pageNum: number;
}

export interface changeTaskListAction extends Action<types.CHANGE_TASK_LIST> {
  tasks: Task[];
}

export interface fetchTasksVisualizationAction
  extends Action<types.FETCH_TASKS_VISUALIZATION> {
  selectedUserId: number;
}
export interface changeVisualisationListAction
  extends Action<types.CHANGE_VISUALISATION_LIST> {
  tasks: Task[];
}

export interface clearVisualizationLish
  extends Action<types.CLEAR_VISUALIZATION_LIST> {}

export interface setTaskAddModeAction extends Action<types.SET_TASK_ADD_MODE> {
  mode: boolean;
}

export interface setFilterAddModeAction
  extends Action<types.SET_FILTER_WIN_MODE> {
  mode: boolean;
}

export interface setFilterDraftAction extends Action<types.SET_DRAFT_VALUE> {
  draft: number;
}

export interface setFilterAddNewRow
  extends Action<types.SET_FILTERS_ADD_NEW_ROW> {
  value: boolean;
}

export interface setLoadingTasksAction extends Action<types.SET_LOADING_TASK> {
  isLoading: boolean;
}

export interface setTableFilterAction extends Action<types.SET_TABLE_FILTER> {
  filter: string;
}

export interface setSelectedUserIdAction
  extends Action<types.SET_SELECTED_USER_ID> {
  id: number;
}

export interface setShowVisulaizationAction
  extends Action<types.SET_SHOW_VISUALIZATION> {
  show: boolean;
}

export interface setShowColumnsCustomAction
  extends Action<types.SET_SHOW_COLUMNS_WINDOW> {
  show: boolean;
}

export interface setShowDaysOffAction extends Action<types.SET_SHOW_DAYS_OFF> {
  show: boolean;
}

export interface setAllOrActualAction extends Action<types.SET_ALL_OR_ACTUAL> {
  value: object;
}

export interface setStatusFilterAction extends Action<types.SET_FILTERS> {
  filterObject: object;
}

export interface setTableOrderAction extends Action<types.SET_TABLE_ORDER> {
  col_name: string;
  order_direct: boolean;
}

export interface setShowableColumns extends Action<types.SET_SHOWING_COLS> {
  value: string;
}

export interface setExecAuthAction extends Action<types.SET_EXEC_AUTH> {
  val: string;
}

export interface loadSpecificTaskAction
  extends Action<types.LOAD_SPECIFIC_TASK> {
  value: number;
}

export type TasksAction =
  | fetchTasksAction
  | changeTaskListAction
  | setTaskAddModeAction
  | setFilterAddModeAction
  | setLoadingTasksAction
  | setTableFilterAction
  | setSelectedUserIdAction
  | setShowVisulaizationAction
  | setShowDaysOffAction
  | setStatusFilterAction
  | setFilterDraftAction
  | setFilterAddNewRow
  | changeVisualisationListAction
  | setAllOrActualAction
  | setTableOrderAction
  | setShowColumnsCustomAction
  | setShowableColumns
  | setExecAuthAction
  | loadSpecificTaskAction
  | clearVisualizationLish;
