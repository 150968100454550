import {takeLatest, call} from 'redux-saga/effects'
import {fetchData, loginBodyParts} from '../../utils/fetchData'
import qs from "querystring"
import {types, fetchTokenAction} from './action_types'

function* watchFetchToken() {
    yield takeLatest(types.FETCH_TOKEN, fetchToken)
}


function* fetchToken({email, password}: fetchTokenAction) {
    let body = {
        ...loginBodyParts,
        username: email,
        password: password
    }
    const data = yield call(fetchData.post, '/oauth/token', qs.stringify(body), {'Content-Type': 'application/x-www-form-urlencoded'})

    if (data?.access_token) {
        document.cookie = `access_token=${`${data.token_type} ${data.access_token}`}; path=/; expires=${data.expires_in}`
        document.cookie = `refresh_token=${data.refresh_token}; path=/; expires=${data.expires_in}`
        document.cookie = `user_id=${data.user_id}; path=/; expires=${data.expires_in}`
        window.location.replace('/tasks')
    }
}


export default watchFetchToken