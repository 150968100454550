//other
const white = '#ffffff';
const lightGray = '#e0e0e0';
const gray = '#A8A8A8';
const ultraLightGray = '#F2F2F2';

const textBlack = '#212121';
const blue = '#1baaf0';
const blueLight = '#F3FAFE';
const ultraBlueLighter = '#99D7F5';
const darkBlue = '#02405e';

const visualColor = '#FFF';
const visualBgBlue = '#1BAAF0';
const visualBgRed = '#FF6666';

const bgChips = '#E6ECEF';

const disabled = 'rgba(24, 9, 9, 0.02)';
const disabledText = '#2021249f';

//buttons
const btnPrimary = 'linear-gradient(180deg, #45BAF2 0%, #06A0EB 100%)';
const btnPrimaryHover = darkBlue;
const btnSecondary = lightGray;
const btnSecondaryHover = gray;

const palette = {
  visualColor,
  visualBgBlue,
  visualBgRed,

  white,
  gray,
  lightGray,
  ultraLightGray,

  textBlack,
  blue,
  blueLight,
  ultraBlueLighter,
  darkBlue,

  bgChips,

  disabled,
  disabledText,

  btnPrimary,
  btnPrimaryHover,
  btnSecondary,
  btnSecondaryHover,
};

export default palette;
