import { TableCell, withStyles, TableRow } from '@material-ui/core';

export const Cell = withStyles({
    root: {
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.56)',
        height: 72
    }
})(TableCell)

export const BodyCell = withStyles({
    root: {
        fontSize: 13,
        color: 'rgba(0, 0, 0, 0.87)',
        position: 'relative'
    }
})(TableCell)

export const Row = withStyles({
    root: {
        height: 72
    }
})(TableRow)