import React, { useCallback } from 'react';
import { uploadFile, deleteFile } from '../actions';
import { connect } from 'react-redux';
// import withTitle from '../../../utils/hoc/withTitle';
import FileElem from '../../../common/components/FileElem/FileElem';
import { File as FileType } from '../../../common/types';
import { State } from '../../../rootReducer';
import { getFiles } from '../selectors';
import { droppedItemsAnalize } from '../../../utils/droppedItemsAnalize';
import styled from 'styled-components';

type Props = {
  files: FileType[];
  uploadFile: (file: File) => void;
  deleteFile: (id: number) => void;
};
const WrapperListFile = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
`;
const Files: React.FC<Props> = ({ files, uploadFile, deleteFile }) => {
  const dropHandler = useCallback(
    (e) => {
      e.preventDefault();
      let items = e.dataTransfer.items;
      droppedItemsAnalize(uploadFile, ...items);
    },
    [uploadFile]
  );

  return (
    <>
      <div
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => dropHandler(e)}
        className="project_files is_drag_enter"
      />
      {files.length > 0 && (
        <WrapperListFile className="file_list">
          {files.map((file) => (
            <FileElem
              id={file.id}
              url={file.url}
              name={file.name}
              deleteHandler={deleteFile}
              key={file.name}
            />
          ))}
        </WrapperListFile>
      )}
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    files: getFiles(state),
  };
};

const mapDispatchToProps = {
  uploadFile,
  deleteFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Files);
