import { types, NewProjectAction } from './action_types';
import { File as FileType, SectionInComponent } from '../../common/types';
import { Reducer } from 'react';

const initialState = {
  id: 0,
  name: '',
  description: '',
  company_id: 1, // Пока одна компания
  workflow_id: 1,
  priority_id: 2,
  cpe_list: [] as number[],
  curators: [] as number[],
  head_of_departments: [] as number[],
  head_of_work_groups: [] as number[],
  files: [] as FileType[],
  sections: [] as SectionInComponent[],
  isSending: false,
};

export type NewProjectState = typeof initialState;

const reducer: Reducer<NewProjectState, NewProjectAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case types.SET_PROJECT_NAME:
      return {
        ...state,
        name: action.name,
      };
    case types.SET_PROJECT_DESCRIPTION:
      return {
        ...state,
        description: action.description,
      };
    case types.SET_PROJECT_SECTIONS:
      return {
        ...state,
        sections: action.sections,
      };
    case types.SET_PROJECT_FILES:
      return {
        ...state,
        files: action.files,
      };
    case types.SET_PROJECT_CURATORS:
      return {
        ...state,
        curators: action.curators,
      };
    case types.SET_PROJECT_CPE_LIST:
      return {
        ...state,
        cpe_list: action.cpeList,
      };
    case types.SET_PROJECT_CHIEF:
      return {
        ...state,
        head_of_departments: action.head_of_departments,
      };
    case types.SET_PROJECT_LEADER:
      return {
        ...state,
        head_of_work_groups: action.head_of_work_groups,
      };
    case types.SET_PROJECT_PRIORITY:
      return {
        ...state,
        priority_id: action.priority,
      };
    case types.SET_IS_SENDING:
      return {
        ...state,
        isSending: action.isSending,
      };
    case types.OPEN_EXISTING_PROJECT:
      return {
        ...state,
        ...action.project,
      };
    case types.CLEAR_PROJECT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
