import { takeEvery, call, put } from 'redux-saga/effects';
import {
  types,
  fetchTasksAction,
  fetchTasksVisualizationAction,
} from './action_types';
import { fetchData } from '../../utils/fetchData';
import {
  setLoadingTasks,
  changeTaskList,
  changeVisualisationList,
  clearVisualizationList,
} from './actions';
import { store } from '../../App';
import { selectTask, setShownTaskInfoPlate } from '../TaskInfoPlate/actions';
import {
  CollectAllFilters,
  sorting,
  table_filter,
} from '../../common/shared_data';
import { changeWorkSchedule } from '../../common/actions';

export function* watchFetchTasks() {
  yield takeEvery(types.FETCH_TASKS, fetchTasks);
}

export function* watchFetchTasksVisualization() {
  yield takeEvery(types.FETCH_TASKS_VISUALIZATION, fetchTasksVisualization);
}

export function* watchLoadSpecificTask() {
  yield takeEvery(types.LOAD_SPECIFIC_TASK, loadSpecificTask);
}

export function* watchSetStatusFilterTasks() {
  yield takeEvery(types.SET_FILTERS, apply_filters_orders);
}

export function* watchFiltersActualToggle() {
  yield takeEvery(types.SET_ALL_OR_ACTUAL, apply_filters_orders);
}

export function* watchSetTableOrderTasks() {
  yield takeEvery(types.SET_TABLE_ORDER, apply_filters_orders);
}

function* loadSpecificTask(data) {
  // console.log('/api/v1/tasks/' + data.value);
  // console.log('aaa')
  const response = yield call(fetchData.get, '/api/v1/tasks/' + data.value);
  if (response) {
    yield put(selectTask(response));
    yield put(setShownTaskInfoPlate(true));
  }
}

function* apply_filters_orders() {
  const {
    selectedUserId,
    filters,
    tasksOrder,
    tableFilter,
  } = store.getState().tasksPage;

  const response = yield call(
    fetchData.get,
    `/api/v1/tasks?page=1` +
      ActualOrAll(filters, selectedUserId, CollectAllFilters(filters)) +
      sorting(tasksOrder) +
      table_filter(tableFilter)
  );
  if (response) yield put(changeTaskList([...response]));
}

function* fetchTasks({ pageNum }: fetchTasksAction) {
  const {
    selectedUserId,
    tasks: taskList,
    tableFilter,
    isLoading,
    filters,
    tasksOrder,
  } = store.getState().tasksPage;
  if (!isLoading && pageNum === 1) yield put(setLoadingTasks(true));

  const tableRequest = yield call(
    fetchData.get,
    `/api/v1/tasks?page=${pageNum}` +
      ActualOrAll(filters, selectedUserId, CollectAllFilters(filters)) +
      sorting(tasksOrder) +
      table_filter(tableFilter)
  );
  if (tableRequest) yield put(changeTaskList([...taskList, ...tableRequest]));

  const workSchedule = yield call(
    fetchData.get,
    `/api/v1/users/${selectedUserId}/schedule`
  );

  if (workSchedule) yield put(changeWorkSchedule(workSchedule.schedule));

  const visualisationRequest = yield call(
    fetchData.get,
    '/api/v1/tasks?executor_id=' +
      selectedUserId +
      `&order=visual&orderType=deadline`
  );
  if (visualisationRequest && pageNum === 1) {
    // yield put(clearVisualizationList());
    yield put(changeVisualisationList(visualisationRequest));
  }

  if (visualisationRequest && visualisationRequest.length > 70) {
    visualisationRequest.splice(70);
    yield put(changeVisualisationList(visualisationRequest));
  } else if (visualisationRequest) {
    yield put(changeVisualisationList(visualisationRequest));
  }
  yield put(setLoadingTasks(false));
}

function* fetchTasksVisualization({
  selectedUserId,
}: fetchTasksVisualizationAction) {
  // yield put(clearVisualizationList());
  const visualisationRequest = yield call(
    fetchData.get,
    '/api/v1/tasks?executor_id=' +
      selectedUserId +
      `&order=visual&orderType=deadline`
  );
  if (visualisationRequest && visualisationRequest.length > 70) {
    visualisationRequest.splice(70);
    yield put(changeVisualisationList(visualisationRequest));
  } else if (visualisationRequest) {
    yield put(changeVisualisationList(visualisationRequest));
  }
}

function ActualOrAll(filters, selectedUserId, collectedFilters) {
  let prefix = '';
  if (filters.exec_auth === 'executor') {
    prefix += '&executor_id=';
  } else if (filters.exec_auth === 'author') {
    prefix += '&author_id=';
  } else {
    prefix += '&displayType=relevant' + '&userId=';
  }

  return prefix + selectedUserId + collectedFilters;
}
