import {combineReducers} from 'redux'
import taskReducer from './pages/Tasks/reducer'
import projectReducer from './pages/Projects/reducer'
import userReducer from './pages/Users/reducer'
import commonReducer from './common/commonReducer'
import newProjectReducer from './pages/NewProject/reducer'
import newTaskReducer from './pages/NewTask/reducer'
import taskInfoPlateReducer from './pages/TaskInfoPlate/reducer'
import newUserReducer from './pages/NewUser/reducer'
import { TasksState } from './pages/Tasks/reducer'
import { UsersState } from './pages/Users/reducer'
import { ProjectsState } from './pages/Projects/reducer'
import { CommonState } from './common/commonReducer'
import { NewProjectState } from './pages/NewProject/reducer'
import { NewUserState } from './pages/NewUser/reducer'
import { TaskInfoPlateState } from './pages/TaskInfoPlate/reducer'
import { NewTaskState } from './pages/NewTask/reducer'



export type State = {
    tasksPage: TasksState,
    projectsPage: ProjectsState,
    usersPage: UsersState,
    commonInfo: CommonState,
    newProject: NewProjectState,
    newUser: NewUserState,
    newTask: NewTaskState,
    taskInfoPlate: TaskInfoPlateState
}




export const rootReducer = combineReducers({
    tasksPage: taskReducer,
    projectsPage: projectReducer,
    usersPage: userReducer,
    commonInfo: commonReducer,
    newProject: newProjectReducer,
    newTask: newTaskReducer,
    newUser: newUserReducer,
    taskInfoPlate: taskInfoPlateReducer
})