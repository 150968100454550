// import { type } from 'os';
import React from 'react';
import styled from 'styled-components';

import Icon from '../Icon';
import palette from '../palette';

type Props = {
    checked?: boolean;
    onChange?: (e: Event) => void;
    disabled?: boolean;
};

const WrapperStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
`;
const BoxCheckboxLabel = styled.label`
  position: relative;
  display: flex;
  > input {
    display: none;
  }
`;

const CheckBoxStyled = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  width: 1rem;
  background-color: ${palette.white};
  border: 1px solid ${palette.darkBlue};
  border-radius: 4px;
  cursor: pointer;
`;

const CheckboxInput = styled.input`
  &:checked + ${CheckBoxStyled} {
    background-color: ${palette.blue};
    border-color: ${palette.blue};

    &:hover {
      background-color: ${palette.darkBlue};
      border-color: ${palette.darkBlue};
    }
  }
  &:checked:disabled + ${CheckBoxStyled} {
    background-color: ${palette.gray};
    border-color: ${palette.gray};
  }
  &:checked:disabled:hover + ${CheckBoxStyled} {
    background-color: ${palette.gray};
    border-color: ${palette.gray};
  }
`;

const Checkbox: React.FC<Props> = ({checked, onChange, disabled = false}) => {
    return (
        <WrapperStyled>
            <BoxCheckboxLabel>
                <CheckboxInput
                    onChange={onChange}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                />
                <CheckBoxStyled>{checked && <Icon name="whiteOK"/>}</CheckBoxStyled>
            </BoxCheckboxLabel>
        </WrapperStyled>
    );
};

export default Checkbox;
