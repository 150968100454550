import React, { useCallback, useState } from 'react';
import AddButton from '../../../common/components/Buttons/AddButton';
// import Tumbler from '../../../common/components/Buttons/Tumbler';
import { connect, useDispatch } from 'react-redux';
import {
  // setShowDepGroupPlate,
  setUserAddMode,
  openDepGroupPlate,
  openDeps,
  // openGroups,
  // setDepGroupType,
  selectDepGroup,
  setTableFilter,
  setUsers,
  fetchUsers,
} from '../actions';
// import {getDepGroupPlateType} from '../selectors'
import { State } from '../../../rootReducer';
import { useHistory } from 'react-router-dom';
// import UserProjectSelect from "../../../common/components/Selects/UserProjectSelect"
// import ViewWeekRoundedIcon from "@material-ui/icons/ViewWeekRounded"
// import UpdateRoundedIcon from "@material-ui/icons/UpdateRounded"
import TableSearch from '../../../common/components/TableSearch/TableSearch';
import { User } from '../../../common/types';
import Button from '../../../common/components/newComponent/Buttons/Button';
import { clearUser } from '../../NewUser/actions';
import styled from 'styled-components';

type Props = {
  tableFilter: string;
  // depGroupPlateType: string | null,
  // setShowDepGroupPlate: (show: boolean) => void,
  setUserAddMode: (mode: boolean) => void;
  openDeps: () => void;
  // openGroups: () => void,
  // setDepGroupType: (type: string | null) => void,
  selectDepGroup: (id: number | null, totalUsers: number | null) => void;
  currentUserRole: number | undefined;
  users_length: number;
  setTableFilter: (filter: string) => void;
  setUsers: (users: User[]) => void;
  fetchUsers: (pageNum: number) => void;
  // setOrder: any
};
const UserActionStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 24px 24px 24px;
`;
const RightBlockStyle = styled.div`
  display: flex;
`;

const UsersActions: React.FC<Props> = ({
  tableFilter,
  fetchUsers,
  setUsers,
  setTableFilter,
  users_length,
  setUserAddMode,
  openDeps,
  selectDepGroup,
  currentUserRole,
}) => {
  const dispath = useDispatch();
  const history = useHistory();
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(
    null
  );

  // при поиске в тулбаре, исполняется этот колбэк
  const searchChangeHandler = useCallback(
    (filter: string) => {
      setTableFilter(filter);
      if (timer) clearTimeout(timer);
      setTimer(
        setTimeout(() => {
          setUsers([]);
          fetchUsers(1);
        }, 500)
      );
    },
    [fetchUsers, setTableFilter, setUsers, timer]
  );

  return (
    <UserActionStyle
    // className="users_actions"
    >
      {/*<UserProjectSelect*/}
      {/*    type="tasks"*/}
      {/*    itemsList={dataForUserSelect.list}*/}
      {/*/>*/}

      <div className="main_title">
        <div>Штат</div>
        <div className={'countUsers'}>{users_length} сотрудников</div>
      </div>

      {/*<Tumbler*/}
      {/*    text="Группы"*/}
      {/*    active={depGroupPlateType === 'groups'}*/}
      {/*    clickOnActive={() => {*/}
      {/*        setShowDepGroupPlate(false)*/}
      {/*        selectDepGroup(null)*/}
      {/*    }}*/}
      {/*    clickOnPassive={() => {*/}
      {/*        selectDepGroup(null)*/}
      {/*        openGroups()*/}
      {/*    }}*/}
      {/*/>*/}

      <RightBlockStyle>
        <div className="sub_actions">
          {/*<ViewWeekRoundedIcon onClick={() => {*/}
          {/*    setShowColumnsCustom(!showColumnsWindow)*/}
          {/*}} className={`columns_select${showColumnsWindow ? ' active' : ''}`}/>*/}

          {/*<div className="table_search_wrapper">*/}
          {/*    <TableSearch searchValue={tableFilter} setSearchValue={searchChangeHandler} isOpen={!!tableFilter}/>*/}
          {/*</div>*/}

          <TableSearch
            searchValue={tableFilter}
            setSearchValue={searchChangeHandler}
            isOpen={!!tableFilter}
          />
        </div>

        {/*<Tumbler*/}
        {/*    text="Отделы"*/}
        {/*    // active={depGroupPlateType === 'deps'}*/}
        {/*    active={false}*/}
        {/*    clickOnActive={() => {*/}
        {/*        setShowDepGroupPlate(false)*/}
        {/*        selectDepGroup(null)*/}
        {/*    }}*/}
        {/*    clickOnPassive={() => {*/}
        {/*        selectDepGroup(null)*/}
        {/*        openDeps()*/}
        {/*    }}*/}
        {/*/>*/}

        {/*пользователь с ролью исполнителя не может создавать новых пользователей*/}
        {currentUserRole !== 7 && (
          <Button
            title="Добавить"
            icon="plus_circle"
            style={{
              padding: '.5rem',
              width: '100px',
              marginLeft: '1rem',
              justifyContent: 'space-between',
            }}
            onClick={() => {
              history.push('/users/0');
              dispath(clearUser());
            }}
          />
        )}
      </RightBlockStyle>
    </UserActionStyle>
  );
};

const mapStateToProps = (state: State) => {
  // console.log(state.commonInfo.users.length)
  return {
    users_length: state.commonInfo.users.length,
    tableFilter: state.usersPage.tableFilter,
    // depGroupPlateType: getDepGroupPlateType(state),
    currentUserRole: state.commonInfo.currentUserInfo?.roles[0],
  };
};

const mapDispatchToProps = {
  setTableFilter,
  setUsers,
  fetchUsers,
  // setShowDepGroupPlate,
  setUserAddMode,
  openDepGroupPlate,
  openDeps,
  // openGroups,
  // setDepGroupType,
  selectDepGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersActions);
