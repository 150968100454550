import React, {useEffect, useState} from 'react';
import ProjectActions from './components/ProjectActions';
import ProjectTable from './components/ProjectTable';
import {fetchTasks, selectProject, setTableOrTreeToggler} from './actions';
import {connect} from 'react-redux';
import {Project} from '../../common/types';
import {State} from '../../rootReducer';
import {useParams} from 'react-router-dom';
import ProjectTree from "./components/ProjectTree";
// import { fetchData } from '../../utils/fetchData';

type Props = {
    projects: Project[];
    fetchTasks: (id: number, pageNum: number) => void;
    selectProject: (project: Project) => void,
    setTableOrTreeToggler: (val: string) => void,
    tableOrTreeToggler: string
};

const Projects: React.FC<Props> = ({
                                       projects,
                                       fetchTasks,
                                       selectProject,
                                       setTableOrTreeToggler,
                                       tableOrTreeToggler
                                   }) => {
        const {id} = useParams();

        useEffect(() => {
            if (id) {
                let project = projects.find((pr) => pr.id === parseInt(id));
                if (project) selectProject(project);
            }
        }, [id, projects, selectProject]);

        useEffect(() => {
            setTableOrTreeToggler(localStorage.getItem('project_show_toggle') as string)
        }, [localStorage.getItem('project_show_toggle')])

        if ([null, undefined, ''].indexOf(localStorage.getItem('project_show_toggle')) > -1) {
            localStorage.setItem('project_show_toggle', 'table')    // инициализация
        }

        return (
            <>
                <ProjectActions/>

                {tableOrTreeToggler === 'table' && <ProjectTable/>}
                {tableOrTreeToggler === 'tree' && <ProjectTree/>}
            </>
        );
    }
;

const mapStateToProps = (state: State) => {
        return {
            projects: state.commonInfo.projects,
            tableOrTreeToggler: state.projectsPage.tableOrTreeToggler
        };
    }
;

const mapDispatchToProps =
    {
        fetchTasks,
        selectProject,
        setTableOrTreeToggler
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
