import React from 'react';
import './styles.sass';
import { State } from '../../../rootReducer';
import { Priority as PriorityType } from '../../types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import palette from '../palette';
import Icon from '../Icon';

type Props = {
  priorities: PriorityType[];
  id: number;
  noname?: boolean;
  style?: object;
};

type PrioritySlugByText = string[];
let prioritySlugByText: PrioritySlugByText = [
  'Низкий',
  'Средний',
  'Высокий',
  'Срочный',
];
const PriorityButton = styled.div`
  display: flex;
  justify-content: start;
  padding: 0.5rem 0.25rem;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid transparent;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  width: ${({ noname }) => !noname && '108px'};
  line-height: 16px;
  text-align: right;
  background: ${palette.white};
  color: ${palette.darkBlue};
  margin-right: ${({ noname }) => !noname && '10px'};
  img {
    margin-right: 8px;
  }
`;

const Priority: React.FC<Props> = ({ id, noname, style }) => {
  return (
    <PriorityButton noname style={style}>
      <Icon name={id <= 4 ? `priority_${id}` : 'priority_1'} />
      {!noname &&
        (id <= 4 ? prioritySlugByText[id - 1] : prioritySlugByText[0])}
    </PriorityButton>
  );
};

const mapStateToProps = (state: State) => ({
  priorities: state.commonInfo.priorities,
});

export default connect(mapStateToProps)(Priority);
//export default Priority
