import React, { useState, useEffect, useCallback } from 'react';
import { TableContainer, Table, TableBody, TablePagination } from '@material-ui/core';
import { setShowDaysOff } from '../Tasks/actions';
import { connect } from 'react-redux';
import TitleTr from './components/TitleTr';
import TableHead from './components/TableHead';
import TaskTr from './components/TaskTr';

const testTasks = [
    'На сегодня',
    { id: 1, name: 'Человеческий организм получает почти все', load: 5, priority_id: 7, hours: 5, percents: 100, comment: 'Сделал быстрее' },
    { name: 'Недостаток полезных веществ, содержащихся в том же', load: 5, priority_id: 6, hours: 4, percents: 20, comment: 'Не успел, завтра доделаю' },
    'На последующие дни',
    { id: 2, name: 'Человеческий организм получает почти все', load: 8, priority_id: 7, hours: 0, percents: 0, comment: '' },
    { id: 3, name: 'Человеческий организм получает почти все', load: 8, priority_id: 8, hours: 0, percents: 0, comment: 'Занимался несрочной задачей, т.к. пришла идея как сделать ...' },
    { id: 4, name: 'Человеческий организм получает почти все', load: 8, priority_id: 9, hours: 0, percents: 0, comment: 'Сделал быстрее' },
    { id: 5, name: 'Человеческий организм получает почти все', load: 8, priority_id: 7, hours: 0, percents: 0, comment: 'Сделал быстрее' },
    { id: 6, name: 'Человеческий организм получает почти все', load: 8, priority_id: 8, hours: 0, percents: 0, comment: 'Сделал быстрее' },
    { id: 7, name: 'Человеческий организм получает почти все', load: 8, priority_id: 7, hours: 0, percents: 0, comment: 'Сделал быстрее' },
    { id: 8, name: 'Человеческий организм получает почти все', load: 8, priority_id: 9, hours: 0, percents: 0, comment: 'Сделал быстрее' },
    { id: 9, name: 'Человеческий организм получает почти все', load: 8, priority_id: 7, hours: 0, percents: 0, comment: 'Сделал быстрее' },
    { id: 10, name: 'Человеческий организм получает почти все', load: 8, priority_id: 7, hours: 0, percents: 0, comment: 'Сделал быстрее' },
    { id: 11, name: 'Человеческий организм получает почти все', load: 8, priority_id: 8, hours: 0, percents: 0, comment: 'Сделал быстрее' },
    { id: 12, name: 'Человеческий организм получает почти все', load: 8, priority_id: 7, hours: 0, percents: 0, comment: 'Сделал быстрее' },
]



type Props = {
    setShowDaysOff: (show: boolean) => void
}

const DayClose: React.FC<Props> = ({ setShowDaysOff }) => {

    const [className, setClassName] = useState('')
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    useEffect(() => {
        setTimeout(setClassName, 0, 'in_center')
    }, [setClassName])


    const closeHandler = useCallback(() => {
        setClassName('')
        setTimeout(() => {
            setShowDaysOff(false)
        }, 300)
    }, [setShowDaysOff])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="add_pannel_wrapper">
            <div className={`add_pannel day_close_pannel ${className}`}>
                <div className="main_title">Закрытие дня</div>
                <TableContainer style={{overflowX: 'visible'}}>
                    <Table>
                        <TableHead />
                        <TableBody>
                            {testTasks
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((task, ind) => {
                                    if (typeof task === 'string') {
                                        return <TitleTr title={task} />
                                    } else {
                                        return <TaskTr taskInfo={task} key={task.id} />
                                    }
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={testTasks.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <div className='action_btns'>
                    <div onClick={closeHandler} className='btn secondary'>Отмена</div>
                    <div onClick={() => { }} className={`btn secondary`}>Применить</div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = {
    setShowDaysOff
}


export default connect(null, mapDispatchToProps)(DayClose)