import React, {useEffect, useState} from 'react';
import withTitle from '../../../utils/hoc/withTitle';
import {connect} from 'react-redux';
import {State} from '../../../rootReducer';
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Input from "../../../common/components/Inputs/Input";
import SelectWithSecondaryBtn from "../../../common/components/Selects/SelectWithSecondaryBtn";
import ScheduleDay from "../../NewUser/components/ScheduleDay";
import Button from "../../../common/components/newComponent/Buttons/Button";
import {Item} from "../../../common/types";
import {setStartCyclick} from "../actions";


type Props = {
    repeat: any,
    setStartCyclick: (period?: string, params?: Array<number>, interval?: number, show_modal_window?: boolean, stringForMainPart?: string) => void
}


const weekDays: { day: number, name: string }[] = [
    {day: 1, name: 'Пн'},
    {day: 2, name: 'Вт'},
    {day: 3, name: 'Ср'},
    {day: 4, name: 'Чт'},
    {day: 5, name: 'Пт'},
    {day: 6, name: 'Сб'},
    {day: 7, name: 'Вс'},
]

const custom_time = [
    {text: 'День', value: 'every day'},
    {text: 'Неделя', value: 'every week'},
    {text: 'Месяц', value: 'every month'}
]

const daysOfMonthes: number[] = []
for (let i = 1; i < 32; i++) {
    daysOfMonthes.push(i)
}

const RepeatTaskWindow: React.FC<Props> = ({setStartCyclick, repeat}) => {

    const [frequency, setFrequency] = useState<string>(repeat.period === 'settings' ? 'every day' : repeat.period)
    const [inputValue, setInputValue] = useState<string>(repeat.interval)
    const [checkedDays, setCheckedDays] = useState<number[]>(repeat.params)
    const [stringForMainPart, setStringForMainPart] = useState<string>('Каждый день')

    useEffect(() => {
        let result_string = ''

        switch (frequency) {
            case 'every day':
                result_string += 'Каждый'
                break
            case 'every week':
                result_string += 'Каждую'
                break
            case 'every month':
                result_string += 'Каждый'
                break
        }

        if (inputValue.toString() === '1') result_string += ' '
        else result_string += ' ' + inputValue + ' '

        switch (frequency) {
            case 'every day':
                result_string += 'день'
                break
            case 'every week':
                result_string += 'неделю'
                break
            case 'every month':
                result_string += 'месяц'
                break
        }

        if (checkedDays.length) {
            if (frequency === 'every week') {
                result_string += ' в: '

                checkedDays.forEach((item, number) => {
                    let found_day = weekDays.find(day => day.day === item)
                    result_string += ((number === 0 ? '' : ', ') + found_day?.name)
                })
            } else if (frequency === 'every month') {
                result_string += ' по: '

                checkedDays.forEach((item, number) => {
                    result_string += ((number === 0 ? '' : ', ') + item)
                })
            }
        }

        result_string = result_string.replace(/\s\s+/g, ' ')    // удалять двойные пробелы
        // console.log(result_string)
        setStringForMainPart(result_string)
    }, [frequency, inputValue, checkedDays])

    // useEffect(() => {   // интерактивно показывать изменения в поле правой плашки. Временно отключен, потому что фон все равно заблюрен.
    //     setStartCyclick(
    //         repeat.period,
    //         repeat.params,
    //         repeat.interval,
    //         repeat.show_modal_window,
    //         stringForMainPart)
    // }, [stringForMainPart])

    return (
        <div className="add_pannel_wrapper">
            <div className={`repeat_task_window`}>
                <div className={'firstRow'}>
                    <div>Повтор</div>
                    <div onClick={() => {   // закрытие
                        setStartCyclick(
                            repeat.period,
                            repeat.params,
                            repeat.interval,
                            false,
                            repeat.settings_string)
                    }}/>
                </div>
                <div className={'secondRow'}>
                    <div style={{display: 'flex'}}>
                        <div className={'icon'}/>
                        <div className={'name'}>Частота</div>
                    </div>
                    <SelectWithSecondaryBtn
                        list={custom_time}
                        selected={custom_time.find(item => item.value === frequency) as Item}
                        // disabled={disabledComponent}
                        selectHandler={(value) => {
                            setFrequency(value as string)
                            setCheckedDays([])
                        }}
                    />
                </div>
                <div className={'thirdRow'}>
                    <div className={'each'}>
                        {frequency === 'every day' ? "Каждый" : ''}
                        {frequency === 'every week' ? "Каждую" : ''}
                        {frequency === 'every month' ? "Каждый" : ''}
                    </div>
                    <Input inputType={'text'} value={inputValue} placeholder={''} title={'интервал'}
                           changeHandler={(value) => {
                               // console.log(value)
                               setInputValue(value)
                           }}/>
                    <div className={'time_unit'}>
                        {frequency === 'every day' ? "день" : ''}
                        {frequency === 'every week' ? "неделю в:" : ''}
                        {frequency === 'every month' ? "месяц" : ''}
                    </div>
                </div>

                {frequency === 'every week' && <div className={'weekdays_container'}>
                    {weekDays.map(weekday => (
                        <ScheduleDay
                            key={weekday.day}
                            name={weekday.name}
                            selected={checkedDays.includes(weekday.day)}
                            addHandler={() => {
                                let copied_array = checkedDays.map(item => item)
                                copied_array.push(weekday.day)
                                setCheckedDays(copied_array.sort((a, b) => a - b))
                            }}
                            removeHandler={() => {
                                let copied_array = checkedDays.map(item => item)
                                copied_array.splice(copied_array.indexOf(weekday.day), 1)
                                setCheckedDays(copied_array)
                            }}
                        />
                    ))}
                </div>}

                {frequency === 'every month' && <div className={'month'}>
                    {daysOfMonthes.map(day => {
                        return (
                            <div className={`dayOfMonth ${checkedDays.includes(day) ? 'selected' : ''}`}
                                 key={day}
                                 onClick={() => {
                                     let copied_array = checkedDays.map(item => item)
                                     let found_item = copied_array.indexOf(day)
                                     if (found_item === -1) copied_array.push(day)
                                     else copied_array.splice(found_item, 1)
                                     setCheckedDays(copied_array.sort((a, b) => a - b))
                                 }}>
                                {day}
                            </div>
                        )
                    })}
                </div>}


                <div className={'bottomRow'}>
                    <Button title={'Отмена'} design={'secondary'} onClick={() => {
                        setStartCyclick(
                            repeat.period,
                            repeat.params,
                            repeat.interval,
                            false,
                            repeat.settings_string)
                    }}/>
                    <Button title={'Готово'} onClick={() => {
                        setStartCyclick(frequency,
                            checkedDays,
                            inputValue === '' ? 1 : parseInt(inputValue),
                            false,
                            stringForMainPart)
                    }}/>
                </div>


            </div>
        </div>
    )
}


const mapStateToProps = (state: State) => {
    return {
        repeat: state.taskInfoPlate.repeat
    }
}

const mapDispatchToProps = {
    setStartCyclick
}


export default connect(mapStateToProps, mapDispatchToProps)(RepeatTaskWindow)