import React, { useState } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ruLocale from 'date-fns/locale/ru'
import DateFnsUtils from "@date-io/date-fns"
import { DatePicker } from '@material-ui/pickers'
import DateMaster from '../../../utils/DateMaster';



type Props = {
    date: Date,
    minDate?: Date,
    maxDate?: Date,
    acceptHandler: (isoDate: string) => void
}

const DateComponent = ({ date, setOpen }) => {

    const dateMaster = new DateMaster(date)


    return (
        <div onClick={() => setOpen(true)} className="date_time">
            <div className="date">{dateMaster.getDate()}</div>
        </div>
    )
}


const CustomDatePicker: React.FC<Props> = ({ date, acceptHandler, minDate, maxDate }) => {

    const [isOpen, setOpen] = useState<boolean>(false)


    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
            <DatePicker
                value={date}
                open={isOpen}
                onClose={() => setOpen(false)}
                cancelLabel="Отменить"
                showTodayButton={true}
                todayLabel="Сейчас"
                onChange={() => { }}
                minDate={minDate ? minDate : new Date('1900-01-01')}
                maxDate={maxDate ? maxDate : new Date('2100-01-01')}
                strictCompareDates={true}
                onAccept={(date) => {
                    if (date) acceptHandler(date.toISOString())
                }}
                TextFieldComponent={() => <DateComponent setOpen={setOpen} date={date} />}
            />
        </MuiPickersUtilsProvider>
    )
}



export default CustomDatePicker