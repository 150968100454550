import React, { useEffect, useRef, useState } from 'react';
import SelectWithSecondaryBtn from '../../../common/components/Selects/SelectWithSecondaryBtn';
import {
  Task,
  SelectData,
  Item,
  Section,
  Schedule,
  File as FileType,
} from '../../../common/types';
import { connect, useDispatch } from 'react-redux';
import {
  getSelectedTask,
  getUsersSelectList,
  getProjectsSelectList,
  getSectionSelectList,
} from '../selectors';
import { State } from '../../../rootReducer';
import { updateTask } from '../../../common/actions';
import TaskWorkLoad from './TaskWorkLoad';
import { TextareaAutosize } from '@material-ui/core';
import {
  changeReducerCheckboxItem,
  createTask,
  deleteFile,
  dispatchCheckboxItem,
  fetchProjectSections,
  isSendingNewTask,
  recalculateTaskLoad,
  selectTask,
  sendReqToChangeTemplRepTask,
  setCyclicTaskToChange,
  setDefaultValues,
  setExecutorId,
  setFinishCyclick,
  setShownTaskInfoPlate,
  setStartCyclick,
  setTaskDescription,
  setTaskProject,
  setTaskSection,
  successfulCreatedNewTasks,
  uploadFile,
} from '../actions';
import CheckListModule from '../../../common/components/CheckList/CheckListModule';
import Button from '../../../common/components/newComponent/Buttons/Button';
import Chips from '../../../common/components/ChipsItem';
import NameWithInput from '../../../common/components/newComponent/Inputs/NameWithInput';
import styled from 'styled-components';
import Prioritys from '../../../common/components/Priority/Prioritys';
import ExecutorSelect from '../../TaskInfoPlate/components/ExecutorSelectWithDelegate';
import Delegate from '../../TaskInfoPlate/components/Delegate';
import TaskBegin from '../../TaskInfoPlate/components/TaskBegin';
import TaskEnd from '../../TaskInfoPlate/components/TaskEnd';
import FileElem from '../../../common/components/FileElem/FileElem';
import Dependencies from './Dependencies';
import RepeatTask from './RepeatTask';
import EndRepeatTask from './EndRepeatTask';
import { fetchData } from '../../../utils/fetchData';
import { call } from 'redux-saga/effects';

type Props = {
  selectedTask: Task | null;
  projectsSelect: SelectData;
  usersSelect: {
    author: Item;
    executor: Item;
    list: Item[];
  };
  uploadFile: (file: File) => void;
  deleteFile: (id: number) => void;
  sectionSelect: SelectData;
  commentFiles: FileType[];
  sections: Section[];
  schedule: Schedule | null;
  checkList: object[];
  currentUser: any;
  updateTask: (id: number, params: any, withNotif?: boolean) => void;
  dispatchCheckboxItem: (
    orderNumber: number,
    text: string,
    checkboxState: boolean,
    actionType: string
  ) => void;
  changeReducerCheckboxItem: (
    orderNumber: number,
    text: string,
    checkboxState: boolean,
    actionType: string
  ) => void;
  setTaskDescription: (val: string) => void;
  name: string;
  executor_id: number | null;
  taskInfoActiveTab: string;
  description: string;
  project_id: number | null;
  project_section_id: number | null;
  successfulCreatedNewTasks: (value: any) => void;
  setTaskProject: (val: number) => void;
  fetchProjectSections: (val: number) => void;
  setTaskSection: (id: number) => void;
  setExecutorId: (id: number | null) => void;
  createTask: (executor_id?: number) => void;
  setDefaultValues: () => void;
  repeat: any;
  isSendingNewTask: (isSending: boolean) => void;
  isSendingCreateNewTasks: boolean;
  isShownTaskInfoPlate: boolean;
  rememberObjectCyclicTaskToChange: string;
  setCyclicTaskToChange: (obj: {}) => void;
  flagForActionCyclycTask: string;
  parameter_trigger: boolean;
  sendReqToChangeTemplRepTask: (obj: {}) => void;
  setStartCyclick: (
    period?: string,
    params?: Array<number>,
    interval?: number,
    show_modal_window?: boolean,
    stringForMainPart?: string
  ) => void;
  setFinishCyclick: (
    ending_condition?: string,
    end_count?: number | null,
    end_date?: string | null
  ) => void;
};

const WrapperChipsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InfoTab: React.FC<Props> = ({
  dispatchCheckboxItem,
  checkList,
  taskInfoActiveTab,
  changeReducerCheckboxItem,
  selectedTask,
  projectsSelect,
  usersSelect,
  sections,
  sectionSelect,
  schedule,
  updateTask,
  project_id,
  setTaskDescription,
  setTaskProject,
  fetchProjectSections,
  setTaskSection,
  setExecutorId,
  createTask,
  setDefaultValues,
  name,
  description,
  project_section_id,
  uploadFile,
  deleteFile,
  commentFiles,
  successfulCreatedNewTasks,
  currentUser,
  executor_id,
  repeat,
  isSendingNewTask,
  isSendingCreateNewTasks,
  isShownTaskInfoPlate,
  rememberObjectCyclicTaskToChange,
  setCyclicTaskToChange,
  flagForActionCyclycTask,
  sendReqToChangeTemplRepTask,
  setStartCyclick,
  setFinishCyclick,
  parameter_trigger,
}) => {
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState<Item[]>([]); // пользователи, которые выбраны в чипсах
  const [cyclicTaskInfo, setCyclicTaskInfo] = useState<any>(null);
  const [applyBtnRepeatBlock, setApplyBtnRepeatBlock] = useState<boolean>(
    false
  );

  // let btnAddFile = useRef<HTMLDivElement>(null);
  const weekDays: { day: number; name: string }[] = [
    { day: 1, name: 'Пн' },
    { day: 2, name: 'Вт' },
    { day: 3, name: 'Ср' },
    { day: 4, name: 'Чт' },
    { day: 5, name: 'Пт' },
    { day: 6, name: 'Сб' },
    { day: 7, name: 'Вс' },
  ];

  const disabledComponent =
    currentUser?.id === selectedTask?.author_id ||
    currentUser?.id === selectedTask?.executor_id ||
    !selectedTask
      ? false
      : currentUser?.roles[0] !== 1;
  // компоненты ОТКЛЮЧЕНЫ, если: пользователь, из под которого смотришь на задачу (не автор, не исполнитель, не администратор)

  useEffect(() => {
    if (!isShownTaskInfoPlate) setSelectedItems([]);
  }, [isShownTaskInfoPlate]);

  useEffect(() => {
    setApplyBtnRepeatBlock(false);
  }, [taskInfoActiveTab]);

  useEffect(() => {
    (async () => {
      if (selectedTask?.cyclic_task_id) {
        let cycleInfo = await fetchData.get(
          `/api/v1/cyclic-tasks/` + selectedTask?.cyclic_task_id
        );
        if (cycleInfo) {
          if (cycleInfo.params === null) cycleInfo['params'] = [];
          if (cycleInfo.end_count)
            cycleInfo['end_count'] = JSON.parse(cycleInfo.end_count)[0]; // из стринга-массива вытаскиваю первое значение
          if (cycleInfo.end_date) {
            cycleInfo['end_date'] =
              cycleInfo.end_date.substr(8, 2) +
              '-' +
              cycleInfo.end_date.substr(5, 2) +
              '-' +
              cycleInfo.end_date.substr(0, 4) +
              ' 00:00:00';
          }
          if (typeof cycleInfo.params === 'string') {
            cycleInfo['params'] = JSON.parse(cycleInfo.params);
          }
          setCyclicTaskInfo(cycleInfo);

          ///////////////     генерирование полного названяия для строки состояния цикла
          let result_string = '';
          switch (cycleInfo.period) {
            case 'every day':
              result_string += 'Каждый';
              break;
            case 'every week':
              result_string += 'Каждую';
              break;
            case 'every month':
              result_string += 'Каждый';
              break;
          }

          if (cycleInfo.interval.toString() === '1') result_string += ' ';
          else result_string += ' ' + cycleInfo.interval + ' ';

          switch (cycleInfo.period) {
            case 'every day':
              result_string += 'день';
              break;
            case 'every week':
              result_string += 'неделю';
              break;
            case 'every month':
              result_string += 'месяц';
              break;
          }

          if (cycleInfo.params.length) {
            if (cycleInfo.period === 'every week') {
              result_string += ' в: ';
              cycleInfo.params.forEach((item, number) => {
                let found_day = weekDays.find((day) => day.day === item);
                result_string += (number === 0 ? '' : ', ') + found_day?.name;
              });
            } else if (cycleInfo.period === 'every month') {
              result_string += ' по: ';
              cycleInfo.params.forEach((item, number) => {
                result_string += (number === 0 ? '' : ', ') + item;
              });
            }
          }

          result_string = result_string.replace(/\s\s+/g, ' '); // удалять двойные пробелы

          ///////////////

          // запись в редюсер всего по repeat
          setStartCyclick(
            cycleInfo.period,
            cycleInfo.params === null ? [] : cycleInfo.params,
            cycleInfo.interval,
            false,
            result_string
          );
          setFinishCyclick(
            cycleInfo.ending_condition,
            cycleInfo.end_count !== null ? cycleInfo.end_count : null,
            cycleInfo['end_date']
          );
        }
      } else {
        setStartCyclick('', [], 1, false, '');
        setFinishCyclick('never', null, null);
        setCyclicTaskInfo(null);
        setApplyBtnRepeatBlock(false);
      }
    })();
  }, [selectedTask?.id, selectedTask?.cyclic_task_id, parameter_trigger]);

  useEffect(() => {
    // @ts-ignore
    setTaskDescription(selectedTask?.description);
  }, [selectedTask?.id]);

  useEffect(() => {
    // нужно, чтобы в редюсере было значение первого пользователя в чипсине, пока нет selectedTask
    if (selectedItems.length) {
      if (!executor_id) {
        setExecutorId(selectedItems[0].value as number);
        setTimeout(() => recalculateTaskLoad(), 500);
      }
    } else setExecutorId(null);
  }, [selectedItems]);

  useEffect(() => {
    if (selectedTask) {
      if (cyclicTaskInfo) {
        //////// проверка поля params, потому что это массив, и при простом сравнении после изменения, он дает неверный результат
        let params_not_equals = false;
        if (
          Object.keys(repeat.params).length !==
          Object.keys(cyclicTaskInfo.params).length
        ) {
          params_not_equals = true;
        } else {
          Object.keys(repeat.params).forEach((each) => {
            let match = false;
            Object.keys(cyclicTaskInfo.params).forEach((key) => {
              if (each === key) match = true;
            });
            if (!match) params_not_equals = true;
          });
        }
        ////////

        // проверка, был ли изменен хоть 1 параметр в блоке с повторениями
        if (
          repeat.end_count !== cyclicTaskInfo.end_count ||
          repeat.end_date !== cyclicTaskInfo.end_date ||
          repeat.ending_condition !== cyclicTaskInfo.ending_condition ||
          repeat.interval !== cyclicTaskInfo.interval ||
          params_not_equals ||
          repeat.period !== cyclicTaskInfo.period
        ) {
          setApplyBtnRepeatBlock(true);
        } else setApplyBtnRepeatBlock(false);
      } else {
        if (repeat.period === '') {
          setApplyBtnRepeatBlock(false);
        }
        // else {
        //   setApplyBtnRepeatBlock(true);
        // }
      }
    }
  }, [repeat]);

  return (
    <div>
      <div className={'full_width_components'}>
        <div className={'description'}>
          <TextareaAutosize
            aria-label="description_textarea_show"
            placeholder="Описание"
            className={'input_div collapsed'}
            rowsMin={2}
            onFocus={(e) => {
              if (e.currentTarget.classList.length) {
                for (let i = 0; i < e.currentTarget.classList.length; i++) {
                  if (e.currentTarget.classList[i] === 'collapsed') {
                    e.currentTarget.classList.remove('collapsed');
                  }
                }
              }
            }}
            onChange={(e) => {
              if (!disabledComponent) setTaskDescription(e.currentTarget.value);
            }}
            onBlur={(e) => {
              if (selectedTask) {
                e.currentTarget.classList.add('collapsed');
                if (!disabledComponent) {
                  if (e.target.value !== selectedTask?.description) {
                    // мгновенно обновляю значение для заколлапсированного состояния
                    if (selectedTask.cyclic_task_id !== 0) {
                      // эта задача вообще циклическая или сама по себе ?
                      // значит циклическая
                      if (flagForActionCyclycTask === '') {
                        setCyclicTaskToChange({ description: e.target.value }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                      } else if (flagForActionCyclycTask === 'task') {
                        updateTask(selectedTask.id, {
                          description: e.target.value,
                        });
                      } else {
                        updateTask(selectedTask.id, {
                          description: e.target.value,
                        });
                        sendReqToChangeTemplRepTask({
                          description: e.target.value,
                        });
                      }
                    } else {
                      updateTask(selectedTask.id, {
                        description: e.target.value,
                      });
                    }
                  }
                }
              } else setTaskDescription(e.target.value);
            }}
            value={description}
          />
        </div>

        <CheckListModule
          action={(
            orderNumber,
            nameItem,
            checkboxValue,
            actionType,
            onlyChangeReducer
          ) => {
            if (selectedTask) {
              if (onlyChangeReducer) {
                changeReducerCheckboxItem(
                  orderNumber,
                  nameItem,
                  checkboxValue,
                  actionType
                );
              } else {
                dispatchCheckboxItem(
                  orderNumber,
                  nameItem,
                  checkboxValue,
                  actionType
                );
              }
            } else {
              changeReducerCheckboxItem(
                orderNumber,
                nameItem,
                checkboxValue,
                actionType
              );
            }
          }}
          checkList={checkList}
          disabled={
            selectedTask
              ? currentUser?.id === selectedTask?.author_id ||
                currentUser?.id === selectedTask?.executor_id
                ? false
                : currentUser?.roles[0] !== 1
              : false
          }
        />
      </div>

      <div className="info_tab">
        <NameWithInput
          title="Проект"
          iconName="folder"
          // disabled={delegated}
        >
          <SelectWithSecondaryBtn
            disabled={disabledComponent}
            list={projectsSelect.list.sort((a, b) =>
              a.text > b.text ? 1 : -1
            )}
            selected={projectsSelect.selected}
            selectHandler={(value, target) => {
              let params = { project_id: value };
              let section = sections.find((sec) => sec.project_id === value);
              if (section) params['project_section_id'] = section.id;

              if (selectedTask) {
                if (selectedTask.cyclic_task_id !== 0) {
                  // эта задача вообще циклическая или сама по себе ?
                  // значит циклическая
                  if (flagForActionCyclycTask === '') {
                    setCyclicTaskToChange(params); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                  } else if (flagForActionCyclycTask === 'task') {
                    updateTask(selectedTask.id, params);
                  } else {
                    updateTask(selectedTask.id, params);
                    sendReqToChangeTemplRepTask(params);
                  }
                } else {
                  updateTask(selectedTask.id, params);
                }
              } else {
                setTaskProject(value as number);
                fetchProjectSections(value as number);
              }
            }}
          />
        </NameWithInput>

        <NameWithInput title="Раздел" iconName="folder">
          <SelectWithSecondaryBtn
            list={sectionSelect.list}
            selected={sectionSelect.selected}
            disabled={disabledComponent || !sectionSelect.list.length}
            selectHandler={(value) => {
              if (selectedTask) {
                // проверить шаблон и спросить про изменение
                if (selectedTask.cyclic_task_id !== 0) {
                  // эта задача вообще циклическая или сама по себе ?
                  // значит циклическая
                  if (flagForActionCyclycTask === '') {
                    setCyclicTaskToChange({ project_section_id: value }); // заношу значения изменяемых параметров в временное хранилище, пока выводится окно с вопросом
                  } else if (flagForActionCyclycTask === 'task') {
                    updateTask(selectedTask.id, { project_section_id: value });
                  } else {
                    updateTask(selectedTask.id, { project_section_id: value });
                    sendReqToChangeTemplRepTask({ project_section_id: value });
                  }
                } else {
                  updateTask(selectedTask.id, { project_section_id: value });
                }
              } else setTaskSection(value as number);
            }}
          />
        </NameWithInput>
        <NameWithInput title="Приоритет" iconName="prioritySelect">
          <Prioritys bigSize />
        </NameWithInput>
        <NameWithInput title="Автор" iconName="people">
          <WrapperChipsBlock>
            <Chips
              image={usersSelect?.author?.urlImage}
              // text={`${author?.surname} ${author?.name}
              // ${author?.patronymic === null ? '' : author?.patronymic}`}
              text={usersSelect.author?.text}
              disabled
            />
          </WrapperChipsBlock>
        </NameWithInput>

        <NameWithInput title="Исполнитель" iconName="people">
          <ExecutorSelect
            selectedItems={selectedItems}
            setSelectedItems={(arg) => setSelectedItems(arg)}
          />
        </NameWithInput>

        {selectedTask &&
          selectedTask?.executor_id === currentUser?.id &&
          Boolean(selectedTask.provide_to) && (
            <NameWithInput title="Делегировать" iconName="arrow-right">
              <Delegate
                selectedItems={selectedItems}
                setSelectedItems={(arg) => setSelectedItems(arg)}
              />
            </NameWithInput>
          )}

        <NameWithInput title="Начало" iconName="calendar-dark">
          <div style={{ width: '232px' }}>
            <TaskBegin />
          </div>
        </NameWithInput>

        <NameWithInput title="Дедлайн" iconName="calendar-dark">
          <div style={{ width: '232px' }}>
            <TaskEnd />
          </div>
        </NameWithInput>

        {/*repeat task*/}
        <NameWithInput title="Повторять" iconName="repeat">
          <RepeatTask
            disabledComponent={
              (currentUser?.id !== selectedTask?.author_id &&
                selectedTask !== null) ||
              (cyclicTaskInfo !== null &&
                currentUser?.id !== cyclicTaskInfo?.author_id)
            }
          />
        </NameWithInput>

        {repeat.period && (
          <NameWithInput title="Закончить повтор" iconName="repeat">
            <EndRepeatTask
              disabledComponent={
                (currentUser?.id !== selectedTask?.author_id &&
                  selectedTask !== null) ||
                (cyclicTaskInfo !== null &&
                  currentUser?.id !== cyclicTaskInfo?.author_id)
              }
            />
          </NameWithInput>
        )}

        {applyBtnRepeatBlock && (
          <NameWithInput title="" iconName="repeat">
            <Button
              style={{ marginLeft: 144 }}
              title={'Применить'}
              onClick={() => {
                sendReqToChangeTemplRepTask({ ...repeat });
              }}
            />
          </NameWithInput>
        )}
        {/*repeat task*/}

        {!selectedTask && (
          <NameWithInput title="Прикрепить файл" iconName="clip">
            <div>
              <input
                onChange={(e) => {
                  let files = e.currentTarget.files;
                  if (!files) return;
                  for (let i = 0; i < files?.length; i++) uploadFile(files[i]);
                }}
                type="file"
                id="comment_files"
                style={{ display: 'none' }}
                multiple={true}
              />

              <label htmlFor="comment_files" className={'info_attach_file'}>
                Выбрать
              </label>

              {commentFiles.length > 0 && (
                <div className={'attachments'} style={{ width: '156px' }}>
                  {commentFiles.map((elem) => (
                    <FileElem
                      url={elem.url}
                      key={elem.id}
                      id={elem.id}
                      name={elem.name}
                      deleteHandler={deleteFile}
                    />
                  ))}
                </div>
              )}
            </div>
          </NameWithInput>
        )}

        <NameWithInput title="Загрузка" iconName="percent">
          <TaskWorkLoad disabled={disabledComponent} />
        </NameWithInput>

        <NameWithInput title="Зависимости" iconName="arrow-right">
          <Dependencies disabledComponent={disabledComponent} />
        </NameWithInput>

        {!selectedTask && (
          <div className={'taskInfoPlateBottomRowButtons'}>
            <Button
              title="Отмена"
              design="secondary"
              onClick={() => {
                setDefaultValues();
                dispatch(setShownTaskInfoPlate(false));
                // if (!isSendingDelegate) {
                //     setIsSendingDelegate(true)
                //     let users_ids = selectedItems.map(item => (item.value))
                //     delegateConfirm(users_ids as number[])
                // }
              }}
            />
            <Button
              title={'Сохранить'}
              style={{ marginLeft: '8px' }}
              isSending={isSendingCreateNewTasks}
              disabled={
                !name ||
                !description ||
                !project_id ||
                !project_section_id ||
                !selectedItems.length
              }
              onClick={() => {
                if (!isSendingCreateNewTasks) {
                  isSendingNewTask(true);

                  successfulCreatedNewTasks({
                    createdTasks: [],
                    executorsIds: selectedItems.map((item) => item.value),
                    checkItems: [...checkList],
                  });
                  selectedItems.forEach((item) =>
                    createTask(item.value as number)
                  );
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  // console.log(getSelectedTask(state))
  return {
    selectedTask: getSelectedTask(state),
    currentUser: state.commonInfo.currentUserInfo,
    executor_id: state.taskInfoPlate.executor_id,
    taskInfoActiveTab: state.taskInfoPlate.taskInfoActiveTab,
    projectsSelect: getProjectsSelectList(state),
    usersSelect: getUsersSelectList(state),
    sectionSelect: getSectionSelectList(state),
    sections: state.commonInfo.sections,
    schedule: state.taskInfoPlate.executorSchedule,
    checkList: state.taskInfoPlate.checkList,
    name: state.taskInfoPlate.name,
    description: state.taskInfoPlate.description,
    project_id: state.taskInfoPlate.project_id,
    project_section_id: state.taskInfoPlate.project_section_id,
    commentFiles: state.taskInfoPlate.commentFiles,
    repeat: state.taskInfoPlate.repeat,
    isSendingCreateNewTasks: state.taskInfoPlate.isSendingCreateNewTasks,
    isShownTaskInfoPlate: state.taskInfoPlate.isShownTaskInfoPlate,
    rememberObjectCyclicTaskToChange:
      state.taskInfoPlate.rememberObjectCyclicTaskToChange,
    flagForActionCyclycTask: state.taskInfoPlate.flagForActionCyclycTask,
    parameter_trigger: state.taskInfoPlate.parameter_trigger,
  };
};

const mapDispatchToProps = {
  updateTask,
  dispatchCheckboxItem,
  changeReducerCheckboxItem,
  setTaskDescription,
  setTaskProject,
  fetchProjectSections,
  setTaskSection,
  setExecutorId,
  createTask,
  setDefaultValues,
  uploadFile,
  deleteFile,
  successfulCreatedNewTasks,
  isSendingNewTask,
  setCyclicTaskToChange,
  sendReqToChangeTemplRepTask,
  setStartCyclick,
  setFinishCyclick,
  // deleteCheckboxItem,
  // dispatchActionForItem
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoTab);
