import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from '../Icon';
// import {Project, Task} from "../../types";
import { urlApp } from '../../../App';
import { fetchData } from '../../../utils/fetchData';
import { connect, useDispatch, useSelector } from 'react-redux';
import { selectTask } from '../../../pages/TaskInfoPlate/actions';
import { changeTaskList, fetchTasks } from '../../../pages/Projects/actions';
import { State } from '../../../rootReducer';
import { Task } from '../../types';
import { CreateNotif } from '../../../utils/createNotification';

const WrapperTaskTree = styled.div`
  position: relative;
  width: 280px;
  height: 120px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 8px 8px 0 8px;
  cursor: pointer;
  margin-bottom: ${({ bottom_margin }) => (bottom_margin ? '24px' : '0px')};
  ${({ selected }) =>
    selected &&
    `
    border: 2px solid #1BAAF0!important;
    background-color: #F3FAFE!important;
  `}
  &:hover {
    background-color: #f2f2f2;
  }
`;
const BlockTopInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 16px;
  height: 100%;
  width: 100%;
  color: #a8a8a8;
`;
const WrapperFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BlockTaskLoad = styled.div`
  margin-left: 4px;
  max-width: 50px;
`;
const BlockPriority = styled.div`
  margin-left: 10px;
`;
const TaskNameStyle = styled.div`
  max-width: 264px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 16px 0 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #212121;
`;
const ExecuterBlock = styled.div`
  width: 220px;
  height: 48px;
  display: flex;
`;
const InfoExecuter = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: 8px;
  div {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #212121;
  }
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #a8a8a8;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
const UserName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StatusIcon = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 10px;
  margin-left: 4px;
  background-color: ${({ status }) => {
    switch (status) {
      case 12:
      case 13:
        return '#27CBA6';
      case 10:
        return '#1BAAF0';
      case 14:
        return '#FF6666';
      case 6:
      case 17:
        return '#FFDE89';
      case 2:
      case 3:
      case 4:
      case 5:
        return '#BB6BD9';
      case 15:
      case 11:
      case 1:
      case 9:
        return '#E0E0E0';
    }
  }};
`;

type Props = {
  begin?: string;
  end?: string;
  priority_id?: number;
  name?: string;
  img?: string;
  executor?: any;
  task_load?: number;
  position?: string;
  nesting?: number;
  bottom_margin?: boolean;
  status?: number;
  id: number;
  selectTask: (task: Task | null) => void;
  activeTaskId: number | undefined;
};

const TakeMonthShortLetters = (arg) => {
  let result;
  switch (arg) {
    case '01':
      result = ' янв';
      break;
    case '02':
      result = ' фев';
      break;
    case '03':
      result = ' мар';
      break;
    case '04':
      result = ' апр';
      break;
    case '05':
      result = ' май';
      break;
    case '06':
      result = ' июн';
      break;
    case '07':
      result = ' июл';
      break;
    case '08':
      result = ' авг';
      break;
    case '09':
      result = ' сен';
      break;
    case '10':
      result = ' окт';
      break;
    case '11':
      result = ' ноя';
      break;
    case '12':
      result = ' дек';
      break;
    default:
      result = ' NON';
  }

  return result;
};

const TreeTask: React.FC<Props> = ({
  begin,
  end,
  priority_id,
  name,
  img,
  executor,
  task_load,
  position,
  nesting,
  bottom_margin = true,
  status,
  id,
  selectTask,
  activeTaskId,
}) => {
  let newBegin =
    begin?.substr(0, 2) + TakeMonthShortLetters(begin?.substr(3, 2));
  let newEnd = end?.substr(0, 2) + TakeMonthShortLetters(end?.substr(3, 2));
  const clickHandler = (event) => {
    let idTask = event.target;
    if (!idTask.dataset.id) {
      let counter = 0;
      while (!idTask.dataset.id && counter < 10) {
        idTask = idTask.parentNode;
        counter++;
      }
    }
    if (name === '**********') {
      CreateNotif('У вас нет прав на просмотр всех данных');
    } else {
      fetchData
        .get(`/api/v1/tasks/${idTask.dataset.id}`)
        .then((task) => selectTask(task))
        .catch((error) => {
          console.error('не найдена задача по следующей причине: ' + error);
        });
    }
  };

  return (
    <WrapperTaskTree
      data-id={id}
      selected={id === activeTaskId}
      onClick={clickHandler}
      bottom_margin={bottom_margin}
    >
      <BlockTopInformation>
        <WrapperFlex>
          <Icon
            name="calendar-gray"
            width="16px"
            height="16px"
            color="red"
            style={{ marginRight: '4px' }}
          />
          <div>
            {newBegin} - {newEnd}
          </div>

          <StatusIcon status={status} />
        </WrapperFlex>
        <WrapperFlex>
          {nesting && (
            <div>
              {nesting} <Icon name={'arrow-right-grey'} />
            </div>
          )}
          {/*оставшееся время до дедлайна*/}
          <BlockTaskLoad>{task_load}ч</BlockTaskLoad>
          <BlockPriority>
            <Icon name={`priority_${priority_id}`} />
          </BlockPriority>
        </WrapperFlex>
      </BlockTopInformation>
      <TaskNameStyle>{name}</TaskNameStyle>
      <ExecuterBlock>
        <Icon
          src={
            urlApp +
            `/files/${
              img ? img : '8ab124bc8994f48757d00db4a269a958a7849134.jpg'
            }/download`
          }
          width="32px"
          height="32px"
          style={{ borderRadius: '50%' }}
        />
        <InfoExecuter>
          <UserName>{executor}</UserName>
          <span>{position}</span> {/*должность*/}
        </InfoExecuter>
      </ExecuterBlock>
    </WrapperTaskTree>
  );
};

const mapStateToProps = (state: State) => {
  return {
    activeColumns: state.projectsPage.activeColumns,
    activeTaskId: state.taskInfoPlate.selectedTask?.id,
  };
};

const mapDispatchToProps = {
  selectTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeTask);
